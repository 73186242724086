import React, { useState, useEffect, useContext } from "react";
import { useSocket } from "./ControlSocketProvider";
import { useSnack } from "./SnackProvider";

import {
    TOPIC_STATE_MISSION_RUN,
    TOPIC_STATE_MISSION_RECORD,
    STATES_RECORD_END_STATES,
    STATES_MISSION_END_STATES,
    STATE_MISSION_PAUSED,
} from "../lib/state";
import { missionDelete, missionList, missionRename } from "../lib/protocols/control";
import { subscribe } from "../lib/socket";
import { callSpotService } from "../lib/service";
import { dummyMissionNames } from "../testdata";

export const MissionContext = React.createContext();
MissionContext.displayName = "Mission";
export const MissionConsumer = MissionContext.Consumer;
export const useMission = () => useContext(MissionContext);

const USE_DUMMY = false;

function MissionProvider({ children }) {
    const { controlSocket: socket } = useSocket();
    const { setSnack, addToast } = useSnack();
    const [recordState, setRecordState] = useState(null);
    const [recordPayload, setRecordPayload] = useState({});
    const [missionState, setMissionState] = useState(null);
    const [missionPayload, setMissionPayload] = useState({});
    const [missions, setMissions] = useState(null);
    const [recordEnded, setRecordEnded] = useState(true);
    const [missionEnded, setMissionEnded] = useState(true);
    const [missionPaused, setMissionPaused] = useState(true);

    const listMission = (invalidateCurrent = false) => {
        // not loaded when missions is set to null
        invalidateCurrent && setMissions(null);
        if (USE_DUMMY) {
            const missionNames = dummyMissionNames.map((obj) => obj.name);
            setMissions(missionNames);
            return new Promise((resolve) => resolve(missionNames));
        }
        return callSpotService(...missionList())
            .then((res) => {
                setMissions(res.missions);
            })
            .catch((err) => {
                setMissions([]); // stop skeleton loading and set empty mission list
                addToast({
                    type: "error",
                    message: `Could not list mission: ${err.message}`,
                    duration: 7000,
                });
                // expects errJson.err to be a human readable message
                // will cause unhandled error if not commented out
                // throw new Error(`Could not list mission: ${err.message}`);
            });
    };

    const deleteMission = (id) =>
        callSpotService(...missionDelete(id)).catch((err) => {
            throw new Error(`Could not delete mission: ${err.message}`);
        });

    const renameMission = (currName, newName) =>
        callSpotService(...missionRename(currName, newName)).catch((err) => {
            throw new Error(`Could not rename mission: ${err.message}`);
        });

    useEffect(() => {
        subscribe(socket, TOPIC_STATE_MISSION_RECORD, (data) => {
            const { event, ...payload } = data;

            setRecordState(event);
            setRecordEnded(STATES_RECORD_END_STATES.includes(event));
            setRecordPayload(payload);
        });

        subscribe(socket, TOPIC_STATE_MISSION_RUN, (data) => {
            const { event, ...payload } = data;
            setMissionState(event);
            const missionEnded = STATES_MISSION_END_STATES.includes(event);
            setMissionEnded(missionEnded);
            setMissionPaused(!missionEnded && missionState !== STATE_MISSION_PAUSED);
            setMissionPayload(payload);
        });
    }, [socket]);

    return (
        <MissionContext.Provider
            value={{
                recordState,
                recordPayload,
                missionState,
                missionPayload,
                missions,
                recordEnded,
                missionEnded,
                missionPaused,
                listMission,
                deleteMission,
                renameMission,
            }}
        >
            {children}
        </MissionContext.Provider>
    );
}

export default MissionProvider;
