import React, { useContext, useState, useEffect } from "react";
import { STATE_MOTOR_POWERED_ON, STATE_CLAIM_CLAIMED } from "../lib/state";
import { RobotStateContext } from "./RobotStateProvider";
import { useMission } from "./MissionProvider";
import { strCompare } from "../lib/utils";

import Section from "./Section";
import VideoButtons from "./VideoButtons";
import ErrorPopup from "./ErrorPopup";
import MissionItem from "./MissionItem";
import EntityAbsence from "./EntityAbsence";
import Icon from "./Icon";

import styles from "./css/SidePanel.module.css";
import { faCaretRight, faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import DummyForDev from "./DummyForDev";

function ArmControlSidePanel() {
    const { motorState, claimState } = useContext(RobotStateContext);
    const [selectedId, setSelectedId] = useState(null);
    const [recordError, setRecordError] = useState(null);
    const [isShow, setIsShow] = useState(true);
    const { missions, listMission } = useMission();
    const disabled = motorState !== STATE_MOTOR_POWERED_ON || claimState !== STATE_CLAIM_CLAIMED;

    useEffect(() => {
        listMission(true);
    }, []);

    return (
        <aside className={styles.container}>
            <div className={`${styles.body} ${!isShow && styles.hidden}`}>
                <p className={styles["record-mission-btn"]}>Record &amp; Missions</p>

                <Section retractable={false} style={{ width: "257px" }}>
                    <Section.Header>New Records</Section.Header>
                    <Section.Content>
                        <VideoButtons type="record" disabled={disabled} />
                    </Section.Content>
                </Section>
                {recordError && (
                    <ErrorPopup
                        type="error"
                        title={recordError.title}
                        content={recordError.content}
                        onClose={() => setRecordError(null)}
                    ></ErrorPopup>
                )}

                {!selectedId && (
                    <Section retractable={false} style={{ width: "257px" }}>
                        <Section.Header>
                            Mission Record
                            <button
                                className={styles["sm-action-btn"]}
                                onClick={() => listMission(true)}
                            >
                                (Refresh)
                            </button>
                        </Section.Header>
                        <Section.Content
                            scrollable={true}
                            className={styles.content}
                            style={{ maxHeight: "100%" }}
                        >
                            {missions ? (
                                missions.length > 0 ? (
                                    missions
                                        .sort(strCompare({ reverse: true }))
                                        .map((name) => (
                                            <MissionItem
                                                key={name}
                                                id={name}
                                                name={name}
                                                onClick={() => setSelectedId(name)}
                                            ></MissionItem>
                                        ))
                                ) : (
                                    <EntityAbsence
                                        title={"No Recorded Mission Yet"}
                                        btnText={"Record a Mission"}
                                    ></EntityAbsence>
                                )
                            ) : (
                                // skeleton
                                [...Array(5)].map((x, i) => (
                                    <MissionItem key={i} isLoading={true} />
                                ))
                            )}
                        </Section.Content>
                    </Section>
                )}
                {process.env.NODE_ENV === "development" && <DummyForDev />}
                <p className={styles.copyright}>
                    &copy;{new Date().getFullYear()} <b>R2C2</b> Ltd.
                </p>
            </div>
            <button
                className={`${styles.toggler} ${!isShow && styles.fade}`}
                style={{ background: "url(/panel-toggler-bg.png) no-repeat" }}
                onClick={() => setIsShow(!isShow)}
            >
                <Icon
                    icon={isShow ? faCaretLeft : faCaretRight}
                    type="fa"
                    size="lg"
                    style={{ color: "hsla(250, 49%, 50%, 1)" }}
                />
            </button>
        </aside>
    );
}

export default ArmControlSidePanel;
