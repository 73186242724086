import React from "react";

import FullScreenProvider from "./FullScreenProvider";
import CameraProvider from "./CameraProvider";
import ControlSocketProvider from "./ControlSocketProvider";

// all providers below need socket from ControlSocketProvider
import NotificationProvider from "./NotificationProvider";
import RobotStateProvider from "./RobotStateProvider";
import RobotControlProvider from "./RobotControlProvider";
import RobotMovementProvider from "./RobotMovementProvider";
import ArmStateProvider from "./ArmStateProvider";
import ArmControlProvider from "./ArmControlProvider";
import GamepadProvider from "./GamepadProvider";
import AirQProvider from "./AirQProvider";
import WifiSwitchingProvider from "./WifiSwitchingProvider";
import MissionProvider from "./MissionProvider";
import MissionLogProvider from "./MissionLogProvider";
// import AudioProvider from "./AudioProvider";
import SnackProvider from "./SnackProvider";

// Wrap components one by one. The component in the front would be in top level, and the other way around
function Compose({ components = [], children }) {
    return (
        <>
            {components.reduceRight((children, Component) => {
                return <Component>{children}</Component>;
            }, children)}
        </>
    );
}

function Providers({ children }) {
    return (
        <Compose
            components={[
                SnackProvider,
                FullScreenProvider,
                CameraProvider,
                ControlSocketProvider, // notice that providers below need this socket
                NotificationProvider,
                RobotStateProvider,
                RobotControlProvider,
                GamepadProvider,
                RobotMovementProvider,
                ArmStateProvider,
                ArmControlProvider,
                AirQProvider,
                WifiSwitchingProvider,
                MissionProvider,
                MissionLogProvider,
                //AudioProvider,
            ]}
        >
            {children}
        </Compose>
    );
}

export default Providers;
