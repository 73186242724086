import React from "react";
import { Redirect, useLocation } from "react-router-dom";
import { useAuth } from "./AuthProvider";
import ErrorPopup from "./ErrorPopup";

function Fallback() {
    const { user, isLoading } = useAuth();
    const location = useLocation();

    if (isLoading) return <ErrorPopup type="loading" title="Loading"></ErrorPopup>;

    return user.isAuthenticated ? (
        <Redirect to={{ pathname: "/main", state: { from: location } }} />
    ) : (
        <Redirect to={{ pathname: "/login", state: { from: location } }} />
    );
}

export default Fallback;
