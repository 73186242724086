import React, { useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import styles from "./css/LogsTab.module.css";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLog } from "./MissionLogProvider";

import Section from "./Section";
import EntityAbsence from "./EntityAbsence";
import { strCompare } from "../lib/utils";

export function LogBox({ onChoose, entry, isSelected }) {
    return (
        <button className={styles.box} onClick={() => onChoose()}>
            <div className={styles.entry}>
                <span>{entry.time}</span>
                <span>{entry.name}</span>
            </div>
            {isSelected && (
                <FontAwesomeIcon
                    icon={faCheckCircle}
                    className={styles.selected}
                    size="lg"
                ></FontAwesomeIcon>
            )}
        </button>
    );
}

const logSortFn = (a, b) => strCompare({ reverse: true })(a.time, b.time);

function LogsTab() {
    const history = useHistory();
    const match = useRouteMatch("/main/log-history/:logId");
    const { logId = null } = match?.params || {};
    const { logs, getLogs } = useLog();

    useEffect(() => {
        getLogs();
    }, []);

    return (
        <div style={{ overflowY: "auto", width: "301px" }}>
            <Section retractable={false}>
                <Section.Header>
                    Logs
                    <button className={styles["sm-action-btn"]} onClick={() => getLogs()}>
                        (Refresh)
                    </button>
                </Section.Header>
                <Section.Content className={styles.content}>
                    {logs.length > 0 ? (
                        logs
                            .sort(logSortFn)
                            .map((entry, index) => (
                                <LogBox
                                    key={index}
                                    entry={entry}
                                    onChoose={() =>
                                        history.push(`/main/log-history/${entry.log_id}`)
                                    }
                                    isSelected={logId === entry.log_id}
                                ></LogBox>
                            ))
                    ) : (
                        <EntityAbsence
                            title={"No Logged Mission Yet"}
                            btnText={"Start a Mission to Log One"}
                        ></EntityAbsence>
                    )}
                </Section.Content>
            </Section>
        </div>
    );
}

export default React.memo(LogsTab);
