import React, { useEffect, useState, useRef } from "react";
import { useControl } from "./RobotControlProvider";
import { XBOX } from "../lib/gamepad";
import isEqual from "lodash/isEqual";

export const GamepadContext = React.createContext();
GamepadContext.displayName = "Gamepad";
export const GamepadConsumer = GamepadContext.Consumer;

// backward compatibility
const requestAnimationFrame = (
    window.requestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.msRequestAnimationFrame
).bind(window);

const cancelAnimationFrame = (window.cancelAnimationFrame || window.mozCancelAnimationFrame).bind(
    window
);

const getGamepads = (navigator.getGamepads || navigator.webkitGetGamepads).bind(navigator);

// Some XBOX axes has a small false input value even when idle
// ignore everything around the deadzone
const DEADZONE = 0.12;
const filterAxis = (val) => (Math.abs(val) < DEADZONE ? 0 : val);

function GamepadProvider({ children }) {
    const { useGamepad } = useControl();
    const gamepads = useRef([]);
    const [axes, setAxes] = useState(null);
    const [buttons, setButtons] = useState(null);
    const [triggers, setTriggers] = useState(null);
    const reqId = useRef(0);

    const pollGamepads = () => {
        return (getGamepads && getGamepads()) || [];
    };

    const buttonValue = (b) => {
        return typeof b == "number" ? b : b.value;
    };

    const buttonPressed = (b) => {
        return typeof b == "number" ? b > 0.1 : b.pressed;
    };

    const tick = () => {
        if (!useGamepad) return;
        // console.log("ticking");
        gamepads.current = pollGamepads();
        const gamepad = gamepads.current[0];

        if (gamepad) {
            const _axes = XBOX.axes.reduce(
                (acc, axis, index) => ({ ...acc, [axis]: filterAxis(gamepad.axes[index]) }),
                {}
            );
            const _buttons = XBOX.buttons.reduce(
                (acc, btn, index) => ({ ...acc, [btn]: buttonPressed(gamepad.buttons[index]) }),
                {}
            );
            const _triggers = XBOX.triggers.reduce((acc, trigger, index) => {
                if (!trigger) return acc;
                else return { ...acc, [trigger]: buttonValue(gamepad.buttons[index]) };
            });
            setAxes((prev) => (isEqual(prev, _axes) ? prev : _axes));
            setButtons((prev) => (isEqual(prev, _buttons) ? prev : _buttons));
            setTriggers((prev) => (isEqual(prev, _triggers) ? prev : _triggers));
        }
        reqId.current = requestAnimationFrame(() => tick()); // loop
    };

    const stopTick = () => {
        reqId.current && cancelAnimationFrame(reqId.current);
    };

    useEffect(() => {
        const handleConnected = (e) => {
            console.log(e.gamepad.id, "connected");
            tick();
        };
        const handleDisconnected = (e) => {
            stopTick();
        };

        window.addEventListener("gamepadconnected", handleConnected);
        window.addEventListener("gamepaddisconnected", handleDisconnected);

        return () => {
            window.removeEventListener("gamepadconnected", handleConnected);
            window.removeEventListener("gamepaddisconnected", handleDisconnected);
            stopTick();
        };
    }, []);

    useEffect(() => {
        if (useGamepad) tick();
        else stopTick();
    }, [useGamepad]);

    return (
        <GamepadContext.Provider value={{ axes, buttons, triggers }}>
            {children}
        </GamepadContext.Provider>
    );
}

export default GamepadProvider;
