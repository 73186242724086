export const positionDummy = require("./graphnav-example.json");

export const dummyAirq = {
    samples: [
        {
            name: "TVOC",
            label: "latest",
            value: 0,
            unit: "ppb",
            high: 0,
            low: -1,
        },
        {
            name: "TVOC",
            label: "1hr",
            value: 0,
            unit: "ppb",
            high: -1,
            low: -1,
        },
        {
            name: "TVOC",
            label: "24hr",
            value: 0,
            unit: "ppb",
            high: -1,
            low: -1,
        },
        {
            name: "PM1.0",
            label: "latest",
            value: 8.800000190734863,
            unit: "ppb",
            high: -1,
            low: -1,
        },
        {
            name: "PM1.0",
            label: "1hr",
            value: 8.800000190734863,
            unit: "ppb",
            high: -1,
            low: -1,
        },
        {
            name: "PM1.0",
            label: "24hr",
            value: 8.800000190734863,
            unit: "ppb",
            high: -1,
            low: -1,
        },
        {
            name: "PM2.5",
            label: "latest",
            value: 10.199999809265137,
            unit: "ppb",
            high: 0,
            low: -1,
        },
        {
            name: "PM2.5",
            label: "1hr",
            value: 10.199999809265137,
            unit: "ppb",
            high: -1,
            low: -1,
        },
        {
            name: "PM2.5",
            label: "24hr",
            value: 10.199999809265137,
            unit: "ppb",
            high: -1,
            low: -1,
        },
        {
            name: "PM10",
            label: "latest",
            value: 11.5,
            unit: "ppb",
            high: 0,
            low: -1,
        },
        {
            name: "PM10",
            label: "1hr",
            value: 11.5,
            unit: "ppb",
            high: -1,
            low: -1,
        },
        {
            name: "PM10",
            label: "24hr",
            value: 11.5,
            unit: "ppb",
            high: -1,
            low: -1,
        },
        {
            name: "CO2",
            label: "latest",
            value: 880,
            unit: "ppb",
            high: 0,
            low: -1,
        },
        {
            name: "CO2",
            label: "1hr",
            value: 880,
            unit: "ppb",
            high: -1,
            low: -1,
        },
        {
            name: "CO2",
            label: "24hr",
            value: 880,
            unit: "ppb",
            high: -1,
            low: -1,
        },
        {
            name: "HCHO",
            label: "latest",
            value: 0,
            unit: "ppb",
            high: 0,
            low: -1,
        },
        {
            name: "HCHO",
            label: "1hr",
            value: 0,
            unit: "ppb",
            high: -1,
            low: -1,
        },
        {
            name: "HCHO",
            label: "24hr",
            value: 0,
            unit: "ppb",
            high: -1,
            low: -1,
        },
        {
            name: "NO2",
            label: "latest",
            value: 0,
            unit: "ppb",
            high: 0,
            low: -1,
        },
        {
            name: "NO2",
            label: "1hr",
            value: 0,
            unit: "ppb",
            high: -1,
            low: -1,
        },
        {
            name: "NO2",
            label: "24hr",
            value: 0,
            unit: "ppb",
            high: -1,
            low: -1,
        },
        {
            name: "AQI",
            label: "latest",
            value: 70,
            unit: "",
            high: -1,
            low: -1,
        },
        {
            name: "AQI",
            label: "1hr",
            value: 70,
            unit: "",
            high: -1,
            low: -1,
        },
        {
            name: "AQI",
            label: "24hr",
            value: 70,
            unit: "",
            high: -1,
            low: -1,
        },
        {
            name: "Temperature",
            label: "latest",
            value: 26.200000762939453,
            unit: "°C",
            high: 0,
            low: 0,
        },
        {
            name: "Relative Humidity",
            label: "latest",
            value: 51.099998474121094,
            unit: "%",
            high: 0,
            low: 0,
        },
    ],
    time: "1643339665000",
    statuses: [
        {
            name: "Version",
            value: "2",
        },
        {
            name: "Message Index",
            value: "3",
        },
        {
            name: "Message Index",
            value: "3",
        },
        {
            name: "Serial Number",
            value: "869098040430682",
        },
        {
            name: "Battery Voltage",
            value: "0mV",
        },
        {
            name: "Network Type",
            value: "LTE",
        },
        {
            name: "RSSI",
            value: "-92",
        },
        {
            name: "RSRQ",
            value: "12",
        },
        {
            name: "CI",
            value: "0341B91A",
        },
        {
            name: "TAC",
            value: "0585",
        },
        {
            name: "Running on Battery",
            value: "-",
        },
        {
            name: "Low Battery",
            value: "No",
        },
        {
            name: "Event Type",
            value: "2",
        },
        {
            name: "Debug Msgs",
            value: "[0, 0, 8, 0, 0, 0]",
        },
    ],
};

export const notiDummy = [
    {
        type: "iaq",
        title: "CO2 in High Value",
        body: "Lorem Ipsum in Dummy Text is somehow blah blah blah",
        time: "21:22:00",
        isRead: false,
    },
    {
        type: "object_detection",
        title: "Smoking Detected",
        body: "Lorem Ipsum in Dummy Text is somehow blah blah blah",
        time: "20:53:09",
        isRead: false,
    },
    {
        type: "iaq",
        title: "CO2 in High Value",
        body: "Lorem Ipsum in Dummy Text is somehow blah blah blah",
        time: "19:22:00",
        isRead: false,
    },
    {
        type: "object_detection",
        title: "Smoking Detected",
        body: "Lorem Ipsum in Dummy Text is somehow blah blah blah",
        time: "14:53:09",
        isRead: true,
    },
    {
        type: "iaq",
        title: "CO2 in High Value",
        body: "Lorem Ipsum in Dummy Text is somehow blah blah blah",
        time: "10:22:00",
        isRead: true,
    },
    {
        type: "object_detection",
        title: "Smoking Detected",
        body: "Lorem Ipsum in Dummy Text is somehow blah blah blah",
        time: "08:53:09",
        isRead: true,
    },
];
export const missionLogs = [
    {
        id: "name-1",
        name: "Name 1",
        videos: [
            {
                name: "front",
                url: "https://localhost:3000/",
            },
            {
                name: "back",
                url: "https://localhost:3000/",
            },
            {
                name: "left",
                url: "https://localhost:3000/",
            },
            {
                name: "right",
                url: "https://localhost:3000/",
            },
        ],
        events: [
            {
                type: "Smoking",
                timestamp: Math.floor(new Date().getTime() / 1000),
                data: "String",
            },
            {
                type: "Breathing",
                timestamp: Math.floor(new Date().getTime() / 1000),
                data: "String",
            },
            {
                type: "Breathing",
                timestamp: Math.floor(new Date().getTime() / 1000),
                data: "String",
            },
            {
                type: "Breathing",
                timestamp: Math.floor(new Date().getTime() / 1000),
                data: "String",
            },
            {
                type: "Breathing",
                timestamp: Math.floor(new Date().getTime() / 1000),
                data: "String",
            },
            {
                type: "Breathing",
                timestamp: Math.floor(new Date().getTime() / 1000),
                data: "String",
            },
            {
                type: "Breathing",
                timestamp: Math.floor(new Date().getTime() / 1000),
                data: "String",
            },
            {
                type: "Breathing",
                timestamp: Math.floor(new Date().getTime() / 1000),
                data: "String",
            },
            {
                type: "Breathing",
                timestamp: Math.floor(new Date().getTime() / 1000),
                data: "String",
            },
        ],
        iaq: [],
    },
    {
        id: "buffet-2",
        name: "Buffet 2",
        videos: [],
        events: [
            {
                type: "String Ya",
                timestamp: Math.floor(new Date().getTime() / 1000),
                data: "String",
            },
        ],
        iaq: null,
    },
];

export const dummyMissionNames = [
    { name: "mission 1", log_id: "935defbae21b431284b68f08ff32cda3", time: "2022-01-25 19:51:30" },
    { name: "mission 2", log_id: "728411e3bc244f6db503189bb8b5e2c8", time: "2022-01-25 22:51:30" },
    { name: "mission 3", log_id: "081808caefab4ded865ebe2a21c3b42b", time: "2022-01-25 23:51:30" },
    { name: "mission 4", log_id: "081808caefab4de93n233e2a21c3b42b", time: "2022-01-25 23:51:30" },
    { name: "mission 5", log_id: "728411e3bc93n233e2f08ff33bc244fs", time: "2022-01-25 23:51:30" },
];

export const dummyMissionLogs = [
    {
        // /mission/log/list
        name: "Mission 1",

        // /mission/log/events
        events: [
            { timestamp: Date(), type: "OBJECT_DETECTED", data: ["Smoker"] },
            { timestamp: Date(), type: "OBJECT_DETECTED", data: ["Human"] },
            { timestamp: Date(), type: "OBJECT_DETECTED", data: ["Thief"] },
            { timestamp: Date(), type: "OBJECT_DETECTED", data: ["Item1"] },
            { timestamp: Date(), type: "OBJECT_DETECTED", data: ["Item2"] },
            {
                timestamp: Date("2022/01/18"),
                type: "IAQ",
                data: {
                    samples: [
                        {
                            name: "TVOC",
                            label: "latest",
                            value: 22,
                            unit: "ppb",
                            high: 0,
                            low: -1,
                        },
                        {
                            name: "PM1.0",
                            label: "latest",
                            value: 2.9,
                            unit: "ppb",
                            high: 0,
                            low: -1,
                        },
                        {
                            name: "PM2.5",
                            label: "latest",
                            value: 7.8,
                            unit: "ppb",
                            high: 0,
                            low: -1,
                        },
                        {
                            name: "PM10",
                            label: "latest",
                            value: 4.3,
                            unit: "ppb",
                            high: 0,
                            low: -1,
                        },
                        {
                            name: "HCHO",
                            label: "latest",
                            value: 23,
                            unit: "ppb",
                            high: 0,
                            low: -1,
                        },
                        {
                            name: "NO2",
                            label: "latest",
                            value: 77,
                            unit: "ppb",
                            high: 0,
                            low: -1,
                        },
                    ],
                    time: new Date(),
                    statuses: [
                        { name: "Version", value: "2" },
                        { name: "Message Index", value: "5194" },
                    ],
                },
            },
            {
                timestamp: Date("2022/01/19"),
                type: "IAQ",
                data: {
                    samples: [
                        {
                            name: "TVOC",
                            label: "latest",
                            value: 23,
                            unit: "ppb",
                            high: 0,
                            low: -1,
                        },
                        {
                            name: "PM1.0",
                            label: "latest",
                            value: 3.9,
                            unit: "ppb",
                            high: 0,
                            low: -1,
                        },
                        {
                            name: "PM2.5",
                            label: "latest",
                            value: 6.8,
                            unit: "ppb",
                            high: 0,
                            low: -1,
                        },
                        {
                            name: "PM10",
                            label: "latest",
                            value: 3.3,
                            unit: "ppb",
                            high: 0,
                            low: -1,
                        },
                        {
                            name: "HCHO",
                            label: "latest",
                            value: 20,
                            unit: "ppb",
                            high: 0,
                            low: -1,
                        },
                        {
                            name: "NO2",
                            label: "latest",
                            value: 55,
                            unit: "ppb",
                            high: 0,
                            low: -1,
                        },
                    ],
                    time: new Date(new Date().getTime() + 20000),
                    statuses: [
                        { name: "Version", value: "2" },
                        { name: "Message Index", value: "5194" },
                    ],
                },
            },
            {
                timestamp: Date("2022/01/20"),
                type: "IAQ",
                data: {
                    samples: [
                        {
                            name: "TVOC",
                            label: "latest",
                            value: 25,
                            unit: "ppb",
                            high: 0,
                            low: -1,
                        },
                        {
                            name: "PM1.0",
                            label: "latest",
                            value: 3.3,
                            unit: "ppb",
                            high: 0,
                            low: -1,
                        },
                        {
                            name: "PM2.5",
                            label: "latest",
                            value: 6.83,
                            unit: "ppb",
                            high: 0,
                            low: -1,
                        },
                        {
                            name: "PM10",
                            label: "latest",
                            value: 4.12,
                            unit: "ppb",
                            high: 0,
                            low: -1,
                        },
                        {
                            name: "HCHO",
                            label: "latest",
                            value: 22,
                            unit: "ppb",
                            high: 0,
                            low: -1,
                        },
                        {
                            name: "NO2",
                            label: "latest",
                            value: 60,
                            unit: "ppb",
                            high: 0,
                            low: -1,
                        },
                    ],
                    time: new Date(new Date().getTime() + 40000),
                    statuses: [
                        { name: "Version", value: "2" },
                        { name: "Message Index", value: "5194" },
                    ],
                },
            },
            { timestamp: Date(), type: "MISSION_STARTING", date: null },
            { timestamp: Date(), type: "MISSION_END", data: null },
        ],

        // /mission/log/vids
        videos: [
            {
                name: "front",
                url: "https://localhost:3000/",
            },
            {
                name: "back",
                url: "https://localhost:3000/",
            },
            {
                name: "left",
                url: "https://localhost:3000/",
            },
            {
                name: "right",
                url: "https://localhost:3000/",
            },
        ],

        // /mission/log/export
        export: "http://localhost:3000/mission/log/12345.csv",
    },
];

export const debugLogs = [
    {
        title: "Title Lorem Ipsum is simply dummy text",
        output: `{d:function(){return window.yt&&yt.config_||ytcfg.data_||(ytcfg.data_={})},get:function(k,o){return k in ytcfg.d()?ytcfg.d()[k]:o},set:function(){var a=arguments;if(a.length>1)ytcfg.d()[a[0]]=a[1];else for(var k in a[0])ytcfg.d()[k]=a[0][k]}}; window.ytcfg.set('EMERGENCY_BASE_URL', '/error_204?t\x3djserror\x26level\x3dERROR\x26client.name\x3d1\x26client.version\x3d2.20211221.00.00');</script><script nonce="BZlI3jfGb162VK8Z4X/qCw">(function(){window.yterr=window.yterr||true;window.unhandledErrorMessages={};window.unhandledErrorCount=0; window.onerror=function(msg,url,line,columnNumber,error){var err;if(error)err=error;else{err=new Error;err.stack="";err.message=msg;err.fileName=url;err.lineNumber=line;if(!isNaN(columnNumber))err["columnNumber"]=columnNumber}var message=String(err.message);if(!err.message||message in window.unhandledErrorMessages||window.unhandledErrorCount>=5)return;window.unhandledErrorCount+=1;window.unhandledErrorMessages[message]=true;var img=new Image;window.emergencyTimeoutImg=img;img.onload=img.onerror=function(){delete window.emergencyTimeoutImg};`,
    },
    {
        title: "Another One",
        output: `{d:function(){return window.yt&&yt.config_||ytcfg.data_||(ytcfg.data_={})},get:function(k,o){return k in ytcfg.d()?ytcfg.d()[k]:o},set:function(){var a=arguments;if(a.length>1)ytcfg.d()[a[0]]=a[1];else for(var k in a[0])ytcfg.d()[k]=a[0][k]}}; window.ytcfg.set('EMERGENCY_BASE_URL', '/error_204?t\x3djserror\x26level\x3dERROR\x26client.name\x3d1\x26client.version\x3d2.20211221.00.00');</script><script nonce="BZlI3jfGb162VK8Z4X/qCw">(function(){window.yterr=window.yterr||true;window.unhandledErrorMessages={};window.unhandledErrorCount=0; window.onerror=function(msg,url,line,columnNumber,error){var err;if(error)err=error;else{err=new Error;err.stack="";err.message=msg;err.fileName=url;err.lineNumber=line;if(!isNaN(columnNumber))err["columnNumber"]=columnNumber}var message=String(err.message);if(!err.message||message in window.unhandledErrorMessages||window.unhandledErrorCount>=5)return;window.unhandledErrorCount+=1;window.unhandledErrorMessages[message]=true;var img=new Image;window.emergencyTimeoutImg=img;img.onload=img.onerror=function(){delete window.emergencyTimeoutImg};`,
    },
    {
        title: "And Another One",
        output: `{d:function(){return window.yt&&yt.config_||ytcfg.data_||(ytcfg.data_={})},get:function(k,o){return k in ytcfg.d()?ytcfg.d()[k]:o},set:function(){var a=arguments;if(a.length>1)ytcfg.d()[a[0]]=a[1];else for(var k in a[0])ytcfg.d()[k]=a[0][k]}}; window.ytcfg.set('EMERGENCY_BASE_URL', '/error_204?t\x3djserror\x26level\x3dERROR\x26client.name\x3d1\x26client.version\x3d2.20211221.00.00');</script><script nonce="BZlI3jfGb162VK8Z4X/qCw">(function(){window.yterr=window.yterr||true;window.unhandledErrorMessages={};window.unhandledErrorCount=0; window.onerror=function(msg,url,line,columnNumber,error){var err;if(error)err=error;else{err=new Error;err.stack="";err.message=msg;err.fileName=url;err.lineNumber=line;if(!isNaN(columnNumber))err["columnNumber"]=columnNumber}var message=String(err.message);if(!err.message||message in window.unhandledErrorMessages||window.unhandledErrorCount>=5)return;window.unhandledErrorCount+=1;window.unhandledErrorMessages[message]=true;var img=new Image;window.emergencyTimeoutImg=img;img.onload=img.onerror=function(){delete window.emergencyTimeoutImg};`,
    },
    {
        title: "And The Last One",
        output: `{d:function(){return window.yt&&yt.config_||ytcfg.data_||(ytcfg.data_={})},get:function(k,o){return k in ytcfg.d()?ytcfg.d()[k]:o},set:function(){var a=arguments;if(a.length>1)ytcfg.d()[a[0]]=a[1];else for(var k in a[0])ytcfg.d()[k]=a[0][k]}}; window.ytcfg.set('EMERGENCY_BASE_URL', '/error_204?t\x3djserror\x26level\x3dERROR\x26client.name\x3d1\x26client.version\x3d2.20211221.00.00');</script><script nonce="BZlI3jfGb162VK8Z4X/qCw">(function(){window.yterr=window.yterr||true;window.unhandledErrorMessages={};window.unhandledErrorCount=0; window.onerror=function(msg,url,line,columnNumber,error){var err;if(error)err=error;else{err=new Error;err.stack="";err.message=msg;err.fileName=url;err.lineNumber=line;if(!isNaN(columnNumber))err["columnNumber"]=columnNumber}var message=String(err.message);if(!err.message||message in window.unhandledErrorMessages||window.unhandledErrorCount>=5)return;window.unhandledErrorCount+=1;window.unhandledErrorMessages[message]=true;var img=new Image;window.emergencyTimeoutImg=img;img.onload=img.onerror=function(){delete window.emergencyTimeoutImg};`,
    },
];

export const dummyIaqChart = [
    {
        time: "09:00",
        TVOC: 22,
        "PM1.0": 2.9,
        "PM2.5": 3.8,
        PM10: 4.3,
        HCHO: 23,
        NO2: 77,
    },
    {
        time: "09:30",
        TVOC: 50,
        "PM1.0": 1,
        "PM2.5": 7.8,
        PM10: 4.3,
        HCHO: 23,
        NO2: 77,
    },
    {
        time: "10:00",
        TVOC: 22,
        "PM1.0": 2.9,
        "PM2.5": 3.8,
        PM10: 4.3,
        HCHO: 23,
        NO2: 77,
    },
    {
        time: "10:30",
        TVOC: 22,
        "PM1.0": 2.9,
        "PM2.5": 3.8,
        PM10: 4.3,
        HCHO: 23,
        NO2: 77,
    },
    {
        time: "11:00",
        TVOC: 22,
        "PM1.0": 2.9,
        "PM2.5": 3.8,
        PM10: 4.3,
        HCHO: 23,
        NO2: 77,
    },
    {
        time: "11:30",
        TVOC: 22,
        "PM1.0": 2.9,
        "PM2.5": 3.8,
        PM10: 4.3,
        HCHO: 23,
        NO2: 77,
    },
];

export const dummyAIModel = [
    {
        id: "01",
        title: "Plugin",
        author: "R2C2",
        longName: "Plugin: Who am I",
        summary:
            "Lorem Ipsum is simply dummy text of the printing and typesetting indu asjhdfjka aksdjf a lkdjf aokdfjo kajdslkf ad",
        details:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. ",
        logo: "/AI-plugin.png",
    },
    {
        id: "02",
        title: "Plugin",
        author: "R2C2",
        longName: "Plugin: Where am I",
        summary:
            "Lorem Ipsum is simply dummy text of the printing and typesetting indu a;kldsjf aklsjfd alksdfn oakdsfmn ",
        details: "alksdjfklas alkdsfj asfd",
        logo: "/AI-plugin.png",
    },
    {
        id: "03",
        title: "Plugin",
        author: "R2C2",
        longName: "Plugin: What am I doing",
        summary: "Lorem Ipsum is simply dummy text of the printing and typesetting indu",
        details: "alksjf adslkf alsmfd oakewi0qje ronq45t9ugoa sfoijaoienjfoinqewf",
        logo: "/AI-plugin.png",
    },
    {
        id: "04",
        title: "Plugin",
        author: "R2C2",
        longName: "Plugin: Why am I here",
        summary: "Lorem Ipsum is simply dummy text of the printing and typesetting indu ",
        details:
            "l;kasejfokawj fokm acpohejoksa fnijwbofjnvhweg fijmvaosignliuasg bn9pvuqh ewofjv hqnweogjherwojbgosa9efblaiusefblijahsehf",
        logo: "/AI-plugin.png",
    },
];
