import { useState, useEffect } from "react";
import isEmpty from "lodash/isEmpty";

export const getLocalStorage = (key) => JSON.parse(localStorage.getItem(key));
export const setLocalStorage = (key, value) => localStorage.setItem(key, JSON.stringify(value));
/**
 * @summary delete data with key, clear the localStorage if key is not provided
 * @param {string} key
 */
export const delLocalStorage = (key = null) =>
    key !== null ? localStorage.removeItem(key) : localStorage.clear();

const isObjectLike = (value) =>
    value.constructor.name === "Array" || value.constructor.name === "Object";

const useStorage = (key, initialState) => {
    const [state, setState] = useState(() => getLocalStorage(key) ?? initialState);

    useEffect(() => {
        if (state === null || (isObjectLike(state) && isEmpty(state))) {
            delLocalStorage(key);
            return;
        }
        // console.log("useStorage state changed: ", key, ": ", state);
        setLocalStorage(key, state);
    }, [key, state]);

    return [state, setState];
};

export default useStorage;
