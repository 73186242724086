import React from "react";
import styles from "./css/Switch.module.css";

function Switch({
    size = "31px",
    title = "",
    isOn = false,
    onToggle = () => {},
    btnClass = null,
    style,
    disabled = false,
}) {
    return (
        <div className={styles["toggler-container"]} style={style}>
            <h3>{title}</h3>
            <button
                style={{ fontSize: size }}
                className={`${styles.switch} ${isOn && styles.on} ${btnClass ?? ""}`}
                disabled={disabled}
                onClick={(e) => {
                    e.stopPropagation();
                    onToggle();
                }}
            >
                <div aria-label="thumb"></div>
            </button>
        </div>
    );
}

export default Switch;
