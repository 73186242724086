import { useState, useEffect } from "react";
import { digest, getCookie } from "../auth";

export default function useFindUser(username, pwHash) {
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function validateUser() {
            const cookie = getCookie("user");
            const hash = await digest(username + pwHash);
            return cookie === hash;
        }

        validateUser()
            .then((isValid) => {
                setUser({
                    isAuthenticated: isValid,
                    username,
                    metadata: {},
                });
                setIsLoading(false);
            })
            .catch((e) => setIsLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        user,
        setUser,
        isLoading,
    };
}
