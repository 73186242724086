import React, { useState, useEffect, useContext } from "react";
import * as CONST from "../consts";
import { MODE_SIT, MODE_STAND, MODE_WALK, MODE_CRAWL, MODE_STAIR } from "../lib/protocols/control";
import { errToMsg, STATE_RECORD_FAILED, STATE_MISSION_GENERATING_FAILED } from "../lib/state";
import RangeSlider from "./RangeSlider";
import Section from "./Section";
import ErrorPopup from "./ErrorPopup";
import VideoButtons from "./VideoButtons";
import Switch from "./Switch";
import { RobotStateContext } from "./RobotStateProvider";
import styles from "./css/GeneralTab.module.css";
import { useControl } from "./RobotControlProvider";
import { useMission } from "./MissionProvider";
import DummyForDev from "./DummyForDev";
import { useArmControl } from "./ArmControlProvider";

function GeneralTab() {
    const [recordError, setRecordError] = useState(null);
    const {
        changeSpeed,
        changeMode,
        changeHeight,
        useKeyboard,
        setUseKeyboard,
        useGamepad,
        setUseGamepad,
        speed,
    } = useControl();
    const { recordState, recordPayload } = useMission();
    const { robotControllable, robotMode, robotHeight } = useContext(RobotStateContext);
    const disabled = !robotControllable;
    const { inArmControl, toggleArmControl } = useArmControl();

    useEffect(() => {
        if ([STATE_RECORD_FAILED, STATE_MISSION_GENERATING_FAILED].includes(recordState)) {
            const { err } = recordPayload;
            setRecordError({ title: recordState, content: `${err}: ${errToMsg(err)}` });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recordState]);

    const robotControlsClassNames = styles[!robotControllable ? "disabled-controls" : ""];

    return (
        <>
            <div className={`${styles["control-container"]} ${robotControlsClassNames}`}>
                <Section className={styles.controls}>
                    <Section.Header>Basic Controls</Section.Header>
                    <Section.Content>
                        <h3 style={{ marginBottom: "8px" }}>Pose</h3>

                        <div className={styles["pose-btn-wrapper"]}>
                            {[
                                { label: "Sit", mode: MODE_SIT },
                                { label: "Stand", mode: MODE_STAND },
                                { label: "Walk", mode: MODE_WALK },
                                { label: "Crawl", mode: MODE_CRAWL },
                                { label: "Stair", mode: MODE_STAIR },
                            ].map(({ label, mode }) => (
                                <button
                                    key={label}
                                    className={
                                        (!inArmControl && robotMode === mode && styles.active) || ""
                                    }
                                    onClick={() => {
                                        toggleArmControl(false);
                                        changeMode(mode);
                                    }}
                                    disabled={disabled}
                                >
                                    {label}
                                </button>
                            ))}
                            <button
                                className={(inArmControl && styles.active) || ""}
                                onClick={() => {
                                    toggleArmControl(true);
                                }}
                                disabled={disabled}
                            >
                                Arm
                            </button>
                        </div>

                        <div
                            className={`${styles["slider-container"]} ${robotControlsClassNames}`}
                            style={{ marginTop: "25px" }}
                        >
                            <RangeSlider
                                lazy={false}
                                name="speed"
                                unit="m/s"
                                onChange={(value) => changeSpeed(value)}
                                options={{
                                    min: CONST.SPEED_SLOW,
                                    max: CONST.SPEED_FAST,
                                    step: CONST.SPEED_STEP,
                                    defaultValue: speed,
                                    controlled: true,
                                }}
                                disabled={disabled}
                            ></RangeSlider>
                            <RangeSlider
                                name="height"
                                unit="m"
                                onChange={(h) => changeHeight(h)}
                                options={{
                                    min: CONST.HEIGHT_MIN,
                                    max: CONST.HEIGHT_MAX,
                                    step: CONST.HEIGHT_STEP,
                                    defaultValue: robotHeight,
                                    lazy: false,
                                    controlled: true,
                                }}
                                disabled={disabled}
                            ></RangeSlider>

                            {process.env.NODE_ENV === "development" && (
                                <div className={styles["slider-container"]}>
                                    <RangeSlider
                                        name="Avoidn."
                                        unit="m"
                                        onChange={() => {}}
                                        options={{
                                            min: CONST.AVOID_MIN,
                                            max: CONST.AVOID_MAX,
                                            step: CONST.AVOID_STEP,
                                            defaultValue: CONST.AVOID_DEFAULT,
                                        }}
                                        disabled={disabled}
                                    ></RangeSlider>
                                    <p style={{ margin: 0, fontSize: "x-small" }}>
                                        note: ⬆ avoidance currently disabled and only visible in dev
                                        mode
                                    </p>
                                </div>
                            )}

                            {
                                // not implemented
                                false && (
                                    <RangeSlider
                                        name="light"
                                        onChange={(value) => {}}
                                        options={{
                                            min: CONST.LIGHT_MIN,
                                            max: CONST.LIGHT_MAX,
                                            step: CONST.LIGHT_STEP,
                                            defaultValue: CONST.LIGHT_DEFAULT,
                                        }}
                                        disabled={disabled}
                                    ></RangeSlider>
                                )
                            }
                        </div>
                    </Section.Content>
                </Section>
                <Section className={styles.controls}>
                    <Section.Header>New Record</Section.Header>
                    <Section.Content>
                        <VideoButtons type="record" disabled={disabled} />
                    </Section.Content>
                </Section>

                <Section className={styles["advanced-control"]} retracted={true}>
                    <Section.Header>Advanced Controls</Section.Header>
                    <Section.Content style={{ margin: "5px auto" }}>
                        <Switch
                            title="WASD Control"
                            isOn={useKeyboard}
                            onToggle={() => {
                                setUseKeyboard(!useKeyboard);
                            }}
                            disabled={disabled}
                        ></Switch>
                        {process.env.NODE_ENV === "development" && (
                            <>
                                <Switch
                                    title="Gamepad control"
                                    isOn={useGamepad}
                                    onToggle={() => {
                                        setUseGamepad(!useGamepad);
                                    }}
                                    disabled={disabled}
                                ></Switch>
                                <p style={{ margin: 0, fontSize: "x-small" }}>
                                    note: ⬆ gamepad currently disabled and only visible in dev mode.
                                    Pending tests!
                                </p>
                            </>
                        )}
                    </Section.Content>
                </Section>
            </div>

            <div>
                {/* Development only. Does not appear in builds */}
                {process.env.NODE_ENV === "development" && <DummyForDev />}
                {/* end Development only */}
            </div>

            {recordError && (
                <ErrorPopup
                    type="error"
                    title={recordError.title}
                    content={recordError.content}
                    onClose={() => setRecordError(null)}
                ></ErrorPopup>
            )}
        </>
    );
}

export default React.memo(GeneralTab);
