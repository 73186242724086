import React, { useState, useLayoutEffect, useEffect } from "react";
import { useParams } from "react-router-dom";
import styles from "./css/LogHistory.module.css";
import Summary from "./LogHistorySummary";
import Detail from "./LogHistoryDetail";
import Replay from "./LogHistoryReplay";
import IAQChart from "./LogHistoryIAQChart";
import EntityAbsence from "./EntityAbsence";

import {
    useLog,
    getMissionNameById,
    getDetectionKeywords,
    getKeywords,
} from "./MissionLogProvider";
import { download_table_as_csv } from "../lib/utils";
import LogHistoryErrorFallback from "./LogHistoryErrorFallback";
import { useSnack } from "./SnackProvider";

function LogHistory() {
    const { logId = "" } = useParams(); // id is the kebabcase of name
    const { missions, events, videos, IAQ, getLog } = useLog();
    const [tabIndex, setTabIndex] = useState(0);
    const [title, setTitle] = useState("Mission not found");
    const { setSnack } = useSnack();
    // set the types of detected objects displayed. null means all of them are displayed
    const [selectedKeywords, setSelectedKeywords] = useState([]);
    const [filteredEvents, setFilteredEvents] = useState([]);

    // call before content is displayed
    useLayoutEffect(() => {
        if (logId === "") return;
        const name = getMissionNameById(missions, logId);
        name && setTitle(name);
        getLog(logId).then(() => {
            setTabIndex(0);
        });
    }, [logId]);

    useEffect(() => {
        const kwRegex = new RegExp(`.*${selectedKeywords.map((kw) => `(${kw})`).join("|")}.*`, "i");
        setFilteredEvents(
            selectedKeywords.length === 0
                ? events
                : events.filter(
                      (e) => e.type.match(kwRegex) || JSON.stringify(e.data).match(kwRegex)
                  )
        );
    }, [selectedKeywords, events]);

    let content;
    if (logId)
        content = (
            <div className={styles["container"]}>
                <header className={styles["header"]}>
                    <h1 className={styles["title"]}>{title}</h1>
                    <button
                        type="button"
                        className={styles["export-btn"]}
                        onClick={() => download_table_as_csv("mission-events-details")}
                    >
                        Export CSV
                    </button>
                </header>

                <div className={styles["tab-container"]}>
                    <button
                        type="button"
                        className={styles["tab"]}
                        data-active={tabIndex === 0 ? "1" : "0"}
                        onClick={() => setTabIndex(0)}
                    >
                        Log Overview
                    </button>

                    <button
                        type="button"
                        className={styles["tab"]}
                        data-active={tabIndex === 1 ? "1" : "0"}
                        onClick={() => setTabIndex(1)}
                        title={videos.length <= 0 ? "No videos available" : ""}
                    >
                        Video Replay
                    </button>

                    <button
                        type="button"
                        className={styles["tab"]}
                        title={IAQ.length <= 0 ? "No IAQ data available" : ""}
                        data-active={tabIndex === 2 ? "1" : "0"}
                        onClick={() => setTabIndex(2)}
                    >
                        IAQ Chart
                    </button>
                </div>

                <Summary
                    isShow={tabIndex === 0}
                    keywords={getKeywords(events)}
                    selectedKeywords={selectedKeywords}
                    setSelectedKeywords={setSelectedKeywords}
                />

                <Replay isShow={tabIndex === 1} videos={videos} />

                <IAQChart isShow={tabIndex === 2} IAQData={IAQ} />

                <Detail
                    isShow={tabIndex === 0}
                    events={filteredEvents}
                    selectedKeywords={selectedKeywords}
                />
            </div>
        );
    else
        content = (
            <div className={styles.container}>
                <EntityAbsence
                    title={"No Selected Log Yet"}
                    btnText={"Select a Log on the left"}
                    path={"/main/log-history"}
                ></EntityAbsence>
            </div>
        );
    return <LogHistoryErrorFallback>{content}</LogHistoryErrorFallback>;
}

export default LogHistory;
