import React from "react";

import styles from "./css/Crosshair.module.css";
import { ReactComponent as IconPlus } from "../svg/IconPlus.svg";

import Icon from "./Icon";

const dashedCircleStyle = (dash, gap, offset = 0, width, color) => ({
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='${color.replace(
        "#",
        "%23"
    )}' stroke-width='${width}' stroke-dasharray='${dash}%2c ${gap}' stroke-dashoffset='${offset}' stroke-linecap='square'/%3e%3c/svg%3e")`,
});

const dashPresets = {
    "dash-circle": dashedCircleStyle(31, 16, 11, 5, "#999AFA"),
    "dash-circle-big": dashedCircleStyle(60, 30, 20, 5, "#999AFA"),
};

function Crosshair({ isFocus = false, type }) {
    return (
        <div
            className={`${styles.container} ${isFocus && styles[type]}`}
            style={dashPresets[type] || null}
        >
            <Icon icon={IconPlus} type="svg" width="24px" height="24px" />
        </div>
    );
}

export default Crosshair;
