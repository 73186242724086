import React from "react";
import ErrorPopup from "./ErrorPopup";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hasError: false,
            title: null,
            content: null,
        };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, title: "haha" };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({
            hasError: true,
            title: "error",
            content: errorInfo,
        });
    }

    handleClose() {
        // FIXME: logic error
        this.setState({
            hasError: false,
            title: null,
            content: null,
        });
    }

    render() {
        if (this.state.hasError) {
            return (
                <ErrorPopup
                    type="error"
                    title={this.state.title}
                    content={this.state.content}
                    onClose={() => this.handleClose()}
                />
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
