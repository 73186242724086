import React, { useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { dummyAIModel } from "../testdata";

import styles from "./css/AIModel.module.css";
import { ReactComponent as IconDownload } from "../svg/IconDownload.svg";
import Switch from "./Switch";

// find out whenever the user own this model
function AIModel() {
    const { modelId = null } = useParams();
    const [enableModel, setEnableModel] = useState(false);
    const models = dummyAIModel;

    const model = models.find(({ id }) => id === modelId);
    const purchased = false; // user provider

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.title}>
                    <h1>{model.longName}</h1>
                    <span>{model.author}</span>
                </div>
                <img
                    src={model.logo}
                    alt={model.title}
                    title={model.title}
                    className={styles.logo}
                />
            </div>
            {purchased ? (
                <Switch
                    isOn={enableModel}
                    onToggle={() => setEnableModel(!enableModel)}
                    btnClass={`${styles.switch} ${enableModel && styles["switch-on"]}`}
                ></Switch>
            ) : (
                <button className={styles.install}>
                    <IconDownload
                        title={`Install ${model.title}`}
                        width="24px"
                        height="24px"
                    ></IconDownload>
                    <span>Install</span>
                    <span>8.8k</span>
                </button>
            )}

            <p>{model.details}</p>
        </div>
    );
}

export default AIModel;
