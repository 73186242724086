import React, { useState, useEffect } from "react";
import {
    TOPIC_STATE_MODE,
    TOPIC_STATE_MOTOR,
    TOPIC_STATE_ESTOP,
    TOPIC_STATE_CLAIM_MOTOR,
    TOPIC_STATE_ROBOT_CONNECTION,
    TOPIC_STATE_CLAIM_ESTOP,
    TOPIC_STATE_MAX_SPEED,
    TOPIC_STATE_BATTERY_CHARGE,
    TOPIC_STATE_BATTERY_STATUS,
    STATE_MOTOR_UNKNOWN,
    STATE_BATTERY_UNKNOWN,
    STATE_CLAIM_UNKNOWN,
    STATE_ROBOT_DISCONNECTED,
    STATE_CLAIM_NO_CLAIM,
    STATE_MOTOR_POWERED_ON,
    STATE_CLAIM_CLAIMED,
    STATE_ROBOT_CONNECTED,
    TOPIC_STATE_HEIGHT,
} from "../lib/state";
import { MODE_SIT, MODE_STAND } from "../lib/protocols/control";
import { subscribe, unsubscribe } from "../lib/socket";
import { useSocket } from "./ControlSocketProvider";

export const RobotStateContext = React.createContext();
RobotStateContext.displayName = "RobotState";
export const RobotStateConsumer = RobotStateContext.Consumer;

const topicStateMap = {
    robotMode: TOPIC_STATE_MODE,
    robotHeight: TOPIC_STATE_HEIGHT,
    robotConnectionState: TOPIC_STATE_ROBOT_CONNECTION,
    motorState: TOPIC_STATE_MOTOR,
    estopState: TOPIC_STATE_ESTOP,
    maxSpeed: TOPIC_STATE_MAX_SPEED,
    batteryPercent: TOPIC_STATE_BATTERY_CHARGE,
    batteryStatus: TOPIC_STATE_BATTERY_STATUS,
    motorClaimState: TOPIC_STATE_CLAIM_MOTOR,
    eStopClaimState: TOPIC_STATE_CLAIM_ESTOP,
};

const stateNormalizers = {
    robotHeight: (val) => val.toPrecision(1),
    robotMode: (val) => val.toLowerCase(),
};

const DEFAULT_ROBOT_STATE = {
    robotMode: MODE_SIT,
    robotHeight: 0,
    robotConnectionState: STATE_ROBOT_DISCONNECTED,
    motorState: STATE_MOTOR_UNKNOWN,
    estopState: { software: false, hardware: false },
    maxSpeed: 0,
    batteryPercent: 0,
    batteryStatus: STATE_BATTERY_UNKNOWN,
    motorClaimState: STATE_CLAIM_UNKNOWN,
    eStopClaimState: STATE_CLAIM_UNKNOWN,
};

const ROBOT_STATE_KEYS = Object.keys(DEFAULT_ROBOT_STATE);

function RobotStateProvider({ children }) {
    const { controlSocket: socket } = useSocket();
    const [robotStates, setRobotStates] = useState(DEFAULT_ROBOT_STATE);
    // derived states
    const [robotControllable, setRobotControllable] = useState(false);
    const [robotMovable, setRobotMovable] = useState(false);
    const [isEstopped, setIsEstopped] = useState(true);

    const setRobotState = (key, state) => {
        setRobotStates((prev) => ({
            ...prev,
            [key]: state,
        }));
    };

    useEffect(() => {
        const { motorState, motorClaimState, estopState, eStopClaimState, robotConnectionState } =
            robotStates;
        const _isEstopped = Object.values(estopState).some((state) => state);
        const _robotControllable =
            robotConnectionState === STATE_ROBOT_CONNECTED &&
            motorClaimState === STATE_CLAIM_CLAIMED &&
            eStopClaimState === STATE_CLAIM_CLAIMED &&
            !_isEstopped;
        setRobotControllable(_robotControllable);
        setRobotMovable(_robotControllable && motorState === STATE_MOTOR_POWERED_ON);
        setIsEstopped(_isEstopped);
    }, [robotStates]);

    useEffect(() => {
        const handlers = {};
        ROBOT_STATE_KEYS.forEach((key) => {
            const handler = (data) =>
                setRobotState(
                    key,
                    // normalize data if normalizer is configured
                    stateNormalizers[key] ? stateNormalizers[key](data) : data
                );
            handlers[key] = handler;
            subscribe(socket, topicStateMap[key], handler);
        });

        return () => {
            if (!socket) return;
            ROBOT_STATE_KEYS.forEach((key) => {
                unsubscribe(socket, topicStateMap[key], handlers[key]);
            });
        };
    }, [socket]);

    return (
        <RobotStateContext.Provider
            value={{
                ...robotStates,
                setRobotState,
                isEstopped,
                robotControllable,
                robotMovable,
            }}
        >
            {children}
        </RobotStateContext.Provider>
    );
}

export default RobotStateProvider;
