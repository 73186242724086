export const waitStates = (s, name, goal = [], timeoutMs = 10 * 1000) => {
    return new Promise((resolve, reject) => {
        const timeoutId = setTimeout(() => {
            reject("TIMEOUT");
            s.off(name, waiter);
        }, timeoutMs);
        const waiter = ({ data }) => {
            if (
                (typeof goal === "function" && goal(data)) ||
                (Array.isArray(goal) && goal.indexOf(data) !== -1) ||
                (typeof goal === "string" && data === goal)
            ) {
                s.off(name, waiter);
                resolve(data);
                clearTimeout(timeoutId);
            }
        };
        s.on(name, waiter);
    });
};

// ROS Topics
export const TOPIC_STATE_ROBOT_CONNECTION = "/connected";
export const TOPIC_STATE_MODE = "/state/mode";
export const TOPIC_STATE_HEIGHT = "/state/height";
export const TOPIC_STATE_MAX_SPEED = "/state/max_speed";
export const TOPIC_STATE_MOTOR = "/state/motor";
export const TOPIC_STATE_ESTOP = "/state/estop";
export const TOPIC_STATE_SELFRIGHT = "/state/selfright";
export const TOPIC_STATE_TURNOVER = "/state/turnover";
export const TOPIC_STATE_MOVEMENT = "/state/movement";
export const TOPIC_STATE_RESET = "/state/reset";

export const TOPIC_STATE_LIGHT = "/state/light";
export const TOPIC_STATE_BRIGHTNESS = "/state/brightness";

export const TOPIC_STATE_BATTERY_CHARGE = "/state/battery/charge";
export const TOPIC_STATE_BATTERY_STATUS = "/state/battery/status";
export const TOPIC_STATE_BATTERY_TEMP = "/state/battery/temperature";
export const TOPIC_STATE_BATTERY_RAW = "/state/battery/raw";
export const TOPIC_STATE_BATTERY_RUNTIME = "/state/battery/est_runtime";
export const TOPIC_STATE_PROXIMITY = "/state/proximity";

export const TOPIC_STATE_CLAIM_MOTOR = "/state/claim/motor";
export const TOPIC_STATE_CLAIM_ESTOP = "/state/claim/estop";

export const TOPIC_STATE_MISSION_RECORD = "/state/mission/record";
export const TOPIC_STATE_MISSION_RUN = "/state/mission/run";

export const TOPIC_STATE_NOTIFICATIONS = "/state/notifications";

export const TOPIC_STATE_ARM_VELOCITY = "/arm/state/arm_velocity";
export const TOPIC_STATE_WRIST_VELOCITY = "/arm/state/wrist_velocity";
export const TOPIC_STATE_VELOCITY_CMD_DURATION = "/arm/state/velocity_cmd_duration";
export const TOPIC_STATE_GRIPPER_TORQUE = "/arm/state/gripper_torque";
export const TOPIC_STATE_FOLLOW_ARM = "/arm/state/follow_arm";
export const TOPIC_STATE_DISABLE_WALK = "/arm/state/follow_arm_disable_walk";
export const TOPIC_STATE_ARM_MODE = "/arm/state/mode";
export const TOPIC_STATE_ARM_JOINT_POS = "/arm/state/joint_positions";
export const TOPIC_STATE_ARM_END_POS = "/arm/state/end_effector_state";

/* Connection states */
export const STATE_ROBOT_CONNECTING = "ROBOT_CONNECTING";
export const STATE_ROBOT_CONNECTED = "ROBOT_CONNECTED";
export const STATE_ROBOT_DISCONNECTED = "ROBOT_DISCONNECTED";
export const STATE_JETPACK_CONNECTING = "JETPACK_CONNECTING";
export const STATE_JETPACK_CONNECTED = "JETPACK_CONNECTED";
export const STATE_JETPACK_DISCONNECTED = "JETPACK_DISCONNECTED";

/* Battery states */
export const STATE_BATTERY_CHARGING = "BATTERY_CHARGING";
export const STATE_BATTERY_DISCHARGING = "BATTERY_DISCHARGING";
export const STATE_BATTERY_BOOTING = "BATTERY_BOOTING";
export const STATE_BATTERY_UNKNOWN = "BATTERY_UNKNOWN";
export const STATE_BATTERY_MISSING = "BATTERY_MISSING";

/* Stream states */
export const STATE_STREAM_OKAY = "STREAM_OKAY";
export const STATE_STREAM_SLOW = "STREAM_SLOW";
export const STATE_STREAM_NONE = "STREAM_NONE";

/* WiFi states */
export const STATE_WIFI_CONNECTING = "WIFI_CONNECTING";
export const STATE_WIFI_CONNECTED = "WIFI_CONNECTED";
export const STATE_WIFI_MODIFIED = "WIFI_MODIFIED";
export const STATE_WIFI_DISCONNECTED = "WIFI_DISCONNECTED";
export const STATE_WIFI_FAILED = "WIFI_FAILED";
export const STATE_WIFI_SSID_NOT_FOUND = "WIFI_SSID_NOT_FOUND";
export const STATE_WIFI_PW_INCORRECT = "WIFI_PW_INCORRECT";

/* Motor states */
export const STATE_MOTOR_POWERED_OFF = "POWERED_OFF";
export const STATE_MOTOR_POWERED_ON = "POWERED_ON";
export const STATE_MOTOR_POWERING_ON = "POWERING_ON";
export const STATE_MOTOR_POWERING_OFF = "POWERING_OFF";
export const STATE_MOTOR_ERROR = "ERROR";
export const STATE_MOTOR_UNKNOWN = "MOTOR_UNKNOWN";
export const STATE_ERROR = "ERROR";

/* Claim states */
export const STATE_CLAIM_CLAIMED = "CLAIMED";
export const STATE_CLAIM_NO_CLAIM = "NO_CLAIM";
export const STATE_CLAIM_NOT_YOURS = "NOT_YOURS";
export const STATE_CLAIM_UNKNOWN = "CLAIM_UNKNOWN";

/* Record event */
export const STATE_RECORD_STARTING = "RECORD_STARTING";
export const STATE_RECORD_STARTED = "RECORD_STARTED";
export const STATE_RECORD_STOPPING = "RECORD_STOPPING";
export const STATE_RECORD_STOPPED = "RECORD_STOPPED";
export const STATE_RECORD_FAILED = "RECORD_FAILED";

export const STATE_MISSION_GENERATING = "MISSION_GENERATING";
export const STATE_MISSION_GENERATING_FAILED = "MISSION_GENERATING_FAILED";
export const STATE_MISSION_GENERATED = "MISSION_GENERATED";

export const STATES_RECORD_END_STATES = [
    STATE_RECORD_FAILED,
    STATE_MISSION_GENERATED,
    STATE_MISSION_GENERATING_FAILED,
];

/* Replay event */
export const STATE_MISSION_STARTING = "MISSION_STARTING";
export const STATE_MISSION_STARTED = "MISSION_STARTED";
export const STATE_MISSION_PAUSING = "MISSION_PAUSING";
export const STATE_MISSION_PAUSED = "MISSION_PAUSED";
export const STATE_MISSION_STOPPING = "MISSION_STOPPING";
export const STATE_MISSION_STOPPED = "MISSION_STOPPED";
export const STATE_MISSION_UPDATE = "MISSION_UPDATE";
export const STATE_MISSION_LOCATION = "MISSION_LOCATION";
export const STATE_MISSION_FAILED = "MISSION_FAILED";

export const STATES_MISSION_END_STATES = [STATE_MISSION_FAILED, STATE_MISSION_STOPPED];

/* Login states */
export const STATE_LOGGED_IN = "LOGGED_IN";
export const STATE_LOGGING_IN = "LOGGING_IN";
export const STATE_LOGGED_OUT = "LOGGED_OUT";
export const STATE_LOGGING_OUT = "LOGGING_OUT";
export const STATE_LOGIN_FAILED = "LOGIN_FAILED";

/* Arm states */
export const STATE_ARM_STOWED = "STOWED";
export const STATE_ARM_UNSTOWED = "UNSTOWED";
export const STATE_ARM_CARRY = "CARRY";

export const RecordEvents = {
    STATE_RECORD_STARTING,
    STATE_RECORD_STARTED,
    STATE_RECORD_STOPPING,
    STATE_RECORD_STOPPED,
    STATE_RECORD_FAILED,

    STATE_MISSION_GENERATING,
    STATE_MISSION_GENERATING_FAILED,
    STATE_MISSION_GENERATED,
};

export const ReplayEvents = {
    STATE_MISSION_STARTING,
    STATE_MISSION_STARTED,
    STATE_MISSION_PAUSING,
    STATE_MISSION_PAUSED,
    STATE_MISSION_STOPPING,
    STATE_MISSION_STOPPED,
    STATE_MISSION_UPDATE,
    STATE_MISSION_LOCATION,
    STATE_MISSION_FAILED,
};

/* Errors */
export const ERROR_NO_MISSION = "NO_MISSION";
export const ERROR_NO_EXTERNAL_ESTOP = "NO_EXTERNAL_ESTOP";
export const ERROR_NO_CONTROL = "NO_CONTROL";
export const ERROR_CANT_TAKE_CONTROL = "COULD_NOT_TAKE_CONTROL";
export const ERROR_ROBOT_UNAVAILABLE = "ROBOT_UNAVAILABLE";
export const ERROR_ROBOT_POWERED_OFF = "ROBOT_POWERED_OFF";
export const ERROR_ROBOT_FAULTED = "ROBOT_FAULTED";
export const ERROR_RECORD_CORRUPTED = "RECORD_CORRUPTED";
export const ERROR_UNKNOWN_MAP_INFO = "UNKNOWN_MAP_INFO";
export const ERROR_GRAPHNAV_SERVICE_FAILED = "GRAPHNAV_SERVICE_FAILED";
export const ERROR_TIMEOUT = "TIMEOUT";
export const ERROR_SERVICE_UNAVAILABLE = "SERVICE_UNAVAILABLE";
export const ERROR_INVALID_REQUEST = "INVALID_REQUEST";
export const ERROR_LOCALIZED_FAILED = "LOCALIZED_FAILED";

export const ERROR_NO_VISIBLE_FIDUCIAL = "NO_VISIBLE_FIDUCIAL";
export const ERROR_NO_MATCHING_FIDUCIAL = "NO_MATCHING_FIDUCIAL";
export const ERROR_CANT_CREATE_WAYPOINT = "COULD_NOT_CREATE_WAYPOINT";
export const ERROR_DISK_FULL = "DISK_FULL";
export const ERROR_FILESYSTEM_ERROR = "FILESYSTEM_ERROR";
export const ERROR_UNKNOWN = "ERROR_UNKNOWN";

export const MissionErrors = {
    ERROR_NO_MISSION,
    ERROR_NO_EXTERNAL_ESTOP,
    ERROR_NO_CONTROL,
    ERROR_ROBOT_UNAVAILABLE,
    ERROR_ROBOT_POWERED_OFF,
    ERROR_ROBOT_FAULTED,
    ERROR_UNKNOWN_MAP_INFO,
    ERROR_GRAPHNAV_SERVICE_FAILED,
    ERROR_TIMEOUT,
    ERROR_SERVICE_UNAVAILABLE,
    ERROR_INVALID_REQUEST,
};

export const errToMsg = (err) => {
    switch (err) {
        case ERROR_NO_MATCHING_FIDUCIAL:
        case ERROR_NO_VISIBLE_FIDUCIAL:
            return "No AR code! Please align robot to an AR code before starting.";
        case ERROR_CANT_CREATE_WAYPOINT:
            return "Can't create waypoint! Please stop the current mission and try again!";
        case ERROR_DISK_FULL:
            return "Storage is full. Please contact the support team for an exchange or upgrade!";
        case ERROR_FILESYSTEM_ERROR:
            return "Internal filesystem error. Please contact the support team for help!";
        case ERROR_NO_MISSION:
            return "The given mission is not found on the server.";
        case ERROR_NO_EXTERNAL_ESTOP:
            return "The emergency stop is not set up or has been switched off. Please restart the robot.";
        case ERROR_ROBOT_POWERED_OFF:
            return "The robot is powered off. Please make sure it is on before any action.";
        case ERROR_ROBOT_FAULTED:
            return "Faulty! Please restart the robot. If the issue persists, contact the support team for help.";
        case ERROR_RECORD_CORRUPTED:
        case ERROR_UNKNOWN_MAP_INFO:
            return "Mission is unrecognizable or corrupted!";
        case ERROR_GRAPHNAV_SERVICE_FAILED:
            return "Faulty SPOT graphnav service. Please restart the robot!";
        case ERROR_TIMEOUT:
            return "Communication timeout! Please check the internet status and try again.";
        case ERROR_SERVICE_UNAVAILABLE:
            return "Mission service unavailable. Please restart the robot and try again.";
        case ERROR_INVALID_REQUEST:
            return "Invalid request! Please contact the support team for help.";
        default:
            return "Unknown error. Please consult support.";
    }
};

export const deviceStateToMsg = (state) => {
    switch (state) {
        case STATE_JETPACK_CONNECTING:
            return "connecting to jetpack";
        case STATE_JETPACK_CONNECTED:
            return "connected to jetpack";
        case STATE_JETPACK_DISCONNECTED:
            return "jetpack not connected";

        case STATE_ROBOT_CONNECTING:
            return "connecting to robot";
        case STATE_ROBOT_CONNECTED:
            return "connected to robot";
        case STATE_ROBOT_DISCONNECTED:
            return "robot not connected";

        case STATE_BATTERY_CHARGING:
            return "battery charging";
        case STATE_BATTERY_DISCHARGING:
            return "battery discharging";
        case STATE_BATTERY_BOOTING:
            return "battery booting";
        case STATE_BATTERY_UNKNOWN:
            return "battery state unknown";
        case STATE_BATTERY_MISSING:
            return "battery missing";

        case STATE_MOTOR_POWERED_OFF:
            return "motor powered off";
        case STATE_MOTOR_POWERED_ON:
            return "motor powered on";
        case STATE_MOTOR_POWERING_ON:
            return "motor powering on";
        case STATE_MOTOR_POWERING_OFF:
            return "motor powering off";
        case STATE_MOTOR_ERROR:
            return "motor error";
        case STATE_MOTOR_UNKNOWN:
            return "motor state unknown";

        case STATE_CLAIM_CLAIMED:
            return "connected";
        case STATE_CLAIM_NO_CLAIM:
            return "hijacked";
        case STATE_CLAIM_NOT_YOURS:
            return "hijacked";
        case STATE_CLAIM_UNKNOWN:
            return "disconnected";
        default:
            return "unknown";
    }
};

export const streamStateToMsg = (state) => {
    switch (state) {
        case STATE_STREAM_OKAY:
            return "Streaming";
        case STATE_STREAM_SLOW:
            return "Low FPS";
        case STATE_STREAM_NONE:
            return "No incoming data";
        default:
            return "Unknown error";
    }
};

export const stateToMsg = (state, err = null) => {
    switch (state) {
        case STATE_RECORD_STARTING:
            return "Starting recording...";
        case STATE_RECORD_STARTED:
            return "Now recording";
        case STATE_RECORD_STOPPING:
            return "Stopping recording...";
        case STATE_RECORD_STOPPED:
            return "Record Stopped";
        case STATE_MISSION_GENERATING:
            return "Saving record...";
        case STATE_MISSION_GENERATING_FAILED:
            return "Failed to save record.";
        case STATE_MISSION_GENERATED:
            return "Saved record.";
        case STATE_RECORD_FAILED:
            return `Record failed. Reason: ${errToMsg(err)}`;
        default:
            return "Idle.";
    }
};
