import React from "react";
import styles from "./css/Logo.module.css";

const Logo = ({ className = "", clientName, clientLogo, width = 75, height = 75 }) => {
    const noClient = clientName === "r2c2";
    return (
        <div className={`${className || ""} ${styles.logo}`}>
            {!noClient && <img src={clientLogo} alt={clientName} height={height} width={width} />}
            {!noClient && <div className="logo-separator" aria-label="logo seperator" />}
            <img src="/r2c2-big-logo.webp" alt="R2C2 Logo" height={height} width={width} />
        </div>
    );
};

export default Logo;
