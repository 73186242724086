import React from "react";
import Logo from "./Logo";
import DeviceStatus from "./DeviceStatus";
import PowerSwitch from "./PowerSwitch";
import EStop from "./EStop";

import styles from "./css/Header.module.css";

const jetpackName = process.env.REACT_APP_JETPACK_SN;
const robotName = "Explorer";

function Header() {
    const user = process.env.REACT_APP_USER;

    return (
        <header className={styles.container}>
            <Logo clientName={user} clientLogo={`/${process.env.REACT_APP_LOGO}`} />

            <div className={styles["status-container"]}>
                <DeviceStatus device="Jetpack" name={jetpackName} />
                <DeviceStatus device="SPOT" name={robotName} />
            </div>

            <PowerSwitch />

            <EStop />
        </header>
    );
}

export default React.memo(Header);
