import { createContext, useContext, useEffect, useState } from "react";

import {
    TOPIC_STATE_ARM_VELOCITY,
    TOPIC_STATE_WRIST_VELOCITY,
    TOPIC_STATE_VELOCITY_CMD_DURATION,
    TOPIC_STATE_GRIPPER_TORQUE,
    TOPIC_STATE_FOLLOW_ARM,
    TOPIC_STATE_DISABLE_WALK,
    TOPIC_STATE_ARM_MODE,
    TOPIC_STATE_ARM_JOINT_POS,
    TOPIC_STATE_ARM_END_POS as TOPIC_STATE_ARM_END_EFFECTOR_STATE,
} from "../lib/state";
import { ARM_STOW } from "../lib/protocols/control";
import { subscribe, unsubscribe } from "../lib/socket";
import { useSocket } from "./ControlSocketProvider";

export const ArmStateContext = createContext();
ArmStateContext.displayName = "ArmState";
export const ArmStateConsumer = ArmStateContext.Consumer;
export const useArmState = () => useContext(ArmStateContext);

const topicStateMap = {
    armMode: TOPIC_STATE_ARM_MODE,
    armJointPos: TOPIC_STATE_ARM_JOINT_POS,
    armEndEffectorState: TOPIC_STATE_ARM_END_EFFECTOR_STATE,
};

const DEFAULT_ARM_STATE = {
    armMode: ARM_STOW,
    armJointPos: {},
    armEndEffectorState: {},
};

const ARM_STATE_KEYS = Object.keys(DEFAULT_ARM_STATE);

const computeExtraStates = (armStates) => {
    const gripperFraction = armStates.armEndEffectorState?.open_fraction || 0;
    // console.log("gripper Fraction", gripperFraction);
    const isGripperOpen = gripperFraction >= 0.1;
    // console.log("is open? ", isGripperOpen);
    return { ...armStates, isGripperOpen };
};

function ArmStateProvider({ children }) {
    const { controlSocket: socket } = useSocket();
    const [armStates, setArmStates] = useState(DEFAULT_ARM_STATE);

    const setArmState = (key, state) => {
        setArmStates((prev) => {
            return computeExtraStates({
                ...prev,
                [key]: state,
            });
        });
    };

    useEffect(() => {
        const handlers = {};

        ARM_STATE_KEYS.forEach((key) => {
            const handler = (data) => setArmState(key, data);
            handlers[key] = handler;
            subscribe(socket, topicStateMap[key], handler);
        });

        return () => {
            if (!socket) return;
            ARM_STATE_KEYS.forEach((key) => {
                unsubscribe(socket, topicStateMap[key], handlers[key]);
            });
        };
    }, [socket]);

    return <ArmStateContext.Provider value={armStates}>{children}</ArmStateContext.Provider>;
}

export default ArmStateProvider;
