import React from "react";
import { ReactComponent as IconStreamStatus } from "../svg/IconVideoStatus.svg";
import {
    STATE_STREAM_OKAY,
    STATE_STREAM_SLOW,
    STATE_STREAM_NONE,
    streamStateToMsg,
} from "../lib/state";
import { CameraContext } from "./CameraProvider";
import styles from "./css/Streamer.module.css";

function StreamStatus({ camConnected = false, streamStatus, isShow = true }) {
    const { selectedCam } = React.useContext(CameraContext);
    const videoStatus = () => {
        if (camConnected) {
            switch (streamStatus) {
                case STATE_STREAM_OKAY:
                    return (
                        <span style={{ color: "lime" }}>
                            {selectedCam} - {streamStateToMsg(streamStatus)}
                        </span>
                    );
                case STATE_STREAM_SLOW:
                    return (
                        <span style={{ color: "yellow" }}>
                            {selectedCam} - {streamStateToMsg(streamStatus)}
                        </span>
                    );
                case STATE_STREAM_NONE:
                default:
                    return (
                        <span style={{ color: "yellow" }}>
                            {selectedCam} - {streamStateToMsg(streamStatus)}
                        </span>
                    );
            }
        } else {
            return <span style={{ color: "red" }}>Not connected</span>;
        }
    };
    return (
        <div className={`${styles.box}`}>
            <IconStreamStatus width="19px" height="19px"></IconStreamStatus>
            <span>Stream: {videoStatus()}</span>
        </div>
    );
}

export default React.memo(StreamStatus);
