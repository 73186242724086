import React, { useState, useContext, useEffect } from "react";
import {
    STATE_JETPACK_CONNECTING,
    STATE_JETPACK_CONNECTED,
    STATE_JETPACK_DISCONNECTED,
    STATE_ROBOT_CONNECTING,
    STATE_ROBOT_CONNECTED,
    STATE_ROBOT_DISCONNECTED,
    STATE_CLAIM_CLAIMED,
    STATE_MOTOR_POWERED_ON,
    STATE_MOTOR_POWERING_ON,
    STATE_MOTOR_POWERED_OFF,
    STATE_MOTOR_POWERING_OFF,
    STATE_MOTOR_UNKNOWN,
    deviceStateToMsg,
    STATE_ERROR,
} from "../lib/state";
import { useSocket } from "./ControlSocketProvider";
import useClickOutside from "../lib/hooks/useClickOutside";
import { RobotStateContext } from "./RobotStateProvider";
import styles from "./css/Header.module.css";
import { claimMotor } from "../lib/protocols/control";

import { ReactComponent as IconConnect } from "../svg/IconConnect.svg";
import { ReactComponent as IconDualGear } from "../svg/IconDualGear.svg";
import { ReactComponent as IconGear } from "../svg/IconGear.svg";
import { ReactComponent as IconNoConnection } from "../svg/IconNoConnection.svg";
import { ReactComponent as IconNoSignal } from "../svg/IconNoSignal.svg";
import { ReactComponent as IconTick } from "../svg/IconTick.svg";
import { ReactComponent as IconError } from "../svg/IconError.svg";

import Icon from "./Icon";
import WifiSettings from "./WifiSettings";
import Tooltip from "./Tooltip";

const stateSVGMap = {
    [STATE_JETPACK_CONNECTING]: { icon: IconConnect, color: "yellow" },
    [STATE_JETPACK_CONNECTED]: { icon: IconConnect, color: "lime" },
    [STATE_JETPACK_DISCONNECTED]: { icon: IconNoConnection, color: "#c4c4c4" },
    [STATE_MOTOR_POWERING_ON]: { icon: IconDualGear, color: "yellow" },
    [STATE_MOTOR_POWERING_OFF]: { icon: IconDualGear, color: "yellow" },
    [STATE_MOTOR_POWERED_ON]: { icon: IconDualGear, color: "lime" },
    [STATE_MOTOR_POWERED_OFF]: { icon: IconDualGear, color: "#c4c4c4" },
    [STATE_ERROR]: { icon: IconError, color: "#ff0000" },
    [STATE_MOTOR_UNKNOWN]: { icon: IconDualGear, color: "#c4c4c4" },
    [STATE_ROBOT_CONNECTED]: { icon: IconTick, color: "lime" },
    [STATE_ROBOT_CONNECTING]: { icon: IconNoSignal, color: "#666680" },
    [STATE_ROBOT_DISCONNECTED]: { icon: IconNoSignal, color: "#666680" },
};

// see corresponding classes in Header.module.css
const stateMotorIconClassMap = {
    [STATE_MOTOR_POWERING_OFF]: "motor-transitioning",
    [STATE_MOTOR_POWERING_ON]: "motor-transitioning",
    [STATE_MOTOR_POWERED_ON]: "motor-on",
    [STATE_MOTOR_POWERED_OFF]: "motor-off",
};

function DeviceStatus({ device, name }) {
    const { socketState, controlSocket: socket, sendControl } = useSocket();
    const { motorState, robotConnectionState, controlState, batteryPercent } =
        useContext(RobotStateContext);
    const [showSetting, setShowSetting] = useState(false);
    const [showButton, setShowButton] = useState(false);
    const iconProp = (state) => {
        return {
            icon: stateSVGMap[state].icon,
            type: "svg",
            alt: deviceStateToMsg(state),
            title: deviceStateToMsg(state),
            className: `${styles.icon} ${styles["status-icon"]} ${
                styles[stateMotorIconClassMap[state]] ?? ""
            }`,
        };
    };
    const [motorIconProps, setMotorIconProps] = useState(iconProp(STATE_MOTOR_POWERED_OFF));
    const clickOutsideRef = useClickOutside(() => setShowButton(false));

    // blinking the icon while powering on/off
    useEffect(() => {
        const props = iconProp(motorState);
        setMotorIconProps(props);
    }, [motorState]);

    return (
        <>
            <div className={styles["status-box"]}>
                <div className={styles["status-bar"]} ref={clickOutsideRef}>
                    <Icon {...iconProp(socketState)} />
                    <Icon {...motorIconProps} />
                    <p
                        className={styles["status-title"]}
                        onClick={() => setShowButton((prev) => !prev)}
                    >{`${device} - ${name}`}</p>
                    <Tooltip content={`${batteryPercent}%`}>
                        <div className={styles.battery}>
                            <div
                                className={styles["battery-percentage"]}
                                style={{
                                    width: `${batteryPercent}%`,
                                }}
                            ></div>
                        </div>
                    </Tooltip>
                    <Icon {...iconProp(robotConnectionState)} />

                    {/* this is a hack of putting absolute setting inside status-bar but is relative to status-box */}
                    {showButton && (
                        <div className={styles.setting}>
                            {device === "SPOT" && controlState !== STATE_CLAIM_CLAIMED && (
                                <button
                                    className={`${styles["button"]} ${styles["claim-button"]}`}
                                    onClick={() => sendControl(claimMotor())}
                                >
                                    Claim
                                </button>
                            )}

                            <button
                                className={styles.button}
                                onClick={() => {
                                    setShowSetting(true);
                                    setShowButton(false);
                                }}
                            >
                                <IconGear
                                    width="20px"
                                    height="20px"
                                    style={{ color: "hsla(239, 69%, 78%, 1)" }}
                                ></IconGear>
                                Connection Settings
                            </button>
                        </div>
                    )}
                </div>
            </div>
            {showSetting && <WifiSettings device={device} onClose={() => setShowSetting(false)} />}
        </>
    );
}

export default DeviceStatus;
