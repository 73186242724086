import React, { useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
    STATE_LOGGED_IN,
    STATE_LOGGING_IN,
    STATE_LOGGED_OUT,
    STATE_LOGIN_FAILED,
} from "../lib/state";
import Logo from "./Logo";
import styles from "./css/LoginPanel.module.css";
import { en } from "../lib/locale";
import Icon from "./Icon";
import { ReactComponent as LoadingSpinner } from "../svg/LoadingSpinner.svg";
import { ReactComponent as IconFB } from "../svg/IconFB.svg";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "./AuthProvider";

function LoginPanel() {
    const { logIn } = useAuth();
    const history = useHistory();
    const location = useLocation();
    const [loginStatus, setLoginStatus] = useState(STATE_LOGGED_OUT);
    const [loginErr, setLoginErr] = useState(null);

    const user = useRef();
    const password = useRef();

    const translation = en.login;

    const handleSubmit = () => {
        const username = user.current.value ?? "";
        const pw = password.current.value ?? "";

        // setLoginStatus(STATE_LOGGING_IN);

        logIn(username, pw)
            .then(() => {
                setLoginStatus(STATE_LOGGED_IN);
                history.replace(location.state?.from || "/main");
            })
            .catch((err) => {
                console.error(err);
                setLoginErr(err);
                setLoginStatus(STATE_LOGIN_FAILED);
            });
    };

    return (
        <div className={styles.container}>
            <Logo
                width="250px"
                height="auto"
                clientName={process.env.REACT_APP_USER}
                clientLogo={process.env.REACT_APP_LOGO}
            ></Logo>

            {/* This is rendered to setup user ID and password for authentication. */}
            {(loginStatus === STATE_LOGGED_OUT || loginStatus === STATE_LOGIN_FAILED) && (
                <form
                    className={styles.form}
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                    }}
                >
                    <div className={styles.input}>
                        <label htmlFor="user-id">{translation.id}</label>
                        <input
                            type="text"
                            id="user-id"
                            name="user-id"
                            ref={user}
                            autoFocus={true}
                        />
                    </div>

                    <div className={styles.input}>
                        <label htmlFor="password">{translation.password}</label>
                        <input type="password" id="password" name="password" ref={password} />
                    </div>

                    {loginErr && <p className={styles.error}>{loginErr}</p>}

                    <button type="submit" className={styles.submit}>
                        {translation.signIn}
                    </button>
                    <p className={styles.desc}>{translation.description}</p>
                </form>
            )}

            {/* This is rendered when connecting to Jetpack. */}
            {loginStatus === STATE_LOGGING_IN && (
                <div className={styles.box}>
                    <div className={styles.message}>
                        <p>{translation.connectingInstructionA}</p>
                        <p>{translation.connectingInstructionB}</p>
                    </div>

                    <img
                        className={styles.box}
                        src="/connecting-jetpack.png"
                        alt="Connecting to JetPack"
                    />
                    <p className={styles["connecting-desc"]}>{translation.connecting}</p>

                    <div className={styles.status}>
                        <Icon
                            icon={LoadingSpinner}
                            type="svg"
                            width="1.5rem"
                            height="1.5rem"
                            className={styles.spinner}
                        ></Icon>
                        <p>{translation.jetpackConnecting}</p>
                    </div>
                    <div className={styles.status}>
                        <Icon icon={faExclamationCircle} type="fa" size="lg"></Icon>
                        <p>{translation.robotConnecting}</p>
                    </div>
                </div>
            )}

            <a
                className={styles["follow-us"]}
                href="https://www.facebook.com/r2c2robotics"
                target="_blank"
                rel="noreferrer"
            >
                <p>Follow Us</p>
                <Icon icon={IconFB} type="svg" width="2rem" height="2rem" alt="facebook"></Icon>
            </a>
        </div>
    );
}

export default LoginPanel;
