import * as THREE from "three";
import MyUtils, { POINT_TYPE_END, POINT_TYPE_POINT, POINT_TYPE_START } from "./three/MyUtils";
import throttle from "lodash/throttle";

// const throttledLog = throttle((...args) => console.log(...args), 1000);

const SCALE_FACTOR = 4;

const hotFix_levelWaypoints = (waypointsArray) => {
    // we don't have reliable data at the z axis. changing them all to 0 first.
    // pending fixes from backend
    return waypointsArray.map((wp) => ({
        ...wp,
        pos: [wp.pos[0], wp.pos[1], 0],
    }));
};

export default class GraphnavJsonRenderer {
    constructor(domRef, onClickWaypoint = null) {
        this.container = typeof domRef === "string" ? document.querySelector(domRef) : domRef;
        this.graph = null;
        this.sortedWaypoints = null;
        this.lines = [];
        this.head = null;
        this.tail = null;
        this.robotLocation = new THREE.Vector3();
        this.prevRobotLocation = null;
        this.robot = null;
        this.timeouts = {};
        this.currWaypointIndex = null;
        this.prevWaypointIndex = null;
        this.onClickWaypoint = onClickWaypoint;

        this.renderer = null;
        this.scene = null;
        this.camera = null;
        this.raycaster = null;
        this.mouse = null;
        this.controls = null;
        this.animateFrame = false;

        this.onWindowResize = null;
        this.onMouseMove = null;
        this.onClick = null;
    }

    getContainerDimensions() {
        if (!this.container) return { width: 0, height: 0 };
        return { width: this.container.clientWidth, height: this.container.clientHeight };
    }

    /**
     * Retrieve the waypoint at index
     * @param {number} index the index of the array (negative index is supported)
     * @returns waypoint
     */
    getWaypointAt(index) {
        const arr = this.sortedWaypoints;
        if (arr.length <= 0 || index >= arr.length || -index > arr.length) {
            console.error(`getWaypointAt(${index}): Index out of range`);
            return null;
        }

        if (index < 0) index += arr.length;
        return arr[index];
    }

    /**
     * @typedef {{pos: Array<number>, timestamp: number, next?: string, prev?: string}} Waypoint
     * @param {Object.<string, {pos: Array<number>, timestamp: number, next?: string, prev?: string}>} waypoints
     *
     * Recreate a linked list of waypoints as most of them only have next but not prev
     */
    linkPoints(waypoints) {
        return Object.entries(waypoints).reduce(
            (acc, [id, { prev, next }]) => {
                if (prev) acc[prev].next = id;
                if (next) acc[next].prev = id;
                return acc;
            },
            { ...waypoints }
        );
    }

    scaleWaypoints() {
        if (!this.visualWaypoints) return;
        // this does 2 things:
        // 1. shows / hides a number of points based on zoom level.
        // 2. keeps the point's size constant across zooms
        this.visualWaypoints.forEach((p, i) => {
            // calculate distance between point and camera
            this.waypointScaleVector.subVectors(p.position, this.camera.position);
            // scale by distance
            const zDist = Math.abs(this.waypointScaleVector.z);
            const scale =
                // start hiding only after point's z distance from camera is larger than 20
                zDist > 20 &&
                // hide every floor(zDistance / 10) point
                i % Math.floor(zDist / 10) !== 0 &&
                // only hide points that are of type "point" i.e. "start" and "end" points are always shown
                p.userData.type === POINT_TYPE_POINT
                    ? 0
                    : // otherwise
                      this.waypointScaleVector.length() / SCALE_FACTOR;
            p.scale.set(scale, scale, 1);
        });
    }

    /**
     * @summary make waypoint sprite
     * @param {Array<{id:string, pos: Array<number>, timestamp: number, next?: string, prev?: string}>} waypoints
     * @param {{id:string, pos: Array<number>, timestamp: number, next?: string, prev?: string}} head
     * @returns
     */
    createWaypoint(waypoints, head, tail) {
        return waypoints.slice().map(({ id, pos: [x, y] }) => {
            const isHead = (head && id === head.id) ?? false;
            const isTail = (tail && id === tail.id) ?? false;
            const type = isHead ? POINT_TYPE_START : isTail ? POINT_TYPE_END : POINT_TYPE_POINT;
            const point = new THREE.Sprite(MyUtils.getWaypointMaterial(type));
            point.position.set(x, y, 0);
            point.userData = { id, type };
            point.name = "waypoint";
            return point;
        });
    }

    createLine(p1, p2, type) {
        const geo = new MyUtils.LineGeometry();
        const z = type === "green" ? 0.1 : 0;
        geo.setPositions([p1.x, p1.y, z, p2.x, p2.y, z]);
        const line = new MyUtils.Line2(geo, MyUtils.getLineMaterial(type));
        line.computeLineDistances();
        line.userData = { from: p1, to: p2, type };
        line.name = "line";
        return line;
    }

    createRobot(pos) {
        const loader = new THREE.TextureLoader();
        const texture = loader.load("/SPOT.png");
        const material = new THREE.MeshBasicMaterial({
            map: texture,
            side: THREE.FrontSide,
            transparent: true,
            opacity: 0.75,
        });
        const geometry = new THREE.PlaneBufferGeometry(1, 1, 1, 1);
        geometry.center();
        const robot = new THREE.Mesh(geometry, material);
        robot.position.copy(pos);
        robot.name = "robot";

        return robot;
    }

    setUpListeners() {
        this.onWindowResize = throttle(() => {
            const { width, height } = this.getContainerDimensions();

            this.camera.aspect = width / height;
            this.camera.updateProjectionMatrix();

            this.renderer.setSize(width, height);
            this.renderer.render(this.scene, this.camera);
        }, 20);

        this.onMouseMove = throttle((e) => {
            const { width, height } = this.getContainerDimensions();
            const rect = e.target.getBoundingClientRect();
            this.mouse.x = ((e.clientX - rect.x) / width) * 2 - 1;
            this.mouse.y = -((e.clientY - rect.y) / height) * 2 + 1;
        }, 50);

        this.onClick = throttle((e) => {
            this.raycaster.setFromCamera(this.mouse, this.camera);
            const intersects = this.raycaster
                .intersectObjects(this.scene.children, true)
                .filter((i) => i.object.name === "waypoint");
            if (intersects.length === 0) return;
            const id = intersects[0].object.userData.id;
            // console.log(intersects[0].object.position);

            if (typeof this.onClickWaypoint === "function") this.onClickWaypoint(id);
        }, 50);

        window.addEventListener("resize", this.onWindowResize.bind(this));
        this.renderer.domElement.addEventListener("mousemove", this.onMouseMove.bind(this), false);
        this.renderer.domElement.addEventListener("click", this.onClick.bind(this), false);
    }

    setupFloor(width = 10, height = 10) {
        // const axes = new THREE.AxesHelper(2);
        // this.scene.add(axes);

        const sizeSmBlock = 5; //m
        const sizeLgBlock = 10; //m
        const size = Math.max(
            MyUtils.ceilToMultiple(width, sizeSmBlock),
            MyUtils.ceilToMultiple(height, sizeSmBlock)
        );

        const floorTexture = MyUtils.getFloorTexture();
        floorTexture.wrapS = floorTexture.wrapT = THREE.RepeatWrapping;
        const floorMaterial = new THREE.MeshBasicMaterial({
            map: floorTexture,
            side: THREE.FrontSide,
        });
        const floorGeometry = new THREE.PlaneBufferGeometry(size * 100, size * 100, 1, 1);
        const floor = new THREE.Mesh(floorGeometry, floorMaterial);
        floor.position.set(this.head.pos[0], this.head.pos[1], -0.15);
        this.scene.add(floor);

        // const grid = new THREE.GridHelper(size, size, 0x888888, 0xcccccc);
        // grid.geometry.rotateX(Math.PI / 2);
        // grid.lookAt(new THREE.Vector3(0, 0, 1));

        const grid = new MyUtils.InfiniteGridHelper(
            sizeSmBlock, // smaller block 5 m
            sizeLgBlock, // large block 10 m
            new THREE.Color(MyUtils.COLOR.darkGray),
            size * 2,
            "xyz"
        );
        grid.position.set(this.head.pos[0], this.head.pos[1], -0.1);
        this.scene.add(grid);
    }

    setupCamera(initPos) {
        // set camera focus on the head
        this.camera.position.set(initPos.x, initPos.y, 5);
        this.controls.target.set(initPos.x, initPos.y, 0);

        // limit the camera position
        this.controls.minPan.set(-100, -100, 0);
        this.controls.maxPan.set(100, 100, 100);
        this.controls.minDistance = 1;
        this.controls.maxDistance = 1000;
        this.controls.update();
        this.controls.saveState(); // save the current camera position
    }

    resetCamera() {
        if (!this.graph) return;
        this.controls.reset();
    }

    init() {
        if (this.scene) return;
        THREE.Object3D.DefaultUp.set(0, 0, 1);

        const width = this.container.clientWidth;
        const height = this.container.clientHeight;
        const aspect = width / height;

        this.scene = new THREE.Scene();

        this.renderer = new THREE.WebGLRenderer({ antialias: true });
        this.renderer.setSize(width, height);
        this.renderer.setPixelRatio(window.devicePixelRatio);
        // FOV, aspect, near clipping distance, far clipping distance
        this.camera = new THREE.PerspectiveCamera(75, aspect, 0.1, 10000);
        this.mouse = new THREE.Vector2();
        this.controls = new MyUtils.OrbitControls(this.camera, this.renderer.domElement);
        // this.controls.enableRotate = false;
        this.raycaster = new THREE.Raycaster();
        this.raycaster.params.Sprite = { threshold: 0.2 };

        this.setUpListeners();

        this.container.appendChild(this.renderer.domElement); // append the canvas to the container
    }

    loadGraph(graph) {
        this.clearGraph();
        this.init();
        this.graph = graph;

        const waypoints = this.linkPoints(graph.waypoints);
        this.sortedWaypoints = MyUtils.sortWaypoints(waypoints);
        this.sortedWaypoints = hotFix_levelWaypoints(this.sortedWaypoints);

        this.head = this.getWaypointAt(0);
        this.tail = this.getWaypointAt(-1);
        const [headX, headY] = this.head.pos;
        const robot = this.createRobot(new THREE.Vector3(headX, headY, 0.1));
        this.robot = robot;
        this.robotScaleVector = new THREE.Vector3();
        this.updateRobotLocation(this.head.id, [0, 0]);
        this.scene.add(robot);

        // console.log(
        //     this.sortedWaypoints.reduce(
        //         (acc, curr) => [...acc, { waypoint: curr.id, waypoint_tform_body: [0, 0] }],
        //         []
        //     )
        // );

        const visualWaypoints = this.createWaypoint(this.sortedWaypoints, this.head, this.tail);
        let farthestX = 0;
        let farthestY = 0;
        visualWaypoints.forEach((p, i, arr) => {
            this.scene.add(p);

            // find the longest width and height for grid floor
            const diffX = Math.abs(p.position.x - headX);
            const diffY = Math.abs(p.position.y - headY);
            if (diffX > farthestX) farthestX = diffX;
            if (diffY > farthestY) farthestY = diffY;

            // you can't make a line with one point
            if (i === 0) return;

            // rotate the robot so that it points to the next point
            if (i === 1) MyUtils.rotateToPoint(robot, p.position);

            // create a line between two waypoints
            const prev = arr[i - 1].position;
            const curr = p.position;
            this.addLine(this.createLine(prev, curr, "purple"));
        });
        this.visualWaypoints = visualWaypoints;
        this.waypointScaleVector = new THREE.Vector3();
        this.setupFloor(farthestX * 2 + 4, farthestY * 2 + 4);

        this.setupCamera(new THREE.Vector2(headX, headY));
    }

    clearGraph() {
        this.graph = null;
        if (!this.scene) return;

        // this.scene.children.forEach((c) => this.scene.remove(c));
        this.stopRender();
        this.head = null;
        this.robotLocation = null;
        this.robot = null;
        this.lines = [];
        this.sortedWaypoints = null;
        this.currWaypointIndex = null;
        this.prevWaypointIndex = null;
        this.scene.clear();
        this.controls.reset();
    }

    updateRobotLocation(waypointId, tformData) {
        if (!waypointId || !this.robot || !this.robotLocation) return;
        const index = this.sortedWaypoints.findIndex((i) => {
            // console.log("i:,", i.id, waypointId);
            return i.id.trim() === waypointId.trim();
        });
        if (index === -1) return; // not found

        this.prevWaypointIndex = this.currWaypointIndex;
        this.currWaypointIndex = index;
        const waypoint = this.sortedWaypoints[index];
        const [x, y] = waypoint.pos;
        const [tformX = 0, tformY = 0] = tformData;
        this.prevRobotLocation = this.robotLocation;
        this.robotLocation.set(x + tformX, y + tformY, this.robot.position.z);
    }

    getProgress() {
        const wpLen = this.sortedWaypoints ? this.sortedWaypoints.length : 0;
        if (this.currWaypointIndex === null || wpLen <= 0) return 0;
        if (wpLen === 1) return 100; // %
        return Math.floor((this.currWaypointIndex / (wpLen - 1)) * 100);
    }

    // deprecated
    highlightPoint(point) {
        point.material = this.getWaypointMaterial(point.userData.id, this.head);
        point.material.color = this.darken(point.material.color, 0.2);
        const { uuid } = point;
        if (this.timeouts[uuid]) {
            clearTimeout(this.timeouts[uuid]);
            this.timeouts[uuid] = null;
        }
        this.timeouts[uuid] = setTimeout(() => {
            point.material = this.getWaypointMaterial(point.userData.id, this.head);
            this.timeouts[uuid] = null;
        }, 20);
    }

    addLine(line) {
        this.lines.unshift(line);
        this.scene.add(line);
    }

    deleteLine(p1, p2) {
        const _p1 = p1.clone();
        const _p2 = p2.clone();
        _p1.z = _p2.z = 0;
        const index = this.lines.findIndex(({ userData: { from: point1, to: point2 } }) => {
            const pp1 = point1.clone();
            const pp2 = point2.clone();
            pp1.z = pp2.z = 0;
            return (pp1.equals(_p1) && pp2.equals(_p2)) || (pp1.equals(_p2) && pp2.equals(_p1));
        });
        if (index === -1) return;
        this.scene.remove(this.lines[index]);
        this.lines.splice(index, 1); // remove line from array
    }

    updateLine() {
        if (this.prevWaypointIndex === null) return;
        // if arrived new waypoint
        const prevWaypoint = this.getWaypointAt(this.prevWaypointIndex);
        const currWaypoint = this.getWaypointAt(this.currWaypointIndex);
        const nextWaypoint = this.getWaypointAt(this.currWaypointIndex + 1); // can be null
        const currPos = MyUtils.toVec3(currWaypoint.pos);
        currPos.z = 0;
        const prevPos = MyUtils.toVec3(prevWaypoint.pos);
        if (this.currWaypointIndex !== this.prevWaypointIndex) {
            // delete previous line and set to green
            this.deleteLine(prevPos, currPos);
            this.deleteLine(prevPos, this.prevRobotLocation);
            this.deleteLine(this.prevRobotLocation, currPos);
            this.addLine(this.createLine(prevPos, currPos, "green"));
            if (nextWaypoint) {
                const nextPos = MyUtils.toVec3(nextWaypoint.pos);
                this.deleteLine(currPos, nextPos);
                this.addLine(this.createLine(currPos, this.robotLocation, "green"));
                this.addLine(this.createLine(this.robotLocation, nextPos, "purple"));
            }
        } else {
            // same waypoint but differnt location
            this.deleteLine(currPos, this.prevRobotLocation);
            // add green line between this waypoint and robot
            this.addLine(this.createLine(currPos, this.robotLocation, "green"));
            if (nextWaypoint) {
                const nextPos = MyUtils.toVec3(nextWaypoint.pos);
                // delete line between this waypoint and next waypoint
                this.deleteLine(this.prevRobotLocation, nextPos);
                // add purple line between robot and next waypoint
                this.addLine(this.createLine(this.robotLocation, nextPos, "purple"));
            }
        }
    }

    scaleRobot() {
        const scale =
            this.robotScaleVector.subVectors(this.robot.position, this.camera.position).length() /
            SCALE_FACTOR;
        this.robot.scale.set(scale, scale, 1);
    }

    updateRobot() {
        if (!this.robot) return;
        this.scaleRobot();
        if (this.robotLocation.equals(this.robot.position)) return;
        const waypoint = this.getWaypointAt(this.currWaypointIndex + 1); // null if not found
        const nextWaypointPos = waypoint ? MyUtils.toVec3(waypoint.pos) : this.robotLocation;
        MyUtils.rotateToPoint(this.robot, nextWaypointPos);
        this.robot.position.copy(this.robotLocation);
        this.updateLine();
    }

    animate() {
        this.animateFrame = requestAnimationFrame(() => this.animate());

        this.controls.update();
        // animate the robot location
        this.updateRobot();
        this.scaleWaypoints();

        // update the picking ray (projection of mouse on object)
        this.raycaster.setFromCamera(this.mouse, this.camera);

        // const intersects = this.raycaster
        //     .intersectObjects(this.scene.children, true)
        //     .filter((i) => i.object.name === "waypoint")
        //     .forEach(({ object }, i) => {
        //         // this.highlightPoint(object);
        //     });

        this.renderer.render(this.scene, this.camera);
    }

    render() {
        if (!this.graph) {
            throw new Error("graph not provided.");
        }
        this.animate();
    }

    stopRender() {
        this.animateFrame && cancelAnimationFrame(this.animateFrame);
    }
}
