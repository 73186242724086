import React, { useContext } from "react";

import { cameras } from "../lib/protocols/streams";
import { CameraContext } from "./CameraProvider";
import { SubMenu } from "./Menu";

import styles from "./css/Menu.module.css";

function CameraPanel({ onClose }) {
    const { selectedCam, onSelectCam } = useContext(CameraContext);

    return (
        <SubMenu title="Switch Camera" onClose={onClose}>
            <div className={styles["camera-grid"]}>
                {cameras.map((c) => {
                    const selected = selectedCam === c.name;

                    return (
                        <button
                            key={c.name}
                            type="button"
                            onClick={() => onSelectCam(c.name)}
                            className={`${styles.camera} ${selected && styles.selected}`}
                        >
                            {c.name}
                        </button>
                    );
                })}
            </div>
        </SubMenu>
    );
}

export default React.memo(CameraPanel);
