import React, { useState, useEffect, useRef, useContext, useCallback } from "react";
import throttle from "lodash/throttle";
import { useSocket } from "./ControlSocketProvider";
import { useControl } from "./RobotControlProvider";
import { GamepadContext } from "./GamepadProvider";
import {
    joyCtrl,
    handleKeyDown,
    handleKeyUp,
    move,
    stow,
    unstow,
    carry,
    gripperOpen,
    pickObject,
} from "../lib/protocols/control";

export const RobotMovementContext = React.createContext();
RobotMovementContext.displayName = "RobotMovement";
export const RobotMovementConsumer = RobotMovementContext.Consumer;

// const movementLogger = throttle((...args) => {
// console.log(...args);
// }, 100);

const copyIfNumber = (src, dst, key) => {
    const val = src[key];
    if (typeof val === "number" && !Number.isNaN(val)) {
        dst[key] = val;
    }
};

const noMovement = (m) => m.x === 0 && m.y === 0 && m.z === 0;

function RobotMovementProvider({ children }) {
    const { sendControl } = useSocket();
    const { useKeyboard, useGamepad, speed } = useControl();
    const { axes, buttons, triggers } = useContext(GamepadContext);
    const [movement, setMovement] = useState({ x: 0, y: 0, z: 0 });
    // useArmMovement();
    const movementRef = useRef(movement);
    const isStoppedRef = useRef(true);
    const periodicMovementId = useRef(0);

    const changeMovements = useCallback(
        (axes = {}) => {
            const toSet = {};
            copyIfNumber(axes, toSet, "x");
            copyIfNumber(axes, toSet, "y");
            copyIfNumber(axes, toSet, "z");
            setMovement((prev) => ({ ...prev, ...toSet }));
        },
        [setMovement]
    );

    const changeMovement = ({ axis, value = 0 }) => {
        changeMovements({ [axis]: value });
    };

    const sendMovement = useCallback(
        (movement) => {
            sendControl(move(movement));
        },
        [sendControl]
    );

    const onKeyDown = (e) => {
        e.preventDefault();
        changeMovement(handleKeyDown(e.code));
    };

    const onKeyUp = (e) => {
        e.preventDefault();
        changeMovement(handleKeyUp(e.code));
    };

    useEffect(() => {
        if (!useGamepad || !axes || !triggers) return;
        changeMovements({
            x: axes["-LeftY"] * speed,
            y: axes["LeftX"] * speed,
            z: axes["RightX"] * speed,
        });
    }, [axes, triggers, useGamepad, speed, changeMovements]);
    // handle keyboard input for spot control
    useEffect(() => {
        if (useKeyboard) {
            window.addEventListener("keydown", onKeyDown);
            window.addEventListener("keyup", onKeyUp);
        } else {
            window.removeEventListener("keydown", onKeyDown);
            window.removeEventListener("keyup", onKeyUp);
        }
        return () => {
            window.removeEventListener("keydown", onKeyDown);
            window.removeEventListener("keyup", onKeyUp);
        };
    }, [useKeyboard]);

    // update movementRef when movement changes. the interval will uses the reference to periodically send current movement info to robot and keep it moving if movements are not zero
    useEffect(() => {
        movementRef.current = movement;
    }, [movement]);
    useEffect(() => {
        periodicMovementId.current = setInterval(() => {
            const m = movementRef.current;
            const hasNoMovement = noMovement(m);
            // if it's stopped already and there's no movement, don't do anything
            if (isStoppedRef.current && hasNoMovement) {
                return;
            }
            // else send movement
            // console.log("sending basic movement...", m);
            sendMovement(m);
            // if there is no movement in m, mark as stopped. this interval will not send movement next time.
            isStoppedRef.current = hasNoMovement;
        }, 100);
        return () => {
            periodicMovementId.current && clearInterval(periodicMovementId.current);
        };
    }, [sendMovement]);

    return (
        <RobotMovementContext.Provider value={{ changeMovement }}>
            {children}
        </RobotMovementContext.Provider>
    );
}

export default RobotMovementProvider;

// function useArmMovement() {
//     const { axes, buttons, triggers } = useContext(GamepadContext);
//     const { sendControl } = useSocket();
//     const { useGamepad } = useControl();

//     const sendArm = (args) => throttle(() => sendControl(args), 100);

//     useEffect(() => {
//         if (!useGamepad || !axes || !triggers) return;
//         // console.log("axes changed", axes, triggers);
//         sendArm(
//             joyCtrl(
//                 axes["LeftX"],
//                 -axes["-LeftY"],
//                 axes["rightX"],
//                 -axes["-RightY"],
//                 triggers["LeftTrigger"],
//                 triggers["RightTrigger"]
//             )
//         );
//     }, [axes, triggers]);

//     useEffect(() => {
//         if (!useGamepad || !buttons) return;
//         // the use of buttons is just random
//         if (buttons["A"]) sendArm(stow());
//         if (buttons["B"]) sendArm(unstow());
//         if (buttons["X"]) sendArm(carry());
//         if (buttons["Y"]) sendArm(gripperOpen());
//         if (buttons["LB"]) sendArm(pickObject());
//         if (buttons["RB"]) sendArm();
//         if (buttons["DpadUp"]) sendArm();
//         if (buttons["DpadDown"]) sendArm();
//         if (buttons["DpadLeft"]) sendArm();
//         if (buttons["DpadRight"]) sendArm();
//     }, [buttons]);
// }
