import { DEFAULT_IP } from "../consts";

let ip = DEFAULT_IP;

export const setServiceIP = (targetIP) => (ip = targetIP);

export const callService = (name, data) => {
    return fetch(`${window.location.protocol}//${ip}/service`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            ...data,
            service: name,
        }),
    }).then((res) => {
        if (!res.ok) throw res;
        return res.json();
    });
};

const parseJsonOrThrow = async (res) => {
    try {
        return await res.json();
    } catch {
        throw new Error("Unexpected response from server");
    }
};

const handleServiceErrors = async (promise) => {
    let res;
    try {
        // try to get result from promise
        res = await promise;
    } catch {
        // no response due to network errors
        throw new Error("No response from server");
    }
    if (!res.ok) {
        // server returned a response but error code is not ok
        // let it throw if it's not valid JSON
        const errJson = await parseJsonOrThrow(res);
        if (errJson.err) {
            // throw error with message from server if "err" is set
            // SPOT service is expected to throw a human-readable message
            throw Error(errJson.err);
        } else {
            // otherwise tell user it's an unspecified error
            throw Error("Unspecified error");
        }
    } else {
        return res;
    }
};

export const callSpotService = (cmd, data) => {
    const promise = fetch(`${window.location.protocol}//${ip}/service`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            service: "spot",
            cmd,
            data,
        }),
    });
    return handleServiceErrors(promise).then((res) => parseJsonOrThrow(res));
};

export const callLogService = (type, id) => {
    const promise = fetch(`${window.location.protocol}//${ip}/service`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            service: `/mission/log/${type}`,
            log_id: id,
        }),
    });
    return handleServiceErrors(promise).then((res) => parseJsonOrThrow(res));
};
