import React, { useState, useContext, useEffect, useCallback } from "react";

import { useControl } from "./RobotControlProvider";
import { RobotMovementContext } from "./RobotMovementProvider";
import { RobotStateContext } from "./RobotStateProvider";
// import { AudioContext } from "./AudioProvider";
import { useArmControl } from "./ArmControlProvider";
import { useNoti } from "./NotificationProvider";
import { useSnack } from "./SnackProvider";
import { MODE_SIT } from "../lib/protocols/control";

import JoystickWrapper from "./JoystickWrapper";
import Menu from "./Menu";
import MenuButton from "./MenuButton";
import CameraPanel from "./CameraPanel";
import NotificationPanel from "./NotificationPanel";

import styles from "./css/BottomBar.module.css";
import cameraMenuStyles from "./css/Menu.module.css";
import { ReactComponent as IconCamera } from "../svg/IconCamera.svg";
// import { ReactComponent as IconMic } from "../svg/IconMic.svg";
import { ReactComponent as IconCandyBox } from "../svg/IconCandyBox.svg";
import { ReactComponent as IconLight } from "../svg/IconLight.svg";

const ButtonsBar = React.memo(function ButtonsBar() {
    // use regular function so that dev tool can display name
    const [showNotification, setShowNotification] = useState(false);
    const [showActionMenu, setShowActionMenu] = useState(false);
    // const { micOpened, setMicOpen } = useContext(AudioContext);
    const [showCameraMenu, setShowCameraMenu] = useState(false);
    const { light, changeLight } = useControl();

    const hasLight = JSON.parse(process.env.REACT_APP_HAS_LIGHT);
    return (
        <div className={styles.nav}>
            {/* <MenuButton
                icon={micOpened ? IconMic : IconMute}
                iconType="svg"
                description={micOpened ? "Mute" : "Open Mic"}
                btnClass={micOpened ? "active" : ""}
                action={() => setMicOpen(!micOpened)}
            ></MenuButton> */}

            {hasLight && (
                <MenuButton
                    icon={IconLight}
                    iconType="svg"
                    description="Light"
                    btnClass={(light && "active") || null}
                    action={() => changeLight(!light)}
                ></MenuButton>
            )}

            <MenuButton
                icon={IconCamera}
                iconType="svg"
                description="Switch Camera"
                action={() => setShowCameraMenu(true)}
            ></MenuButton>

            <MenuButton
                icon={IconCandyBox}
                iconType="svg"
                description="Menu"
                action={() => setShowActionMenu(!showActionMenu)}
            ></MenuButton>

            {showActionMenu && <Menu closeMenu={() => setShowActionMenu(false)}></Menu>}
            {showCameraMenu && (
                <div
                    className={cameraMenuStyles.container}
                    tabIndex={1}
                    ref={(div) => div && div.focus()}
                    onBlur={(e) => {
                        if (!e.currentTarget.contains(e.relatedTarget)) {
                            setShowCameraMenu(false);
                        }
                    }}
                >
                    <CameraPanel onClose={() => setShowCameraMenu(false)}></CameraPanel>
                </div>
            )}

            <NotificationPanel
                closePanel={() => setShowNotification(false)}
                isShow={showNotification}
            ></NotificationPanel>
        </div>
    );
});

function BottomBar() {
    const { robotControllable, robotMode } = useContext(RobotStateContext);
    const { changeMovement } = useContext(RobotMovementContext);
    const { speed } = useControl();
    const { inArmControl, changeArmMovement } = useArmControl();
    const { addToast } = useSnack();

    const joystickSize = 140;
    const joystickProps = {
        className: styles.joystick,
        disabled: !robotControllable || robotMode === MODE_SIT,
        // disabled: false,
        size: joystickSize,
        onClick: () => {
            if (!robotControllable || robotMode === MODE_SIT)
                addToast({
                    type: "error",
                    message: "Cannot move when the robot is sitting, estopped or powered off",
                });
        },
    };
    const onChangeLeft = useCallback(
        ({ x: y = 0, y: x = 0 }) => {
            // console.log("joystick left: inArmControl:", inArmControl);
            if (inArmControl) {
                // console.log("change arm movement...", { x, y });
                changeArmMovement({ position: "leftX", value: y }); // actually x
                changeArmMovement({ position: "leftY", value: 0 - x }); // actually -y
            } else {
                changeMovement({ axis: "x", value: x * speed });
                changeMovement({ axis: "y", value: y * speed });
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [inArmControl] // don't depend on a non-memorized function
    );

    const onChangeRight = useCallback(
        ({ x = 0, y = 0 } = {}) => {
            console.log("joystick right: inArmControl:", inArmControl);
            if (inArmControl) {
                changeArmMovement({ position: "rightX", value: x });
                changeArmMovement({ position: "rightY", value: -y });
            } else {
                changeMovement({ axis: "z", value: x * speed });
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [inArmControl] // don't depend on a non-memorized function
    );

    return (
        <div className={styles.container}>
            <JoystickWrapper {...joystickProps} onChange={onChangeLeft}></JoystickWrapper>

            <ButtonsBar></ButtonsBar>

            <JoystickWrapper {...joystickProps} onChange={onChangeRight}></JoystickWrapper>
        </div>
    );
}

export default BottomBar;
