import React, { useState } from "react";
import styles from "./css/Select.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

import useClickOutside from "../lib/hooks/useClickOutside";

const Select = ({ options, placeholder, onSelect }) => {
    const [isOpen, setOpen] = useState(false);
    const [items] = useState(options);
    const [selectedItem, setSelectedItem] = useState(null);
    const clickOutsideRef = useClickOutside(() => setOpen(false));

    const toggleDropdown = () => setOpen(!isOpen);

    const handleItemClick = (item) => {
        setSelectedItem(item);
        onSelect(item?.id);
        setOpen(false);
    };

    return (
        <div className={styles.dropdown} ref={clickOutsideRef}>
            <div className={styles.header} onClick={toggleDropdown}>
                {selectedItem?.label ?? placeholder}
                <FontAwesomeIcon
                    className={`${styles.icon} ${isOpen && styles.open}`}
                    icon={faChevronDown}
                />
            </div>
            {/* option list */}
            <div className={`${styles.body} ${isOpen && styles.open}`}>
                {items.map((item) => (
                    <div
                        className={styles.item}
                        onClick={() => handleItemClick(item)}
                        key={item.id}
                        id={item.id}
                    >
                        <span
                            className={`${styles.dot} ${
                                item.id === selectedItem && styles.selected
                            }`}
                        >
                            •{" "}
                        </span>
                        {item.label}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Select;
