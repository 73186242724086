import React, { useState } from "react";
import Streamer from "./Streamer";
import AirQBar from "./AirQBar";
import BottomBar from "./BottomBar";
import styles from "./css/MainScreen.module.css";

export const MenuContext = React.createContext();
MenuContext.displayName = "Menu";
export const MenuConsumer = MenuContext.Consumer;

function MainScreen() {
    const [showIAQ, setShowIAQ] = useState(true);

    const menuActions = {
        showIAQ,
        toggleIAQ: () => setShowIAQ(!showIAQ),
    };

    return (
        <>
            <div className={styles.container}>
                <div className={styles["stream-container"]}>
                    <Streamer></Streamer>
                </div>
            </div>
            <div className={styles["airq-bar"]}>
                <AirQBar isShow={showIAQ}></AirQBar>
            </div>
            <MenuContext.Provider value={menuActions}>
                <BottomBar></BottomBar>
            </MenuContext.Provider>
        </>
    );
}

export default MainScreen;
