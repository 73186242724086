import React, { useState, useContext } from "react";
import { CameraContext } from "./CameraProvider";
import { useMission } from "./MissionProvider";
import { useArmControl } from "./ArmControlProvider";
import { useNoti } from "./NotificationProvider";

import styles from "./css/Streamer.module.css";
import { ReactComponent as IconBell } from "../svg/IconBell.svg";
import { ReactComponent as IconBellBadged } from "../svg/IconBellBadged.svg";

import StreamStatus from "./StreamStatus";
import ArmControlButtonPanel from "./ArmControlButtonPanel";
import ActionBanner from "./ActionBanner";
import MenuButton from "./MenuButton";
import NotificationPanel from "./NotificationPanel";
import Crosshair from "./Crosshair";

const Streamer = () => {
    const [isStatusShow, setStatusShow] = useState(false);
    const [timeoutId, setTimeoutId] = useState(null);
    const { imageDataUrl, camConnected, streamStatus } = useContext(CameraContext);
    const { recordEnded } = useMission();
    const { inArmControl, armAction, setArmAction, crosshair } = useArmControl();
    const { hasUnreadNoti, hasNewNoti } = useNoti();
    const [showNotification, setShowNotification] = useState(false);

    const setTempStatusShow = (timeout, show = true) => {
        const resetTimeout = () => {
            clearTimeout(timeoutId);
            setTimeoutId(null);
        };

        if (show) {
            timeoutId && resetTimeout();
            setStatusShow(true);
            setTimeoutId(
                setTimeout(() => {
                    setStatusShow(false);
                    setTimeoutId(null);
                }, timeout)
            );
        } else {
            clearTimeout(timeoutId);
            setStatusShow(false);
            setTimeoutId(null);
        }
    };

    return (
        <div className={styles.container}>
            {!recordEnded && (
                <div className={styles["record-status"]}>
                    <p>Now recording</p>
                </div>
            )}
            <div
                className={styles.screen}
                onPointerEnter={() => setTempStatusShow(5000)}
                onMouseLeave={() => setTempStatusShow(0, false)}
            >
                <div className={styles["arm-control-panel"]}>
                    {armAction && (
                        <ActionBanner
                            {...armAction}
                            onCancel={() => setArmAction(null)}
                        ></ActionBanner>
                    )}
                    <Crosshair isFocus={crosshair?.isFocus} type={crosshair?.type} />
                    <div className={styles["in-screen-btn-panel"]}>
                        <ArmControlButtonPanel />
                    </div>
                </div>

                {inArmControl && (
                    <div className={styles["sub-screen"]}>
                        <img
                            alt="Camera stream"
                            src={`/${process.env.REACT_APP_STREAM_DUMMY}`}
                        ></img>
                    </div>
                )}

                <div className={styles["stream-status-wrapper"]}>
                    <StreamStatus
                        {...{ camConnected, streamStatus, isShow: isStatusShow }}
                    ></StreamStatus>
                    <MenuButton
                        icon={hasUnreadNoti ? IconBellBadged : IconBell}
                        iconType="svg"
                        btnClass={(hasNewNoti && styles["new-noti"]) || null}
                        iconClass={(hasUnreadNoti && styles["unread-noti"]) || null}
                        action={() => setShowNotification(!showNotification)}
                    ></MenuButton>
                    <NotificationPanel
                        closePanel={() => setShowNotification(false)}
                        isShow={showNotification}
                    ></NotificationPanel>
                </div>

                <img
                    alt="Camera stream"
                    src={imageDataUrl || `/${process.env.REACT_APP_STREAM_DUMMY}`}
                ></img>
            </div>
        </div>
    );
};

export default Streamer;
