import throttle from "lodash/throttle";
import * as THREE from "three/build/three.module";
import joystickFore from "../svg/JoyStickFore.svg";
import joystickBack from "../svg/JoyStickBack.svg";

// eslint-disable-next-line no-unused-vars
import { throttledLog } from "./utils";

const findIntersect = (radius, endPoint) => {
    const origin = new THREE.Vector2(0, 0);
    const v = new THREE.Vector2().subVectors(origin, endPoint);
    const lineLength = v.length();
    if (lineLength < radius) return new THREE.Vector2(-endPoint.x, -endPoint.y);
    const intersect = origin.add(v.normalize().multiplyScalar(radius));
    // throttledLog('findIntersect:', endPoint, v, lineLength, intersect)
    return new THREE.Vector2(intersect.x, intersect.y);
};

const setSize = (elem, size) => {
    elem.style.height = elem.style.width = `${size}px`;
};
export function Renderer(size) {
    // the dot in middle of
    this.size = size;
    const stickSize = (this.stickSize = size / 3);
    const elem = (this.domElement = document.createElement("div"));
    elem.style.position = "relative";
    elem.style.background = `url(${joystickBack}) no-repeat`;
    setSize(elem, size);
    const stickElem = (this.stickElem = document.createElement("div"));
    stickElem.style.position = "absolute";
    stickElem.style.background = `url(${joystickFore}) no-repeat`;
    setSize(stickElem, stickSize);
    this.setPos(0, 0);
    this.render();
    elem.appendChild(stickElem);
}

Renderer.prototype.setPos = function setPos(x, y) {
    this.circle = { position: { x, y } };
};

Renderer.prototype.render = function render() {
    const { x, y } = this.circle.position;
    const compensation = this.size / 2 - this.stickSize / 2;
    this.stickElem.style.left = `${x + compensation}px`;
    this.stickElem.style.top = `${y + compensation}px`;
};

/* Joystick logic implemented in a class
    Uses a Renderer class to render and animate
    expected members of a Renderer class:
    1. setPos - to set the position of the joystick
    2. size - the size of the whole joystick element
    3. render - a method to render one frame of the joystick
*/

export function Joystick(size) {
    this.renderer = new Renderer(size);
    this.operating = false;
}
Joystick.prototype.getDomElement = function getDomElement() {
    return this.renderer.domElement;
};
Joystick.prototype.operate = function operate(position) {
    if (this.operating) return;
    const plateRadius = this.renderer.size / 2;
    // console.log("joystick size:", plateRadius);

    const mouse = new THREE.Vector2();
    // document.body.appendChild( this.renderer.domElement )
    const width = this.renderer.domElement.clientWidth;
    const height = this.renderer.domElement.clientHeight;
    this.pointerInCanvas = false;

    this.onUserMove = (event) => {
        const rect = this.renderer.domElement.getBoundingClientRect();
        const intersect = findIntersect(plateRadius, mouse);
        mouse.x = event.clientX - rect.x - width / 2;
        mouse.y = event.clientY - rect.y - height / 2;

        this.renderer.setPos(-intersect.x, -intersect.y);
        const circle = this.renderer.circle;
        this.getDomElement().dispatchEvent(
            new CustomEvent("change", {
                detail: { x: circle.position.x / plateRadius, y: circle.position.y / plateRadius },
            })
        );
    };

    this.throttledUserMove = throttle(this.onUserMove, 33);
    this.onMouseMove = (event) => {
        this.throttledUserMove(event);
    };
    this.onMouseDown = (event) => {
        const rect = this.renderer.domElement.getBoundingClientRect();
        if (
            event.clientX > rect.x &&
            event.clientX < rect.x + width &&
            event.clientY > rect.y &&
            event.clientY < rect.y + height
        ) {
            this.pointerInCanvas = true;
            window.addEventListener("mousemove", this.onMouseMove, false);
        }
    };
    this.onUserMoveEnd = (event) => {
        // console.log("on move end");
        if (this.pointerInCanvas) {
            this.getDomElement().dispatchEvent(
                new CustomEvent("change", {
                    detail: { x: 0, y: 0 },
                })
            );
            mouse.x = 0;
            mouse.y = 0;
            this.renderer.setPos(0, 0);
        }
        this.pointerInCanvas = false;
    };
    this.onMouseUp = (event) => {
        window.removeEventListener("mousemove", this.onMouseMove, false);
        this.onUserMoveEnd(event);
    };
    let touchId = null;
    this.onTouchMove = (event) => {
        // console.log('touchmove. touchId: ', touchId)
        for (const touch of event.touches) {
            if (touch.identifier !== touchId) continue;
            // console.log('calling mouse move...')
            // console.log('the touch:', touch)
            this.throttledUserMove(touch);
            break;
        }
        return false;
    };
    this.onTouchStart = (event) => {
        // console.log('touchstart. touchId: ', touchId)
        for (const touch of event.touches) {
            if (touchId !== null) return;
            const event = touch;
            // console.log('the touch:', event)
            const rect = this.getDomElement().getBoundingClientRect();
            if (
                event.clientX > rect.x &&
                event.clientX < rect.x + width &&
                event.clientY > rect.y &&
                event.clientY < rect.y + height
            ) {
                touchId = event.identifier;
                this.pointerInCanvas = true;
            }
        }
    };
    this.onTouchEnd = (event) => {
        // console.log('touchend. touchId: ', touchId, event)
        for (const touch of event.changedTouches) {
            if (touch.identifier !== touchId) continue;
            // console.log('end: touchId = null')
            touchId = null;
            this.onUserMoveEnd(touch);
            break;
        }
    };
    window.addEventListener("touchmove", this.onTouchMove, false);

    window.addEventListener("mousedown", this.onMouseDown);
    window.addEventListener("mouseup", this.onMouseUp);

    window.addEventListener("touchstart", this.onTouchStart);
    window.addEventListener("touchend", this.onTouchEnd);
    window.addEventListener("touchcancel", this.onTouchEnd);
    window.addEventListener("touchmove", this.onTouchMove, false);
    this.operating = true;
    const animate = () => {
        requestAnimationFrame(animate);
        this.renderer.render(this.scene, this.camera);
    };
    animate();
};
Joystick.prototype.stop = function stop() {
    if (!this.operating) return;
    this.operating = false;
    window.removeEventListener("touchmove", this.onTouchMove, false);

    window.removeEventListener("mousedown", this.onMouseDown);
    window.removeEventListener("mouseup", this.onMouseUp);

    window.removeEventListener("touchstart", this.onTouchStart);
    window.removeEventListener("touchend", this.onTouchEnd);
    window.removeEventListener("touchcancel", this.onTouchEnd);
    window.removeEventListener("touchmove", this.onTouchMove, false);
};
