import React, { useState, useEffect, useRef } from "react";
import GraphnavJsonRenderer from "../lib/graphnav_renderer";
import { useSnack } from "./SnackProvider";
import { useMission } from "./MissionProvider";
import { STATE_MISSION_LOCATION } from "../lib/state";
import { callSpotService } from "../lib/service";
import { missionMap } from "../lib/protocols/control";
import styles from "./css/Graph.module.css";
import MyUtils from "../lib/three/MyUtils";

import Icon from "./Icon";
import { ReactComponent as IconLoading } from "../svg/IconLoading.svg";
import { ReactComponent as IconReload } from "../svg/IconReload.svg";
import { ReactComponent as IconLocation } from "../svg/IconLocation.svg";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { positionDummy as DUMMY_GRAPHNAV_JSON } from "../testdata";

const USE_DUMMY_GRAPHNAV = false;
// const DUMMY_GRAPHNAV_JSON = require("../graphnav-example.json");

// DUMMY_MOVE: whether to simulate robot movements on the graphnav map
const DUMMY_MOVE = false;

export function Graph({ id }) {
    const { addToast } = useSnack();
    const { missionState, missionPayload } = useMission();
    const [robotLocation, setRobotLocation] = useState(null);
    const [graph, setGraph] = useState(null);
    const [graphLoading, setGraphLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const timeout = useRef(null);

    const loadGraph = (id) => {
        setGraphLoading(true);
        setGraph(null);
        setRobotLocation(null);

        if (USE_DUMMY_GRAPHNAV) {
            setGraph({
                id: 1,
                graph: DUMMY_GRAPHNAV_JSON,
                err: null,
            });
            setGraphLoading(false);
        } else {
            callSpotService(...missionMap(id))
                .then((res) => {
                    setGraph({ id, graph: res, err: null });
                })
                .catch((err) => {
                    setGraph({
                        id: null,
                        graph: null,
                        err: "Failed to load mission map.",
                    });
                    addToast({
                        type: "error",
                        message: "Failed to load mission map. Please try again or try another map.",
                        duration: 2000,
                    });
                })
                .finally(() => {
                    setGraphLoading(false);
                });
        }
    };

    const closeGraph = () => {
        setGraph(null);
    };

    useEffect(() => {
        if (missionState === STATE_MISSION_LOCATION) {
            const { waypoint_id, waypoint_tform_body } = missionPayload;
            setRobotLocation({ waypoint_id, waypoint_tform_body: [0, 0, 0] });
        }
    }, [missionState, missionPayload]);

    useEffect(() => {
        loadGraph(id);
    }, [id]);

    // dummy effect that demonstrate how UI displays progress and robot location
    useEffect(() => {
        if (!DUMMY_MOVE || !graph || !graph.graph) {
            return;
        }
        const dummytForm = MyUtils.sortWaypoints(graph.graph.waypoints).map((wp) => ({
            waypoint: wp.id,
            waypoint_tform_body: [0, 0],
        }));

        let i = 0;

        const func = () => {
            const { waypoint, waypoint_tform_body } = dummytForm[i];
            const [x, y] = waypoint_tform_body;
            setRobotLocation({
                waypoint,
                waypoint_tform_body: [x + Math.random() / 20, y + Math.random() / 20],
            });
            i++;
            if (i === dummytForm.length) return;
            timeout.current = setTimeout(func, 2000);
        };
        timeout.current = setTimeout(func, 2000);

        return () => timeout.current && clearTimeout(timeout.current);
    }, [graph]);
    // end dummy effect

    return (
        <>
            <div className={styles.progress}>
                Progress
                <div className={styles["progress-bar"]}>
                    <div style={{ width: `${progress}%` }}></div>
                </div>
                <span>{`${progress}%`}</span>
            </div>
            <div className={styles.container}>
                {graphLoading && (
                    <div
                        className={styles.fullscreen}
                        style={{ backgroundColor: "hsla(240, 30%, 21%, 1)" }}
                    >
                        <Icon icon={IconLoading} type="svg" className={styles.loading}></Icon>
                    </div>
                )}
                {graph?.err && (
                    <div className={styles.fullscreen}>
                        <Icon
                            icon={faExclamationTriangle}
                            type="fa"
                            size="2x"
                            className={styles.error}
                        ></Icon>
                        <span style={{ textAlign: "center" }}>{graph.err}</span>
                        <button className={styles.reload} onClick={() => loadGraph(id)}>
                            <Icon
                                icon={IconReload}
                                type="svg"
                                width="24px"
                                height="24px"
                                style={{ color: "white" }}
                            ></Icon>
                        </button>
                    </div>
                )}
                {graph && (
                    <GraphnavRendererWrapper
                        id={graph.id}
                        graph={graph.graph}
                        robotLocation={robotLocation}
                        setProgress={setProgress}
                    ></GraphnavRendererWrapper>
                )}
            </div>
        </>
    );
}

export function GraphnavRendererWrapper({ id, graph, robotLocation, setProgress }) {
    const [_id, setId] = useState(null);
    const renderer = useRef(null);

    const renderGraph = () => {
        if (!graph) return;
        renderer.current.loadGraph(graph);
        renderer.current.render();
    };

    const updateGraph = () => {
        if (!graph) return;
        if (_id !== id) {
            renderGraph();
            setId(id);
        }
    };

    const clearGraph = () => {
        renderer.current.clearGraph();
        renderer.current.stopRender();
    };

    const handleRef = (ref) => {
        if (!ref || renderer.current) return;
        renderer.current = new GraphnavJsonRenderer(ref);
        updateGraph();
    };

    const resetCamera = () => {
        if (!renderer.current) return;
        renderer.current.resetCamera();
    };

    useEffect(() => {
        if (!renderer.current) return;
        if (robotLocation) {
            const { waypoint_id, waypoint_tform_body } = robotLocation;
            if (waypoint_id && waypoint_tform_body && graph !== null) {
                renderer.current.updateRobotLocation(waypoint_id, waypoint_tform_body);
                setProgress(renderer.current.getProgress());
            }
        }
        // updateGraph();
    }, [robotLocation]);

    useEffect(() => {
        return () => {
            clearGraph();
            // renderer.current = null;
        };
    }, []);

    return (
        <div className={styles.graph} ref={handleRef}>
            <button className={styles["back-to-start"]} onClick={() => resetCamera()}>
                <Icon icon={IconLocation} type="svg" width="24px" height="24px"></Icon>
            </button>
        </div>
    );
}
