import React from "react";
import { useHistory } from "react-router-dom";
import styles from "./css/EntityAbsence.module.css";

import Icon from "./Icon";
import { ReactComponent as IconNoFile } from "../svg/IconNoFile.svg";

function EntityAbsence({
    title,
    description = null,
    btnText = null,
    icon = IconNoFile,
    path = "/main/new-record",
}) {
    const history = useHistory();

    return (
        <div className={styles.container}>
            <Icon icon={icon} type="svg" width="80px" height="80px"></Icon>
            <h1 className={styles.title}>{title}</h1>
            {description && <p>{description}</p>}
            {btnText && (
                <button className={styles.button} onClick={() => history.push(path)}>
                    {btnText}
                </button>
            )}
        </div>
    );
}

export default EntityAbsence;
