import React, { useState, useEffect, useContext } from "react";
import { STATE_MISSION_FAILED, errToMsg } from "../lib/state";
import { useMission } from "./MissionProvider";
import MissionItem from "./MissionItem";
import Section from "./Section";
import EntityAbsence from "./EntityAbsence";
import MissionEditor from "./MissionEditor";
import { Graph } from "./Graph";
import styles from "./css/MissionTab.module.css";

import Icon from "./Icon";
import { ReactComponent as IconEllipsis } from "../svg/IconEllipsis.svg";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import MenuButton from "./MenuButton";
import VideoButtons from "./VideoButtons";
import ErrorPopup from "./ErrorPopup";
import { strCompare } from "../lib/utils";
import useClickOutside from "../lib/hooks/useClickOutside";
import { RobotStateContext } from "./RobotStateProvider";

function MissionTab() {
    const { missionState, missionPayload, missions, listMission } = useMission();
    const [selectedId, setSelectedId] = useState(null);
    const [missionError, setMissionError] = useState(null);
    const [showEditMenu, setShowEditMenu] = useState(false);
    const [editAction, setEditAction] = useState(null);

    useEffect(() => {
        listMission(true);
    }, []);

    useEffect(() => {
        if (missionState === STATE_MISSION_FAILED) {
            const { err } = missionPayload;
            setMissionError({
                title: missionState,
                content: `${err}: ${errToMsg(err)}`,
            });
        }
    }, [missionState, missionPayload]);

    // the menu that contains rename mission and delete mission buttons
    const editMenuRef = useClickOutside(() => {
        setShowEditMenu(false);
    });

    const { robotControllable } = useContext(RobotStateContext);
    const disabled = !robotControllable;

    return (
        <>
            {!selectedId && (
                <Section retractable={false} style={{ width: "257px" }}>
                    <Section.Header>
                        Mission Record
                        <button
                            className={styles["sm-action-btn"]}
                            onClick={() => listMission(true)}
                        >
                            (Refresh)
                        </button>
                    </Section.Header>
                    <Section.Content scrollable={true} className={styles.content}>
                        {missions ? (
                            missions.length > 0 ? (
                                missions
                                    .sort(strCompare({ reverse: true }))
                                    .map((name) => (
                                        <MissionItem
                                            key={name}
                                            id={name}
                                            name={name}
                                            onClick={() => setSelectedId(name)}
                                        ></MissionItem>
                                    ))
                            ) : (
                                <EntityAbsence
                                    title={"No Recorded Mission Yet"}
                                    btnText={"Record a Mission"}
                                ></EntityAbsence>
                            )
                        ) : (
                            // skeleton
                            [...Array(5)].map((x, i) => <MissionItem key={i} isLoading={true} />)
                        )}
                    </Section.Content>
                </Section>
            )}

            {selectedId && (
                <>
                    <div className={styles["top-actions"]}>
                        <MenuButton
                            icon={faChevronLeft}
                            iconType="fa"
                            action={() => setSelectedId(null)}
                        />
                        {selectedId}
                        <span className={styles["more-btn"]}>
                            <button
                                onClick={(e) => {
                                    setShowEditMenu(!showEditMenu);
                                }}
                            >
                                <Icon
                                    icon={IconEllipsis}
                                    type="svg"
                                    width="24px"
                                    height="24px"
                                ></Icon>
                            </button>
                            {showEditMenu && (
                                <div className={styles["mission-edit"]} ref={editMenuRef}>
                                    <button onClick={() => setEditAction("rename")}>
                                        Rename Mission
                                    </button>
                                    <div aria-label="separator"></div>
                                    <button
                                        onClick={() => setEditAction("delete")}
                                        style={{ color: "red" }}
                                    >
                                        Delete Mission
                                    </button>
                                </div>
                            )}
                        </span>
                        {editAction && (
                            <MissionEditor
                                oldId={selectedId}
                                currName={selectedId}
                                editAction={editAction}
                                onClose={(newName) => {
                                    setSelectedId(newName);
                                    setShowEditMenu(false);
                                    setEditAction(null);
                                }}
                            ></MissionEditor>
                        )}
                    </div>

                    <Section retractable={false} style={{ width: "257px" }}>
                        <Section.Header>Current Mission</Section.Header>
                        <Section.Content className={styles.content}>
                            <VideoButtons type="run" selectedId={selectedId} disabled={disabled} />
                            <Graph id={selectedId}></Graph>
                        </Section.Content>
                    </Section>
                </>
            )}

            {missionError && (
                <ErrorPopup
                    type="error"
                    title={missionError.title}
                    content={missionError.content}
                    onClose={() => setMissionError(null)}
                ></ErrorPopup>
            )}
        </>
    );
}

export default MissionTab;
