import React, { useState } from "react";
import styles from "./css/MissionTab.module.css";

import { callSpotService } from "../lib/service";
import {
    missionRunStart,
    missionRunPause,
    missionRunStop,
    missionRecordStart,
    missionRecordStop,
} from "../lib/protocols/control";
import { useMission } from "./MissionProvider";

import Icon from "./Icon";
import { ReactComponent as IconRecord } from "../svg/IconRecord.svg";
import { ReactComponent as IconPlay } from "../svg/IconPlay.svg";
import { ReactComponent as IconPause } from "../svg/IconPause.svg";
import { ReactComponent as IconStop } from "../svg/IconStop.svg";

import Switch from "./Switch";

function VideoButtons({ type, selectedId, disabled = false }) {
    const { missionEnded, missionPaused, recordEnded } = useMission();
    const [enableLog, setEnableLog] = useState(true);

    const startMissionRecord = () =>
        callSpotService(...missionRecordStart()).catch((err) => console.error(err));
    const stopMissionRecord = () =>
        callSpotService(...missionRecordStop()).catch((err) => console.error(err));

    const startMissionRun = (id) =>
        callSpotService(...missionRunStart(id, enableLog)).catch((err) => console.error(err));
    const pauseMissionRun = () =>
        callSpotService(...missionRunPause()).catch((err) => console.error(err));
    const stopMissionRun = () =>
        callSpotService(...missionRunStop()).catch((err) => console.error(err));

    return (
        <>
            {type === "run" && (
                <>
                    <div className={styles["video-btn-wrapper"]}>
                        <button
                            disabled={!missionEnded || !selectedId || disabled}
                            onClick={() => startMissionRun(selectedId)}
                        >
                            <Icon icon={IconPlay} type="svg" width="32px" height="32px"></Icon>
                        </button>
                        <button
                            disabled={missionPaused || !selectedId || disabled}
                            onClick={() => pauseMissionRun()}
                        >
                            <Icon icon={IconPause} type="svg" width="32px" height="32px"></Icon>
                        </button>
                        <button
                            disabled={missionEnded || !selectedId || disabled}
                            onClick={() => stopMissionRun()}
                        >
                            <Icon icon={IconStop} type="svg" width="32px" height="32px"></Icon>
                        </button>
                    </div>
                    {process.env.NODE_ENV === "development" && (
                        <>
                            <Switch
                                title="Enable Mission Logging"
                                isOn={enableLog}
                                onToggle={() => setEnableLog((enable) => !enable)}
                                disabled={!missionEnded}
                            ></Switch>
                            <p style={{ margin: 0, fontSize: "x-small" }}>
                                note: currently disabled and only visible in dev mode⬆️
                            </p>
                        </>
                    )}
                </>
            )}
            {type === "record" && (
                <div className={styles["video-btn-wrapper"]}>
                    <button onClick={startMissionRecord} disabled={!recordEnded || disabled}>
                        <Icon icon={IconRecord} type="svg" width="32px" height="32px" />
                    </button>
                    <button onClick={stopMissionRecord} disabled={recordEnded || disabled}>
                        <Icon icon={IconStop} type="svg" width="32px" height="32px" />
                    </button>
                </div>
            )}
        </>
    );
}

export default VideoButtons;
