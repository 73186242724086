const STREAM_SELECT = 0x02;

export const selectStream = (name) => {
    const nameGiven = typeof name === "string";
    if (!nameGiven) {
        throw new Error("Please give a string to selectStream");
    }
    const encodedName = new TextEncoder().encode(name);
    const out = new Uint8Array(1 + 1 + encodedName.length);
    out[0] = STREAM_SELECT;
    const sizeBuffer = new Uint8Array([encodedName.length]);
    out.set(sizeBuffer, 1);
    if (nameGiven) {
        out.set(new TextEncoder().encode(name), 2);
    }
    return out.buffer;
};

export const setAIOnOff = (on) => `gie/${on ? "on" : "off"}`;

export const startRecord = (durationS = 7200) => `record/${durationS}`;

export const stopStreaming = () => "quit";

export const cameras = JSON.parse(process.env.REACT_APP_CAMERAS);
const nullCam = { name: null, source: null };
export const defaultCam = cameras[0] || nullCam;

export const getCamera = ({ name, source }) =>
    cameras.find(({ name: n, source: s }) => n === name || s === source);

// "shutdown" because it's arranged to always start again after a shutdown
export const restartStream = () => ["/ros_media_stream/shutdown"];
