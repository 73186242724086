import React from "react";
import { GoogleMap, Marker, LoadScript } from "@react-google-maps/api";

const containerStyle = {
    width: "95%",
    height: "550px",
    margin: "44px auto 0 auto",
    borderRadius: "10px",
};

const coordinate = {
    lat: 22.33930598164702,
    lng: 114.15120711134136,
};

const ZOOM_LV = 20;

function MyComponent() {
    const [map, setMap] = React.useState(null);

    const onLoad = React.useCallback(function callback(map) {
        const bounds = new window.google.maps.LatLngBounds();
        map.fitBounds(bounds);
        setMap(map);
    }, []);

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null);
    }, []);

    return (
        <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}>
            <GoogleMap mapContainerStyle={containerStyle} center={coordinate} zoom={ZOOM_LV}>
                <Marker position={coordinate}></Marker>
            </GoogleMap>
        </LoadScript>
    );
}

export default React.memo(MyComponent);
