import throttle from "lodash/throttle";

export const throttledLog = throttle(function () {
    console.log(...arguments);
}, 50);

// transform text from (CAPITAL, Title, normal) to kebab-case
export const toKebabCase = (string) => string.toLowerCase().split(" ").join("-");

// transform time to format - dd/mm/yyyy hh:mm:ss
export const toTimestamp = (datetime) => new Date(datetime).toLocaleString("en-GB");

export const download_table_as_csv = (table_id, separator = ",", file_name = null) => {
    // Select rows from table_id
    var rows = document.querySelectorAll("table#" + table_id + " tr");
    // Construct csv
    var csv = [];
    for (var i = 0; i < rows.length; i++) {
        var row = [],
            cols = rows[i].querySelectorAll("td, th");
        for (var j = 0; j < cols.length; j++) {
            // Clean innertext to remove multiple spaces and jumpline (break csv)
            var data = cols[j].innerText.replace(/(\r\n|\n|\r)/gm, "").replace(/(\s\s)/gm, " ");
            // Escape double-quote with double-double-quote (see https://stackoverflow.com/questions/17808511/properly-escape-a-double-quote-in-csv)
            data = data.replace(/"/g, '""');
            // Push escaped string
            row.push('"' + data + '"');
        }
        csv.push(row.join(separator));
    }
    var csv_string = csv.join("\n");
    // Download it
    var filename =
        file_name ?? "export_" + table_id + "_" + new Date().toLocaleDateString() + ".csv";
    var link = document.createElement("a");
    link.style.display = "none";
    link.setAttribute("target", "_blank");
    link.setAttribute("href", "data:text/csv;charset=utf-8," + encodeURIComponent(csv_string));
    link.setAttribute("download", filename);
    link.click();
};

export const copyToClipboard = (text) => {
    if (window.isSecureContext) {
        navigator.clipboard?.writeText(text);
        return;
    }
    if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
        var textarea = document.createElement("textarea");
        textarea.textContent = text;
        textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in Microsoft Edge.
        document.body.appendChild(textarea);
        textarea.select();
        try {
            return document.execCommand("copy"); // Security exception may be thrown by some browsers.
        } catch (ex) {
            console.warn("Copy to clipboard failed.", ex);
            return prompt("Copy to clipboard: Ctrl+C, Enter", text);
        } finally {
            document.body.removeChild(textarea);
        }
    }
};

export const strCompare =
    ({ reverse = false } = {}) =>
    (a, b) => {
        const first = reverse ? b : a;
        const second = reverse ? a : b;
        return first > second ? 1 : first === second ? 0 : -1;
    };
