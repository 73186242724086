import React, { useState, useContext, useEffect } from "react";
import { callLogService } from "../lib/service";
import { dummyMissionLogs, dummyMissionNames } from "../testdata";
import { useSnack } from "./SnackProvider";
import flatten from "lodash/flatten";
import { DEFAULT_IP } from "../consts";

export const TYPE_IAQ = "IAQ";
export const TYPE_OBJECT_DETECTION = "OBJECT_DETECTED";
export const TYPE_MISSION_STARTING = "MISSION_STARTING";
export const TYPE_MISSION_STARTED = "MISSION_STARTED";
export const TYPE_MISSION_FAILED = "MISSION_FAILED";
export const TYPE_MISSION_STOPPED = "MISSION_STOPPED";
export const TYPE_MISSION_LOCATION = "MISSION_LOCATION";

export const TYPE_VIDEO_RECORD_STATE = "VID_RECORD_STATE";
export const VIDEO_RECORD_STATE_RECORD_STARTED = "RECORD_STARTED";
export const VIDEO_RECORD_STATE_RECORD_STOPPED = "RECORD_STOPPED";
export const VIDEO_RECORD_STATE_RECORD_FAILED = "RECORD_FAILED";

const USE_DUMMY = false;

export const MissionLogContext = React.createContext();
MissionLogContext.displayName = "MissionLog";
export const MissionLogConsumer = MissionLogContext.Consumer;
export const useLog = () => useContext(MissionLogContext);

export const normalizeVideoLink = (filename) => {
    const addr = DEFAULT_IP.split(":")[0];
    return `${window.location.protocol}//${addr}/log/videos/${filename}`;
};

export const getMissionNameById = (missions = [], id) => {
    const filtered = missions.filter(({ log_id }) => log_id === id);
    if (filtered.length > 0) return filtered.name;
    return null; // null if not found
};

export const getDetectionKeywords = (events = []) => {
    if (events.length <= 0) return [];
    const DectectionEvents = events.filter((event) => event.type === TYPE_OBJECT_DETECTION);
    const objs = new Set(flatten(DectectionEvents.map((event) => event.data)));
    if (objs.size === 0) {
        return [];
    } else {
        return ["detected", ...objs];
    }
};

export const getVideoRecordKeywords = (events = []) => {
    const RecordEvents = events.filter((event) => event.type === TYPE_VIDEO_RECORD_STATE);
    return RecordEvents.length === 0 ? [] : ["record"];
};

export const getKeywords = (events = []) => {
    return [
        "mission",
        ...getIAQKeywords(events),
        ...getDetectionKeywords(events),
        ...getVideoRecordKeywords(events),
    ];
};

export const getIAQKeywords = (events = []) => {
    if (getIAQData(events).length === 0) {
        return [];
    } else {
        return ["iaq"];
    }
};

export const getIAQSamplesFromEvent = (event) => {
    const samples = {
        time: new Date(event.timestamp).toLocaleTimeString("en-GB"), // hh:mm:ss
        samples: Object.entries(event.data.samples).reduce(
            (acc, [name, sample]) => ({
                ...acc,
                [name]: { ...sample, value: parseFloat(sample.value).toPrecision(4) },
            }),
            {}
        ),
    };
    return samples;
};

export const getIAQData = (events = []) => {
    if (events.length <= 0) return [];
    const IAQEvents = events.filter((event) => event.type === TYPE_IAQ);
    if (IAQEvents.length <= 0) return [];
    else return IAQEvents.map(getIAQSamplesFromEvent);
};

const validateVideosOrThrow = (vids) => {
    vids.forEach((v) => {
        if (!v.name || !v.url) {
            throw new Error("server returned corrupted data");
        }
    });
};

function MissionLogProvider({ children }) {
    const { setSnack } = useSnack();
    const [logs, setLogs] = useState([]);
    const [events, setEvents] = useState([]);
    const [videos, setVideos] = useState([]);
    const [IAQ, setIAQ] = useState([]);

    const getLogs = () => {
        if (USE_DUMMY) {
            setLogs(dummyMissionNames);
            return;
        }
        return callLogService("list", "")
            .then((res) => {
                if (!Array.isArray(res.logentries)) {
                    throw new Error("server returned corrupted data");
                }
                return res.logentries;
            })
            .then((logs) => {
                setLogs(logs);
            })
            .catch((err) => {
                if (!err.message) {
                    err = { message: "Unexpected error" };
                }
                const message = `Could not list logs: ${err.message}`;
                console.error(message, err);
                setSnack({
                    type: "error",
                    message,
                    action: () => getLogs(),
                    actionDesc: "Try again",
                });
            });
    };
    const getEvents = (id) => {
        if (USE_DUMMY) {
            setLogs(dummyMissionLogs[0].events);
            setIAQ(getIAQData(dummyMissionLogs[0].events));
            return;
        }
        return callLogService("events", id)
            .then((res) => {
                try {
                    return res.events
                        .map((event) => ({
                            timestamp: event.timestamp,
                            type: event.event_type,
                            data: JSON.parse(event.data),
                        }))
                        .filter((event) => {
                            return (
                                event.data !== VIDEO_RECORD_STATE_RECORD_STARTED &&
                                event.type !== TYPE_MISSION_STARTED &&
                                event.type !== TYPE_MISSION_LOCATION
                            );
                        });
                } catch (e) {
                    throw new Error("server returned corrupted data");
                }
            })
            .then((events) => {
                setEvents(events);
                try {
                    setIAQ(getIAQData(events));
                } catch (e) {
                    console.log("failed to get iaq data");
                }
            });
    };
    const getVideos = (id) => {
        if (USE_DUMMY) {
            setLogs(dummyMissionLogs[0].videos);
            return;
        }
        return callLogService("vids", id).then((res) => {
            validateVideosOrThrow(res);
            setVideos(res);
        });
    };

    const getLog = (logId) => {
        return Promise.all([getEvents(logId), getVideos(logId)]).catch((err) => {
            const message = `Could not get all of mission log: ${err.message}`;
            console.error(message, err);
            setSnack({
                type: "error",
                message,
                action: () => getLog(),
                actionDesc: "Try again",
            });
        });
    };

    return (
        <MissionLogContext.Provider
            value={{
                logs,
                events,
                videos,
                IAQ,
                getLogs,
                getEvents,
                getVideos,
                getLog,
                normalizeVideoLink,
            }}
        >
            {children}
        </MissionLogContext.Provider>
    );
}

export default MissionLogProvider;
