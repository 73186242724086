import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import Header from "./Header";
import SidePanel from "./SidePanel";
import MainScreen from "./MainScreen";
import FindMySpot from "./FindMySpot";
import LogHistory from "./LogHistory";
import DebugConsole from "./DebugConsole";
import SnackBar, { SnackGroup } from "./SnackBar";
import { useSnack } from "./SnackProvider";
import AIMarket from "./AIMarket";

import { useArmControl } from "./ArmControlProvider";
import ArmControlSidePanel from "./ArmControlSidePanel";
import styles from "./css/MainPanel.module.css";

function MainPanel() {
    const { path } = useRouteMatch();
    const { snack, setSnack, toasts, removeToast } = useSnack();
    const { inArmControl } = useArmControl();

    return (
        <div className={styles.container}>
            <Header></Header>
            <div className={styles.content}>
                <SnackGroup position="bottom-left">
                    {toasts.map((toast, i) => (
                        <SnackBar
                            size="small"
                            onClose={() => {
                                removeToast(toast.id);
                            }}
                            {...toast}
                            key={toast.id}
                        >
                            {toast.message}
                        </SnackBar>
                    ))}
                </SnackGroup>
                {/* {inArmControl ? (
                    <ArmControlSidePanel></ArmControlSidePanel>
                ) : ( */}
                <SidePanel></SidePanel>
                {/* )} */}

                <div className={styles["content-wrapper"]}>
                    {snack && (
                        <SnackBar
                            className={styles.snackBar}
                            onClose={() => setSnack(null)}
                            {...snack}
                        >
                            {snack.message}
                        </SnackBar>
                    )}
                    <Switch>
                        <Route path={`${path}/find-my-spot`}>
                            <FindMySpot></FindMySpot>
                        </Route>
                        <Route exact path={`${path}/log-history/`}>
                            <LogHistory></LogHistory>
                        </Route>
                        <Route path={`${path}/log-history/:logId`}>
                            <LogHistory></LogHistory>
                        </Route>
                        <Route path={path}>
                            <MainScreen></MainScreen>
                        </Route>
                    </Switch>
                </div>

                {/* fixed floating elements */}
                <Switch>
                    <Route path={`${path}/debug-console`}>
                        <DebugConsole></DebugConsole>
                    </Route>
                    <Route path={`${path}/ai-market`}>
                        <AIMarket></AIMarket>
                    </Route>
                </Switch>
            </div>
        </div>
    );
}

export default MainPanel;
