import React, { useState } from "react";
import styles from "./css/AIMarket.module.css";
import { useHistory, useRouteMatch, Switch as RouteSwitch, Route } from "react-router-dom";
import { dummyAIModel } from "../testdata";
import Tooltip from "./Tooltip";

import FloatingWindow from "./FloatingWindow";
import Tabs from "./Tabs";
import Switch from "./Switch";
import AIModel from "./AIModel";
import { ReactComponent as IconOffline } from "../svg/IconOffline.svg";

function AIModelBox({
    title,
    description,
    logo,
    isOn = false,
    onToggle = () => {},
    onClick = () => {},
    enableSwitch = true,
}) {
    return (
        <div
            className={`${styles.card} ${!enableSwitch && styles["card-no-switch"]}`}
            onClick={onClick}
        >
            <Tooltip content={title}>
                <img src={logo} alt={title} className={styles["card-logo"]} />
            </Tooltip>
            <div className={styles["card-content"]}>
                <h2 className={styles["card-title"]}>{title}</h2>
                <p className={styles["card-description"]}>{description}</p>
                {enableSwitch && (
                    <Switch
                        isOn={isOn}
                        onToggle={onToggle}
                        btnClass={isOn && styles["switch-on"]}
                    ></Switch>
                )}
            </div>
        </div>
    );
}

function AIMarket() {
    const history = useHistory();
    const { path } = useRouteMatch();
    const [tab, setTab] = useState("Purchased");

    const models = dummyAIModel;

    return (
        <FloatingWindow>
            {models?.length > 0 ? (
                <RouteSwitch>
                    <Route exact path={path}>
                        <Tabs tab={tab} defaultTab={tab} onSwitchTab={({ label }) => setTab(label)}>
                            <Tabs.Item index={0} label="Purchased">
                                <div className={styles.grid}>
                                    {models.map((model, index) => (
                                        <AIModelBox
                                            key={index}
                                            title={model.title}
                                            description={model.summary}
                                            logo={model.logo}
                                            onClick={() =>
                                                history.push(`/main/ai-market/${model.id}`)
                                            }
                                        ></AIModelBox>
                                    ))}
                                </div>
                            </Tabs.Item>
                            <Tabs.Item index={1} label="Explore">
                                <div className={styles.grid}>
                                    {models.map((model, index) => (
                                        <AIModelBox
                                            key={index}
                                            title={model.title}
                                            description={model.summary}
                                            logo={model.logo}
                                            enableSwitch={false}
                                            onClick={() =>
                                                history.push(`/main/ai-market/${model.id}`)
                                            }
                                        ></AIModelBox>
                                    ))}
                                </div>
                            </Tabs.Item>
                        </Tabs>
                    </Route>
                    <Route path={`${path}/:modelId`}>
                        <AIModel></AIModel>
                    </Route>
                </RouteSwitch>
            ) : (
                <div className={styles.offline}>
                    <IconOffline title="You are offline!" width="140px" height="140px" />
                    <h2>You are offline!</h2>
                    <p>Please check your network and try again!</p>
                </div>
            )}
        </FloatingWindow>
    );
}

export default AIMarket;
