import React, { useState, useEffect } from "react";
import styles from "./css/LogHistory.module.css";
import difference from "lodash/difference";
import union from "lodash/union";

function EventButton({ typeName, isActive, action }) {
    return (
        <button
            type="button"
            className={styles["event-btn"]}
            data-active={isActive ? "1" : "0"}
            onClick={action}
        >
            {typeName}
        </button>
    );
}

function LogHistorySummary({ isShow, keywords = [], selectedKeywords = [], setSelectedKeywords }) {
    if (isShow) {
        return (
            <div className={styles["summary"]}>
                <h2 className={styles["sub-title"]}>Keywords</h2>
                <span className={styles["sub-title-explainer"]}>
                    Select keywords to filter. Select none to not filter
                </span>
                {Array.isArray(keywords) && keywords.length > 0 ? (
                    <div className={styles["event-container"]}>
                        <EventButton
                            typeName="All"
                            isActive={selectedKeywords.length === 0}
                            action={() => setSelectedKeywords([])}
                        />
                        {keywords.map((keyword, index) => {
                            const selected = selectedKeywords.includes(keyword);
                            return (
                                <EventButton
                                    key={index}
                                    typeName={keyword}
                                    index={index}
                                    isActive={selected}
                                    action={() => {
                                        setSelectedKeywords(
                                            selected
                                                ? // remove if selected
                                                  difference(selectedKeywords, [keyword])
                                                : // else add to selected keywords
                                                  union(selectedKeywords, [keyword])
                                        );
                                    }}
                                />
                            );
                        })}
                    </div>
                ) : (
                    <p className={styles["empty-fallback"]}>No detected objects</p>
                )}
            </div>
        );
    } else {
        return <></>;
    }
}

export default LogHistorySummary;
