import React from "react";
import styles from "./css/Section.module.css";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// https://swiecajs.github.io/javascript/vue/react/2019/05/18/vue-patterns-in-react-pt-1.html
// Basically remaking named slot in React

class Section extends React.Component {
    state = { _retract: this.props.retracted ?? false };

    toggleRetract() {
        this.setState({ _retract: !this.state._retract });
    }

    static Header = ({ children, _retract, _retractable, _toggleRetract, className, style }) => (
        <header className={`${styles.header} ${className ?? ""}`} style={style}>
            <h2>{children}</h2>
            {_retractable && (
                <button onClick={() => _toggleRetract()}>
                    <FontAwesomeIcon
                        icon={faChevronUp}
                        className={`${styles.icon} ${(_retract && styles.retract) || ""}`}
                    ></FontAwesomeIcon>
                </button>
            )}
        </header>
    );

    static Content = ({ children, _retract, scrollable, className, style }) => (
        <main
            className={`${styles.content} ${_retract ? styles.retract : ""} ${
                scrollable ? styles.scrollable : ""
            } ${className || ""}`}
            style={style}
        >
            {children}
        </main>
    );

    renderChildren() {
        const { _retract } = this.state;

        if (this.props.children.length > 0)
            return React.Children.map(this.props.children, (child) =>
                React.cloneElement(child, {
                    _retract,
                    _toggleRetract: () => this.toggleRetract(),
                    _retractable: this.props.retractable ?? true,
                })
            );
        else return <></>;
    }

    render() {
        const { className, style } = this.props;

        return (
            <section className={`${styles.container} ${className ?? ""}`} style={style}>
                {this.renderChildren()}
            </section>
        );
    }
}

export default Section;
