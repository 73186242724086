import React, { useState, useEffect } from "react";
import styles from "./css/RangeSlider.module.css";
import { ReactComponent as IconReset } from "../svg/IconReload.svg";
/**
 * @typedef RangeOptions
 * @type {object}
 * @property {number} min - min value of slider
 * @property {number} max - max value of slider
 * @property {number} [step=1] - step value of slider
 * @property {number} [defaultValue=0] - default value of slider
 * @property {Boolean} [showIndicator=true] - show current value indicator
 * @property {Boolean} [lazy=true] - when false, trigger `onChange` given to `RangeSlider` whenever change event is triggered in the wrapped <input>
 * @property {Boolean} [controlled=true] - whether the input's value is decided by `defaultValue`. Note `defaultValue` does not
 *
 * @param {{name: string, value: number, action: function, options: RangeOptions}}
 */
function RangeSlider({ name, unit = "", onChange, options, disabled = false }) {
    const {
        min,
        max,
        step = 1,
        defaultValue = 0,
        showIndicator = true,
        lazy = true,
        controlled = true,
    } = options;
    const [slider, setSlider] = useState(defaultValue);
    // prevent update from defaultValue if locked
    const [locked, setLocked] = useState(false);
    // timeoutId of the lock, releases lock 200 ms after mouseUp, cleared when mouseDown
    const [lockId, setLockId] = useState(defaultValue);

    // disable when mouse is down
    const onMouseDown = () => {
        clearTimeout(lockId);
        setLocked(true);
    };

    const changeAndUpdate = (value) => {
        setSlider(Number(value));
        if (!lazy) {
            onChange(Number(value));
        }
    };

    const onMouseUp = (e) => {
        onChange(Number(e.target.value));
        setLockId(
            setTimeout(() => {
                setLocked(false);
            }, 200)
        );
    };

    useEffect(() => {
        if (!locked && controlled) {
            setSlider(defaultValue);
        }
    }, [defaultValue, locked, controlled]);

    return (
        <div className={styles.container}>
            <h3 className={styles.label}>{name}</h3>
            <span className={styles.range}>
                <input
                    type="range"
                    value={slider}
                    {...{ min, max, step }}
                    onMouseDown={onMouseDown}
                    onChange={(e) => changeAndUpdate(e.target.value)}
                    onMouseUp={onMouseUp}
                    disabled={disabled}
                />
                {showIndicator && (
                    <span className={styles.indicator}>
                        {slider} {unit}
                    </span>
                )}
            </span>
            <button
                type="button"
                className={styles.reset}
                disabled={disabled}
                onClick={() => changeAndUpdate(defaultValue)}
            >
                <IconReset width="10px" height="10px" />
            </button>
        </div>
    );
}

export default RangeSlider;
