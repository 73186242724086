import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import MenuButton from "./MenuButton";
import DialogBox from "./DialogBox";
import TroubleshootMenu from "./TroubleshootMenu";
import { hasIAQ } from "./AirQProvider";
import { MenuContext } from "./MainScreen";
import { useArmControl } from "./ArmControlProvider";

import styles from "./css/Menu.module.css";
import {
    faTimes,
    faMapMarkerAlt,
    faHeadset,
    faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as IconArmControl } from "../svg/IconArmControl.svg";
import { ReactComponent as IconSpot } from "../svg/IconSpot.svg";
import { ReactComponent as IconWind } from "../svg/IconWind.svg";
import { ReactComponent as IconNoWind } from "../svg/IconNoWind.svg";
import { ReactComponent as IconDoorOut } from "../svg/IconDoorOut.svg";
import { ReactComponent as IconAI } from "../svg/IconAI.svg";
import { useAuth } from "./AuthProvider";
import { FullScreenContext } from "./FullScreenProvider";
import { ReactComponent as IconCompress } from "../svg/IconCompress.svg";
import { ReactComponent as IconExpand } from "../svg/IconExpand.svg";
import useClickOutside from "../lib/hooks/useClickOutside";

const hasGPS = JSON.parse(process.env.REACT_APP_HAS_GPS);

function Menu({ closeMenu }) {
    const history = useHistory();
    const { showIAQ, toggleIAQ } = useContext(MenuContext);
    const { logOut } = useAuth();
    const [showTroubleshootMenu, setShowTroubleshootMenu] = useState(false);
    const [confirmPrompt, setConfirmPrompt] = useState(null);
    const { inArmControl, toggleArmControl } = useArmControl();
    const { isFullscreenSupported, isFullscreen, toggleFullscreen } = useContext(FullScreenContext);
    const clickOutsideRef = useClickOutside(() => closeMenu());

    return (
        <div className={styles.container} ref={clickOutsideRef}>
            <div
                className={styles.page}
                style={{
                    marginLeft: showTroubleshootMenu ? "-100%" : null,
                }}
            >
                <h1 className={styles.title}>Menu</h1>

                <div className={`${styles.content} ${styles["btn-grid"]}`}>
                    <MenuButton
                        icon={inArmControl ? IconSpot : IconArmControl}
                        iconType="svg"
                        description={inArmControl ? "Movement Mode" : "Arm Control Mode"}
                        action={() => toggleArmControl(!inArmControl)}
                    ></MenuButton>

                    {isFullscreenSupported && (
                        <MenuButton
                            icon={isFullscreen ? IconCompress : IconExpand}
                            iconType="svg"
                            description={isFullscreen ? "Window Size" : "Full Screen"}
                            action={() => toggleFullscreen()}
                        ></MenuButton>
                    )}
                    {hasIAQ && (
                        <MenuButton
                            icon={showIAQ ? IconWind : IconNoWind}
                            iconType="svg"
                            description={showIAQ ? "Hide Live IAQ" : "Show Live IAQ"}
                            action={toggleIAQ}
                        ></MenuButton>
                    )}

                    {hasGPS && (
                        <MenuButton
                            icon={faMapMarkerAlt}
                            iconType="fa"
                            size="2x"
                            description="Find My SPOT"
                            action={() => history.push("/main/find-my-spot")}
                        ></MenuButton>
                    )}

                    {/* not implemented yet */}
                    {false && (
                        <MenuButton
                            icon={IconAI}
                            iconType="svg"
                            width="20"
                            description="AI Marketplace"
                            action={() => history.push("/main/ai-market")}
                        ></MenuButton>
                    )}

                    <MenuButton
                        icon={faHeadset}
                        iconType="fa"
                        size="1x"
                        style={{ fontSize: "16px" }}
                        description="Troubleshoot"
                        action={() => setShowTroubleshootMenu(true)}
                    ></MenuButton>

                    <MenuButton
                        icon={IconDoorOut}
                        iconType="svg"
                        description="Logout"
                        btnClass={"red-btn"}
                        action={() =>
                            setConfirmPrompt({
                                title: "Log out",
                                textConfirm: "Log out",
                                onConfirm: logOut,
                                onCancel: () => setConfirmPrompt(null),
                            })
                        }
                    ></MenuButton>
                </div>

                <nav className={styles.close}>
                    <MenuButton
                        icon={faTimes}
                        iconType="fa"
                        size="2x"
                        action={closeMenu}
                    ></MenuButton>
                </nav>
            </div>

            {confirmPrompt && (
                <DialogBox {...confirmPrompt}>Are you sure to log out from this device?</DialogBox>
            )}

            {showTroubleshootMenu && (
                <TroubleshootMenu onClose={() => setShowTroubleshootMenu(false)}></TroubleshootMenu>
            )}
        </div>
    );
}

export default Menu;

export function SubMenu({ title, children, onClose }) {
    return (
        <div className={styles.page}>
            <h1 className={styles["sub-title"]}>{title}</h1>

            <div className={styles.content}>{children}</div>

            <div className={styles.close}>
                <MenuButton
                    icon={faChevronLeft}
                    iconType="fa"
                    size="2x"
                    action={onClose}
                ></MenuButton>
            </div>
        </div>
    );
}
