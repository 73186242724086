import React from "react";
import { useHistory } from "react-router-dom";
import GoogleMap from "./GoogleMap";
import MenuButton from "./MenuButton";
import Icon from "./Icon";
import { faChevronLeft, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

import styles from "./css/FindMySpot.module.css";

function FindMySpot() {
    const history = useHistory();

    return (
        <div className={styles.container}>
            <div className={styles["back-btn"]}>
                <MenuButton icon={faChevronLeft} iconType="fa" action={() => history.goBack()} />
                Back to Menu
            </div>

            <p className={styles.title}>Find My SPOT</p>
            <p className={styles.subtitle}>
                <Icon
                    icon={faMapMarkerAlt}
                    type="fa"
                    alt="location"
                    size="1x"
                    className={styles.marker}
                />
                <span>Approximate location of your SPOT</span>
            </p>

            <GoogleMap></GoogleMap>
        </div>
    );
}

export default FindMySpot;
