// config mode
export const MODE_SIT = "sit";
export const MODE_STAND = "stand";
export const MODE_WALK = "walk";
export const MODE_CRAWL = "crawl";
export const MODE_STAIR = "stair";
export const MODE_STANDARD = "standard";

export const configMode = (m) => ["/config/mode", m];
export const sit = () => configMode(MODE_SIT);
export const stand = () => configMode(MODE_STAND);
export const walk = () => configMode(MODE_WALK);
export const crawl = () => configMode(MODE_CRAWL);
export const stair = () => configMode(MODE_STAIR);
export const standard = () => configMode(MODE_STANDARD);

// config height
export const configHeight = (height) => ["/config/height", height];

// config light
export const configLight = (on) => ["/config/light", on];
export const configBrightness = (brightness) => ["/config/brightness", brightness];

// config max_speed
export const configMaxSpeed = (speed) => ["/config/max_speed", speed];

// config motor
export const MOTOR_POWER_OFF = "POWER_OFF";
export const MOTOR_POWER_ON = "POWER_ON";

export const configMotor = (action) => ["/config/motor", action];
export const motorOn = () => configMotor(MOTOR_POWER_ON);
export const motorOff = () => configMotor(MOTOR_POWER_OFF);
export const motorOnOff = (on) => (on ? motorOn() : motorOff());

// config estop
export const configEstop = (activate) => ["/config/estop", activate];

// claim motor
export const claimMotor = () => ["/claim/motor", true];
export const releaseMotor = () => ["/claim/motor", false];

// claim estop
export const claimEstop = () => ["/claim/estop", true];
export const releaseEstop = () => ["/claim/estop", false];

// perform actions
export const selfRight = () => ["/perform/selfright"];
export const turnOver = () => ["/perform/turnover"];
export const move = ({ x, y, z }) => ["/perform/movement", { x, y, z }];
export const resetPose = () => ["/perform/reset"];

// arm control
export const ARM_STOW = "Stow";
export const ARM_UNSTOW = "Unstow";
export const ARM_CARRY = "Carry";
export const ARM_MANIP_VALVE_TURN = "ARM_MANIP_VALVE_TURN";
export const ARM_MANIP_MANUAL = "ARM_MANIP_MANUAL";

export const stow = () => ["/arm/perform/stow"];
export const unstow = () => ["/arm/perform/unstow"];
export const carry = () => ["/arm/perform/carry"];
export const armStop = () => ["/arm/perform/stop"];
export const joyCtrl = (left_x, left_y, right_x, right_y, left_trigger, right_trigger) => [
    "/arm/perform/joy_ctrl",
    { left_x, left_y, right_x, right_y, left_trigger, right_trigger },
];
export const joyValveCtrl = (left_x, left_y, right_x, right_y, left_trigger, right_trigger) => [
    "/arm/perform/joy_valve_ctrl",
    { left_x, left_y, right_x, right_y, left_trigger, right_trigger },
];
export const gripperClose = () => ["/arm/perform/gripper_close"];
export const gripperOpen = () => ["/arm/perform/gripper_open"];
export const pickObject = () => ["/arm/perform/pickup_object_in_image"];
export const configArm = (max_speed) => ["/arm/config/arm_velocity", max_speed];
export const configWrist = (max_speed) => ["/arm/config/wrist_velocity", max_speed];
export const configVelocityDuration = (sec) => ["/arm/config/gripper_torque", sec];
export const configGripper = (torque) => ["/arm/config/gripper_torque", torque];
export const configFollowArm = (follow) => ["/arm/config/follow_arm", follow];
export const configDisableWalk = (disable) => ["/arm/config/follow_arm_disable_walk", disable];

// mission
export const missionRecordStart = () => ["/mission/record/start"];
export const missionRecordStop = () => ["/mission/record/stop"];
export const missionRunStart = (id, enableLog) => [
    "/mission/run/start",
    { mission_id: id, enableLog },
];
export const missionRunPause = () => ["/mission/run/pause"];
export const missionRunStop = () => ["/mission/run/stop"];
export const missionClear = () => ["/mission/clear"];
export const missionRename = (from, to) => ["/mission/rename", { from_name: from, to_name: to }];
export const missionDelete = (id) => ["/mission/delete", { mission_id: id }];
export const missionList = () => ["/mission/list"];
export const missionMap = (id) => ["/mission/map", { mission_id: id }];

export const handleKeyDown = (keyboardCode) => {
    switch (keyboardCode) {
        case "KeyS":
        case "ArrowDown":
            // Handle "back"
            return { axis: "x", value: -1 };
        case "KeyW":
        case "ArrowUp":
            // Handle "forward"
            return { axis: "x", value: 1 };
        case "KeyA":
        case "ArrowLeft":
            // Handle "turn left"
            return { axis: "y", value: 1 };
        case "KeyD":
        case "ArrowRight":
            // Handle "turn right"
            return { axis: "y", value: -1 };
        case "KeyE":
            return { axis: "z", value: -1 };
        case "KeyQ":
            return { axis: "z", value: 1 };
        default:
            return {};
    }
};

export const handleKeyUp = (keyboardCode) => {
    switch (keyboardCode) {
        case "KeyS":
        case "ArrowDown":
        case "KeyW":
        case "ArrowUp":
            // Handle "back"
            return { axis: "x", value: 0 };
        case "KeyA":
        case "ArrowLeft":
        case "KeyD":
        case "ArrowRight":
            // Handle "turn left"
            return { axis: "y", value: 0 };
        case "KeyE":
        case "KeyQ":
            return { axis: "z", value: 0 };
        default:
            return {};
    }
};
