import React, { useState } from "react";

import styles from "./css/MissionTab.module.css";
import { ReactComponent as IconPen } from "../svg/IconPen.svg";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import Icon from "./Icon";
import MissionEditor from "./MissionEditor";

function MissionItem({ id, name, onClick, isLoading = false }) {
    if (!isLoading)
        return (
            <div className={styles.item}>
                <button className={styles.name} title={name} onClick={onClick}>
                    {name}
                </button>
                <Icon className={styles.open} icon={faChevronRight} type="fa" size="1x"></Icon>
            </div>
        );
    else return <div className={styles.item}></div>;
}

export default MissionItem;
