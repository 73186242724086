import * as THREE from "three";
import { Line2 } from "three/examples/jsm/lines/Line2";
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial";
import { LineGeometry } from "three/examples/jsm/lines/LineGeometry";
import { SVGLoader } from "three/examples/jsm/loaders/SVGLoader";
import { OrbitControls, MapControls } from "./OrbitControls";
import InfiniteGridHelper from "./InfiniteGridHelper";

export const POINT_TYPE_POINT = "point";
export const POINT_TYPE_START = "start";
export const POINT_TYPE_END = "end";

const COLOR = {
    yellow: 0xfff222,
    red: 0xff0000,
    green: 0x00ff00,
    blue: 0x0000ff,
    purple: 0x696ad2,
    lightGray: 0xcccccc,
    gray: 0x888888,
    darkGray: 0x1c1c1c,
};

const loadSVG = (url, callback) => {
    const loader = new SVGLoader();
    loader.load(url, (data) => {
        const paths = data.paths;
        const group = new THREE.Group();
        // group.scale.multiplyScalar(0.25);
        // group.position.x = -70;
        // group.position.y = 70;
        // group.scale.y *= -1;

        for (let i = 0; i < paths.length; i++) {
            const path = paths[i];

            const fillColor = path.userData.style.fill;
            if (fillColor !== undefined && fillColor !== "none") {
                const material = new THREE.MeshBasicMaterial({
                    color: new THREE.Color().setStyle(fillColor).convertSRGBToLinear(),
                    opacity: path.userData.style.fillOpacity,
                    transparent: true,
                    side: THREE.DoubleSide,
                    depthWrite: false,
                });

                const shapes = SVGLoader.createShapes(path);

                for (let j = 0; j < shapes.length; j++) {
                    const shape = shapes[j];

                    const geometry = new THREE.ShapeGeometry(shape);
                    const mesh = new THREE.Mesh(geometry, material);

                    group.add(mesh);
                }
            }

            const strokeColor = path.userData.style.stroke;

            if (strokeColor !== undefined && strokeColor !== "none") {
                const material = new THREE.MeshBasicMaterial({
                    color: new THREE.Color().setStyle(strokeColor).convertSRGBToLinear(),
                    opacity: path.userData.style.strokeOpacity,
                    transparent: true,
                    side: THREE.DoubleSide,
                    depthWrite: false,
                });

                for (let j = 0, jl = path.subPaths.length; j < jl; j++) {
                    const subPath = path.subPaths[j];

                    const geometry = SVGLoader.pointsToStroke(
                        subPath.getPoints(),
                        path.userData.style
                    );

                    if (geometry) {
                        const mesh = new THREE.Mesh(geometry, material);

                        group.add(mesh);
                    }
                }
            }
        }
        callback(group);
    });
};

/**
 *
 * @param {string} url the URL or URI to the svg
 * @param {number} resolution the resolution of the square canvas.
 * The higher the resolution, the better the texture (default 512)
 * @returns THREE.Texture
 */
const getSVGTexture = (url, resolution = 512) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    const texture = new THREE.Texture(canvas);
    const img = document.createElement("img");
    canvas.width = canvas.height = resolution;

    const scaleToFit = (img) => {
        // get the scale
        const scale = Math.min(canvas.width / img.width, canvas.height / img.height);
        // get the top left position of the image
        var x = canvas.width / 2 - (img.width / 2) * scale;
        var y = canvas.height / 2 - (img.height / 2) * scale;
        context.drawImage(img, x, y, img.width * scale, img.height * scale);
    };

    img.src = url;
    img.onload = () => {
        scaleToFit(img);
        texture.needsUpdate = true;
    };
    return texture;
};

/**
 * Get SpriteMaterial for waypoints
 * This function uses closure to avoid recreating textures.
 * @param {string} type the type of waypoint ("point", "start", "end")
 * @returns THREE.SpriteMaterial
 */
const getWaypointMaterial = (() => {
    const waypointTexture = getSVGTexture("/waypoint.svg");
    const startTexture = getSVGTexture("/start-waypoint.svg");
    const endTexture = getSVGTexture("/end-waypoint.svg");

    return function (type) {
        switch (type) {
            case "start":
                return new THREE.SpriteMaterial({
                    map: startTexture,
                    transparent: true,
                    opacity: 0.9,
                });
            case "end":
                return new THREE.SpriteMaterial({
                    map: endTexture,
                    transparent: true,
                    opacity: 0.9,
                });
            case "point":
            default:
                return new THREE.SpriteMaterial({
                    map: waypointTexture,
                    transparent: true,
                    opacity: 0.9,
                });
        }
    };
})();

const getLineMaterial = (() => {
    const purpleLineMaterial = new LineMaterial({
        color: COLOR.purple,
        linewidth: 0.03,
        dashed: false,
        worldUnits: false,
        alphaToCoverage: true,
    });

    const greenLineMaterial = new LineMaterial({
        color: COLOR.green,
        linewidth: 0.03,
        dashed: false,
        worldUnits: false,
        alphaToCoverage: true,
    });

    const newMaterial = (args) =>
        new LineMaterial({
            ...args,
        });

    return function (type, options) {
        switch (type) {
            case "green":
                return greenLineMaterial;
            case "purple":
                return purpleLineMaterial;
            default:
                return newMaterial({ color: new THREE.Color(type), ...options });
        }
    };
})();

const getFloorTexture = () => {
    const canvas = document.createElement("canvas");
    canvas.width = canvas.height = 100;
    const context = canvas.getContext("2d");

    context.fillStyle = "#edf0f2";
    context.fillRect(0, 0, 100, 100);
    return new THREE.CanvasTexture(canvas);
};

const rotateToPoint = (obj, point) => {
    const u = new THREE.Vector3(0, 1, 0); // facing upward
    const vObj = obj.position.clone();
    const uObj = vObj.clone().normalize(); // unit vector of obj position
    const vPoint = point.clone();

    u.z = vObj.z = uObj.z = vPoint.z = 0; // we don't care about the z pos

    vPoint.sub(vObj);

    const rotation = u.angleTo(uObj) + vObj.angleTo(vPoint);
    obj.rotation.z = rotation;
};

const toVec3 = (arr3) => new THREE.Vector3().fromArray(arr3);

const ceilToMultiple = (num, multiple = 1) => Math.ceil(num / multiple) * multiple;
const floorToMultiple = (num, multiple = 1) => Math.floor(num / multiple) * multiple;
const sortWaypoints = (waypoints, byLinkage = true) => {
    // [id: string, ...info: Object]
    const waypointsArray = Object.entries(waypoints);
    if (waypointsArray.length <= 0) return [];

    // embed the id of a waypoint into the object instead of being the key
    const embedId = (waypoint) => ({ id: waypoint[0], ...waypoint[1] });

    if (!byLinkage) {
        // sort by timestamp
        return waypointsArray.sort(([, a], [, b]) => a.timestamp - b.timestamp).map(embedId);
    } else {
        // sort by linkage
        let sorted = [];

        // head is the one without prev waypoint
        const head = waypointsArray.filter(([, waypoint]) => !waypoint.prev).map(embedId)[0];

        sorted.push(head);
        let prev = head;
        while (prev.next) {
            const p = waypoints[prev.next];
            const obj = { id: prev.next, ...p };
            sorted.push(obj);
            prev = obj;
        }
        return sorted;
    }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    COLOR,
    Line2,
    LineMaterial,
    LineGeometry,
    OrbitControls,
    MapControls,
    InfiniteGridHelper,
    SVGLoader,
    loadSVG,
    getSVGTexture,
    getWaypointMaterial,
    getLineMaterial,
    getFloorTexture,
    rotateToPoint,
    toVec3,
    ceilToMultiple,
    floorToMultiple,
    sortWaypoints,
};
