import React, { useRef, useEffect } from "react";
import { Joystick } from "../lib/joystick";

function JoystickWrapper({ size = 140, onChange, className, disabled, onClick }) {
    const joystick = useRef(new Joystick(size));

    useEffect(() => {
        const joystickOnChange = (e) => onChange(e.detail);
        joystick.current.getDomElement().addEventListener("change", joystickOnChange);
        return () => {
            joystick.current.getDomElement().removeEventListener("change", joystickOnChange);
            joystick.current.stop();
        };
    }, [onChange]);

    useEffect(() => {
        if (disabled && joystick.current.operating) joystick.current.stop();
        else if (!disabled && !joystick.current.operating) joystick.current.operate();
    });

    const handleRef = (ref) => {
        if (!ref) return;
        const joystickElem = joystick.current.getDomElement();
        if (Array(ref.children).includes(joystickElem)) return;

        ref.appendChild(joystickElem);
        joystick.current.operate();
    };

    return <div className={className} onClick={onClick} ref={handleRef} disabled={disabled}></div>;
}
export default JoystickWrapper;
