import React, { useEffect, useState } from "react";
import styles from "./css/LogHistory.module.css";
import {
    ResponsiveContainer,
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    LabelList,
} from "recharts";
import EntityAbsence from "./EntityAbsence";

import { ReactComponent as IconNoWind } from "../svg/IconNoWind.svg";

const colorTone = [
    "#FF5A5A",
    "#ED9013",
    "#FBE947",
    "#58D764",
    "#76FFFF",
    "#52ACFF",
    "#725CFA",
    "#EF7BE3",
    "#3DFFDC",
];

function ChartLegendItem({ name, color, isShow, action }) {
    return (
        <div
            className={styles["legend-item"]}
            data-filter={isShow ? null : "true"}
            onClick={() => {
                action(name);
            }}
        >
            <div className={styles["legend-color"]} style={{ backgroundColor: color }}></div>
            <p>{name}</p>
        </div>
    );
}

function ChartLegend({ IAQChartKeys = [], action }) {
    if (IAQChartKeys.length > 0) {
        return (
            <div className={styles["chart-legend"]}>
                {IAQChartKeys.map((item, index) => (
                    <ChartLegendItem
                        key={index}
                        name={item.key}
                        color={item.color}
                        isShow={item.display}
                        action={action}
                    />
                ))}
            </div>
        );
    } else {
        return <></>;
    }
}

let selectedKey;
const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        return payload.map((payload, i) => {
            if (selectedKey !== payload.dataKey) return null;
            return (
                <div className={styles["tooltip-container"]} key={i}>
                    <div>
                        <div
                            className={styles["legend-color"]}
                            style={{ backgroundColor: payload.color }}
                        />
                        <span>{payload.name}</span>
                    </div>
                    <p>{`${payload.value}/${payload.unit}`}</p>
                </div>
            );
        });
    }

    return null;
};

const CustomizedLabel = (props) => {
    const { x, y, stroke, value } = props;

    return (
        <text
            x={x}
            y={y}
            dy={-4}
            fill="#AAA"
            fontSize={10}
            textAnchor="middle"
            style={{ color: "white !important" }}
        >
            {value}
        </text>
    );
};

function LogHistoryIAQChart({ isShow, IAQData = [] }) {
    const [chartKeys, setChartKeys] = useState([]);
    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        if (IAQData.length > 0) {
            const keys = Object.entries(IAQData[0].samples).map(([_, { unit, name }], index) => ({
                key: name,
                unit: unit,
                color: colorTone[index % colorTone.length],
                display: true,
            }));
            setChartKeys(keys);

            const data = IAQData.map(({ time, samples }) => {
                return Object.entries(samples).reduce(
                    (acc, [k, sample]) => ({
                        ...acc,
                        [k]: parseFloat(sample.value),
                    }),
                    { time }
                );
            });
            setChartData(data);
        }
    }, [IAQData]);

    const toggleDisplayKey = (keyName) => {
        const newChartKeys = chartKeys.map((obj) => {
            if (obj.key === keyName) {
                return { ...obj, display: !obj.display };
            }

            return obj;
        });

        setChartKeys(newChartKeys);
    };

    if (!isShow) {
        return <></>;
    }

    if (IAQData.length <= 0)
        return <EntityAbsence icon={IconNoWind} title="No IAQ Data"></EntityAbsence>;

    return (
        <>
            <ResponsiveContainer width="100%" height="90%">
                <LineChart
                    data={chartData}
                    margin={{
                        top: 32,
                    }}
                >
                    <XAxis dataKey="time" />
                    <YAxis
                        fill="#FFFFFF"
                        label={{
                            fill: "#FFFFFF",
                            value: "ppm",
                            angle: -90,
                            position: "insideLeft",
                        }}
                    />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip content={<CustomTooltip />} />
                    {/* <Legend /> */}
                    {chartKeys.map((item, index) => {
                        if (item.display) {
                            return (
                                <Line
                                    key={index}
                                    type="monotone"
                                    dataKey={item.key}
                                    unit={item.unit ?? "ppm"}
                                    stroke={item.color}
                                    onMouseOver={() => (selectedKey = item.key)}
                                    activeDot={{ onMouseOver: () => (selectedKey = item.key) }}
                                >
                                    <LabelList content={<CustomizedLabel />} />
                                </Line>
                            );
                        }
                        return null;
                    })}
                </LineChart>
            </ResponsiveContainer>

            <ChartLegend IAQChartKeys={chartKeys} action={toggleDisplayKey} />
        </>
    );
}

export default LogHistoryIAQChart;
