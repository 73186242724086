import React from "react";
import styles from "./css/DialogBox.module.css";

function DialogBox({
    title,
    textConfirm = "Confirm",
    textCancel = "Cancel",
    confirmStyle = null,
    cancelStyle = null,
    onConfirm = null,
    onCancel,
    children,
}) {
    return (
        <div className={styles.container}>
            <form
                className={styles.form}
                onSubmit={(e) => {
                    e.preventDefault();
                    onConfirm();
                }}
            >
                <h2 className={styles.title}>{title}</h2>
                <div style={{ margin: "1rem auto" }}>{children}</div>

                <div className={styles["btn-wrapper"]}>
                    {textConfirm && (
                        <button
                            type="submit"
                            className={styles.confirm}
                            style={confirmStyle}
                            onClick={onConfirm}
                        >
                            {textConfirm}
                        </button>
                    )}
                    {textCancel && (
                        <button
                            type="button"
                            className={styles.cancel}
                            style={cancelStyle}
                            onClick={onCancel}
                        >
                            {textCancel}
                        </button>
                    )}
                </div>
            </form>
        </div>
    );
}

export default DialogBox;
