import React, { useEffect, useState } from "react";
import OverlayButtonPanel from "./OverlayButtonPanel";

import { useArmState } from "./ArmStateProvider";
import { useArmControl } from "./ArmControlProvider";
import {
    ARM_CARRY,
    ARM_STOW,
    ARM_UNSTOW,
    stow,
    unstow,
    carry,
    armStop,
    gripperOpen,
    pickObject,
    ARM_MANIP_VALVE_TURN,
    ARM_MANIP_MANUAL,
    gripperClose,
} from "../lib/protocols/control";
import { useSocket } from "./ControlSocketProvider";

import { ReactComponent as IconHand } from "../svg/IconHand.svg";
import { ReactComponent as IconGateValve } from "../svg/IconGateValve.svg";
import { ReactComponent as IconStow } from "../svg/IconStow.svg";
import { ReactComponent as IconUnstow } from "../svg/IconUnstow.svg";
import { ReactComponent as IconGripper } from "../svg/IconGripper.svg";
import { ReactComponent as IconWaterTap } from "../svg/IconWaterTap.svg";
import { ReactComponent as IconOpenDoor } from "../svg/IconOpenDoor.svg";
import { ReactComponent as IconSandSample } from "../svg/IconSandSample.svg";
import { ReactComponent as IconCloseMenu } from "../svg/IconCloseMenu.svg";

import styles from "./css/Streamer.module.css";
import overlayStyles from "./css/OverlayButtonPanel.module.css";

function ArmControlButtonPanel() {
    const { armMode, isGripperOpen } = useArmState();
    const { inArmControl, setArmAction, armManipMode, setArmManipMode } = useArmControl();
    const { sendControl } = useSocket();
    const [showActionBar, setShowActionBar] = useState(false);

    if (inArmControl) {
        return (
            <>
                {/* <button
                    type="button"
                    className={styles["in-screen-btn"]}
                    onClick={() => setShowActionBar(true)}
                >
                    <IconActions />
                    <span>Actions</span>
                </button> */}

                <button
                    type="button"
                    className={styles["in-screen-btn"]}
                    onClick={() => setArmManipMode(ARM_MANIP_MANUAL)}
                >
                    <IconHand />
                    <span>Manual {armManipMode === ARM_MANIP_MANUAL && "(Active)"}</span>
                </button>

                <button
                    type="button"
                    className={styles["in-screen-btn"]}
                    onClick={() => setArmManipMode(ARM_MANIP_VALVE_TURN)}
                >
                    <IconGateValve />
                    <span>Valve {armManipMode === ARM_MANIP_VALVE_TURN && "(Active)"}</span>
                </button>

                {armMode === ARM_STOW && (
                    <button
                        type="button"
                        className={styles["in-screen-btn"]}
                        onClick={() => sendControl(unstow())}
                    >
                        <IconUnstow />
                        <span>Unstow</span>
                    </button>
                )}

                {(armMode === ARM_UNSTOW || armMode === ARM_CARRY) && (
                    <button
                        type="button"
                        className={styles["in-screen-btn"]}
                        onClick={() => sendControl(stow())}
                    >
                        <IconStow />
                        <span>Stow</span>
                    </button>
                )}

                <button
                    type="button"
                    className={styles["in-screen-btn"]}
                    onClick={() => {
                        if (isGripperOpen) {
                            sendControl(gripperClose());
                        } else {
                            sendControl(gripperOpen());
                        }
                    }}
                >
                    <IconGripper />
                    <span>{isGripperOpen ? "Close Gripper" : "Open Gripper"}</span>
                </button>
                {showActionBar && (
                    <OverlayButtonPanel
                        items={[
                            {
                                icon: IconWaterTap,
                                description: "Switch Water Tap",
                                action: () => {
                                    setArmAction({
                                        icon: IconWaterTap,
                                        iconType: "svg",
                                        action: "Switch Water Tap",
                                        actionDesc: "Click on the water tap to execute action",
                                        onExecute: () => console.log("execute switch water tap"),
                                    });
                                    setShowActionBar(false);
                                },
                            },
                            {
                                icon: IconOpenDoor,
                                description: "Open Door",
                                action: () => {
                                    setArmAction({
                                        icon: IconOpenDoor,
                                        iconType: "svg",
                                        action: "Open Door",
                                        actionDesc: "Click on the doorknob to execute action",
                                        onExecute: () => console.log("execute open door"),
                                    });
                                    setShowActionBar(false);
                                },
                            },
                            {
                                icon: IconSandSample,
                                description: "Source Sand",
                                action: () => {
                                    setArmAction({
                                        icon: IconSandSample,
                                        iconType: "svg",
                                        action: "Source Sand",
                                        actionDesc:
                                            "Place front sight in right position to execute action. ",
                                        onExecute: () => console.log("execute source sand"),
                                    });
                                    setShowActionBar(false);
                                },
                            },
                            {
                                icon: IconCloseMenu,
                                description: "Close Menu",
                                action: () => setShowActionBar(false),
                            },
                        ]}
                    />
                )}
            </>
        );
    } else {
        return <></>;
    }
}

export default ArmControlButtonPanel;
