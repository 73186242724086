import React, { useContext } from "react";
import MenuButton from "./MenuButton";
import NotifyBox from "./NotifyBox";
import { NotificationContext } from "./NotificationProvider";
import styles from "./css/Notification.module.css";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import useClickOutside from "../lib/hooks/useClickOutside";

function NotificationPanel({ closePanel, isShow = false }) {
    const { notis, read, readAll } = useContext(NotificationContext);
    const clickOutsideRef = useClickOutside(() => closePanel());

    if (isShow) {
        return (
            <div className={`${styles.menu} ${styles.position}`} ref={clickOutsideRef}>
                <div className={styles["header-container"]}>
                    <p className={styles.header}>Notification</p>
                    <button type="button" className={styles.action} onClick={() => readAll()}>
                        Mark as read
                    </button>
                </div>

                <div className={styles["box-container"]}>
                    {notis.length > 0 ? (
                        notis.map((notice) => {
                            const { id, type, title, body, time, isRead } = notice;
                            return (
                                <NotifyBox
                                    {...{ type, title, body, time, isRead }}
                                    key={id}
                                    onClick={() => read(id)}
                                ></NotifyBox>
                            );
                        })
                    ) : (
                        <div className={styles.fallback}>No notification now</div>
                    )}
                </div>

                <div className={styles.footer}>
                    <MenuButton
                        icon={faTimes}
                        iconType="fa"
                        size="2x"
                        action={closePanel}
                    ></MenuButton>
                </div>
            </div>
        );
    } else {
        return <></>;
    }
}

export default NotificationPanel;
