import React, { useState, useContext, useEffect } from "react";
import { configMode, configHeight, configLight, configBrightness } from "../lib/protocols/control";
import { restartStream } from "../lib/protocols/streams";
import { TOPIC_STATE_LIGHT, TOPIC_STATE_BRIGHTNESS } from "../lib/state";

import { SPEED_NORMAL } from "../consts";
import { subscribe, unsubscribe } from "../lib/socket";
import useStorage from "../lib/hooks/useStorage";
import { useSocket } from "./ControlSocketProvider";
import { useSnack } from "./SnackProvider";

export const RobotControlContext = React.createContext();
RobotControlContext.displayName = "RobotControl";
export const RobotControlConsumer = RobotControlContext.Consumer;
export const useControl = () => useContext(RobotControlContext);

function RobotControlProvider({ children }) {
    const { controlSocket: socket, sendControl } = useSocket();
    const [useKeyboard, setUseKeyboard] = useStorage("robot:control/useKeyboard", false);
    const [useGamepad, setUseGamepad] = useStorage("robot:control/useGamepad", false);
    const [speed, setSpeed] = useStorage("robot:control/speed", SPEED_NORMAL);
    // const [speed, setSpeed] = useState(SPEED_NORMAL);
    const [light, setLight] = useState(false);
    const [brightness, setBrightness] = useState(0);
    const { setSnack } = useSnack();

    const changeMode = (mode) => sendControl(configMode(mode));
    const changeHeight = (h) => sendControl(configHeight(h));
    const changeLight = (on) => sendControl(configLight(on));
    const changeBrightness = (brightness) => sendControl(configBrightness(brightness));
    const restartStreaming = () => sendControl(restartStream());

    useEffect(() => {
        const handleLight = (data) => {
            setLight(data);
        };

        const handleBrightness = (data) => {
            setBrightness(data);
        };

        subscribe(socket, TOPIC_STATE_LIGHT, handleLight);
        subscribe(socket, TOPIC_STATE_BRIGHTNESS, handleBrightness);

        return () => {
            socket && unsubscribe(socket, TOPIC_STATE_LIGHT, handleLight);
            socket && unsubscribe(socket, TOPIC_STATE_BRIGHTNESS, handleBrightness);
        };
    }, [socket]);

    return (
        <RobotControlContext.Provider
            value={{
                changeMode,
                changeHeight,
                useKeyboard,
                setUseKeyboard,
                useGamepad,
                setUseGamepad,
                speed,
                changeSpeed: setSpeed,
                light,
                changeLight,
                brightness,
                changeBrightness,
                restartStreaming,
            }}
        >
            {children}
        </RobotControlContext.Provider>
    );
}

export default RobotControlProvider;
