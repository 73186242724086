import React from "react";
import { defaultCam, getCamera, selectStream } from "../lib/protocols/streams";
import { DEFAULT_IP, STREAM_LOW_FPS } from "../consts";
import { STATE_STREAM_NONE, STATE_STREAM_SLOW, STATE_STREAM_OKAY } from "../lib/state";

export const CameraContext = React.createContext({
    imageDataUrl: null,
    camConnected: false,
    streamStatus: STATE_STREAM_NONE,
    selectedCam: defaultCam.name,
});
CameraContext.displayName = "CameraContext";

export const CameraContextConsumer = CameraContext.Consumer;

class CameraProvider extends React.Component {
    constructor(props) {
        super(props);

        this.ws = null;
        this.pingByte = Uint8Array.of(0x9).buffer;
        this.timeout = 250;
        this.pingIntervalId = null;
        this.fpscheckIntervalId = null;
        this.retryId = null;
        this.fps = 0;
        this.subscription = null;
        this.state = {
            // export
            imageDataUrl: null,
            camConnected: false,
            streamStatus: STATE_STREAM_NONE,
            selectedCam: this.props.selectedCam ? this.props.selectedCam : defaultCam.name,
            onSelectCam: (name) => this.onSelectCam(name),
        };
    }

    componentDidMount() {
        this.connect();
    }

    // componentDidUpdate(prevProps, prevState) {
    //     if (prevState.ws !== this.state.ws) {
    //         prevState.ws && prevState.ws.close();
    //     }
    // }

    componentWillUnmount() {
        if (this.state.selectedCam) {
            this.setState({ selectedCam: null });
        }
        if (this.state.subscription) {
            this.unsubscribe();
            this.setState({ subscription: null });
        }
        this.ws.close();
        clearTimeout(this.retryId);
    }

    sendWhenReady(data) {
        if (!this.ws || this.ws.readyState !== this.ws.OPEN) return;

        this.ws.send(data);
    }

    ping(interval) {
        this.pingIntervalId = setInterval(() => {
            this.sendWhenReady(this.pingByte);
        }, interval);
    }

    checkFPS() {
        this.fpscheckIntervalId = setInterval(() => {
            this.setState({
                streamStatus:
                    this.fps === 0
                        ? STATE_STREAM_NONE
                        : this.fps <= STREAM_LOW_FPS
                        ? STATE_STREAM_SLOW
                        : STATE_STREAM_OKAY,
            });
            this.fps = 0;
        }, 1000);
    }

    subscribe({ name, source }) {
        if (this.ws) {
            this.sendWhenReady(selectStream(source));
            this.setState({
                subscription: source,
                selectedCam: name,
            });
        }
    }

    unsubscribe() {
        if (this.subscription) {
            if (this.ws) {
                this.sendWhenReady(selectStream(""));
            }
            this.setState({ subscription: null });
        }
    }

    handleData(data) {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onloadend = () => {
            const dataURL = reader.result.split(",")[1];
            this.setState({ imageDataUrl: `data:image/jpg;base64,${dataURL}` });
        };
    }

    close() {
        this.ws.onclose = null;
        this.ws.close();
        this.ws = null;
        this.retryId && clearTimeout(this.retryId);
    }

    onOpen() {
        console.log("connected to stream");

        this.setState({
            camConnected: true,
        });

        // select first stream
        this.sendWhenReady(selectStream(defaultCam.source));

        // ping every 5 seconds
        this.ping(5000);

        this.subscribe(defaultCam);

        // check Stream Health
        this.checkFPS();
    }

    async onMessage(e) {
        const nameSizeSize = 1;
        const streamNameSize = new Uint8Array(await e.data.slice(0, nameSizeSize).arrayBuffer())[0];

        const streamData = await e.data.slice(nameSizeSize, 1 + streamNameSize).arrayBuffer();
        const name = new TextDecoder().decode(streamData);
        this.sendWhenReady(new Uint8Array([0x01]).buffer);

        this.fps++;
        this.handleData(e.data.slice(nameSizeSize + streamNameSize));
    }

    onClose() {
        console.log("disconnected from stream");
        this.setState({
            camConnected: false,
        });

        // Clear all interval
        if (this.pingIntervalId !== null) {
            clearInterval(this.pingIntervalId);
            this.pingIntervalId = null;
        }
        if (this.fpscheckIntervalId !== null) {
            clearInterval(this.fpscheckIntervalId);
            this.fpscheckIntervalId = null;
        }

        this.retryId = setTimeout(() => {
            this.connect();
        }, this.timeout);
        this.timeout = Math.min(this.timeout * 2, 10 * 1000);
    }

    onError(err) {
        console.error("Socket encountered error: ", err.message, "Closing socket");

        this.ws.close();
    }

    onSelectCam(name) {
        console.log("Changing camera to ", name);
        const targetCam = getCamera({ name });
        this.setState({
            selectedCam: targetCam.name,
        });
        if (targetCam === null) {
            alert("Camera not found");
            return;
        }

        if (targetCam) {
            const subscribed = this.state.subscription === targetCam.source;
            if (this.state.subscription !== null && !subscribed) {
                this.unsubscribe();
            }
            this.subscribe(targetCam);
        }
    }

    connect() {
        const isSecure = window.location.protocol === "https:";
        const url = `ws${isSecure ? "s" : ""}://${DEFAULT_IP.split(":")[0]}:9002`;
        this.ws = new WebSocket(url);

        this.ws.onopen = () => this.onOpen();
        this.ws.onmessage = (e) => this.onMessage(e);
        this.ws.onclose = () => this.onClose();
        // this.ws.onerror = (err) => this.onError(err);
    }

    render() {
        return (
            <CameraContext.Provider value={this.state}>
                {this.props.children}
            </CameraContext.Provider>
        );
    }
}

export default CameraProvider;
