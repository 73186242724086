export const DEFAULT_IP =
    process.env.REACT_APP_ROBOT_IP_OVERRIDE || `${window.location.hostname}:3000`;

export const SPEED_SLOW = 0.2;
export const SPEED_NORMAL = 0.6;
export const SPEED_FAST = 1.0;
export const SPEED_STEP = 0.4;

export const HEIGHT_ACTUAL = 0.61;
export const HEIGHT_DEFAULT = 0;
export const HEIGHT_MIN = -0.09;
export const HEIGHT_MAX = 0.09;
export const HEIGHT_STEP = 0.03;

export const LIGHT_DEFAULT = 0;
export const LIGHT_MIN = 0;
export const LIGHT_MAX = 100;
export const LIGHT_STEP = 1;

export const AVOID_DEFAULT = 0.02;
export const AVOID_MIN = 0.02;
export const AVOID_MAX = 0.5;
export const AVOID_STEP = 0.08;

export const LOGIN_NOT_CONNECTED = 0;
export const LOGIN_CONNECTING = 1;
export const LOGIN_CONNECTED = 2;

export const STREAM_LOW_FPS = 5;
