import React, { useMemo } from "react";
import styles from "./css/Notification.module.css";

import { faWind, faBullhorn } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as IconAlarm } from "../svg/IconAlarm.svg";
import Icon from "./Icon";
import Tooltip from "./Tooltip";

const ICONS = {
    iaq: { icon: faWind, type: "fa" },
    object_detection: { icon: IconAlarm, type: "svg" },
    others: { icon: faBullhorn, type: "fa" },
};

function NotifyBox({ type = "others", title, body, time, isRead = false, onClick }) {
    const notiType = useMemo(() => (type in ICONS ? type : "others"), [type]);

    return (
        <div className={`${styles.box} ${isRead ? styles.isRead : ""}`.trim()} onClick={onClick}>
            <div style={{ color: "#999AFA", fontSize: "24px" }}>
                <Tooltip content={notiType}>
                    <Icon
                        icon={ICONS[notiType].icon}
                        type={ICONS[notiType].type}
                        alt={notiType}
                        height="24px"
                        width="24px"
                    ></Icon>
                </Tooltip>
            </div>
            <div className={styles.content}>
                <h4 className={styles.title}>{title}</h4>
                <p className={styles.body}>{body}</p>
                <p className={styles.time}>{time}</p>
            </div>
        </div>
    );
}

export default NotifyBox;
