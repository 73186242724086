import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import {
    ARM_MANIP_MANUAL,
    ARM_MANIP_VALVE_TURN,
    joyCtrl,
    joyValveCtrl,
} from "../lib/protocols/control";

import { useSocket } from "./ControlSocketProvider";
import useStorage from "../lib/hooks/useStorage";
import throttle from "lodash/throttle";
import { useArmState } from "./ArmStateProvider";

export const ArmControlContext = React.createContext();
ArmControlContext.displayName = "ArmControl";
export const ArmControlConsumer = ArmControlContext.Consumer;
export const useArmControl = () => useContext(ArmControlContext);

const isZero = (val) => Math.abs(val) === 0;

function ArmControlProvider({ children }) {
    const { sendControl } = useSocket();
    const [movement, setMovement] = useState({ leftX: 0, leftY: 0, rightX: 0, rightY: 0 });
    const [inArmControl, setInArmControl] = useStorage("arm:control/inArmControl", false);
    const [armAction, setArmAction] = useState(null);
    const [crosshair, setCrosshair] = useState(null);
    const [armManipMode, setArmManipMode] = useStorage(
        "arm:control/armManipMode",
        ARM_MANIP_MANUAL
    );

    const changeMovement = ({ position, value = 0 }) => {
        if (!position || !["leftX", "leftY", "rightX", "rightY"].includes(position)) return;

        setMovement((prev) => ({ ...prev, [position]: value }));
    };

    const movementRef = useRef(movement);
    const isStoppedRef = useRef(true);
    const periodicMovementId = useRef(0);

    const sendMovement = useCallback(
        (movement) => {
            const { leftX, leftY, rightX, rightY } = movement;
            if (armManipMode === ARM_MANIP_VALVE_TURN) {
                // sendControl(joyCtrl(leftX, leftY, 0, 0, 0, 0));
                sendControl(joyValveCtrl(0, 0, rightX, rightY, 0, 0));
            } else if (armManipMode === ARM_MANIP_MANUAL) {
                sendControl(joyCtrl(leftX, leftY, rightX, rightY, 0, 0));
            }
        },
        [sendControl, armManipMode]
    );

    const noMovement = (m) => Object.entries(m).every(([_, v]) => isZero(v));

    // update movementRef when movement changes. the interval will uses the reference to periodically send current movement info to robot and keep it moving if movements are not zero
    useEffect(() => {
        movementRef.current = movement;
    }, [movement]);
    useEffect(() => {
        periodicMovementId.current = setInterval(() => {
            const m = movementRef.current;
            // const hasNoMovement = true;
            const hasNoMovement = noMovement(m);
            // if it's stopped already and there's no movement, don't do anything
            if (isStoppedRef.current && hasNoMovement) return;
            // else send movement
            // console.log("sending arm movement...", m);
            sendMovement(m);
            // if there is no movement in m, mark as stopped. this interval will not send movement next time.
            isStoppedRef.current = hasNoMovement;
        }, 100);
        return () => {
            periodicMovementId.current && clearInterval(periodicMovementId.current);
        };
    }, [sendMovement]);

    return (
        <ArmControlContext.Provider
            value={{
                inArmControl,
                toggleArmControl: setInArmControl,
                changeArmMovement: changeMovement,
                armAction,
                setArmAction,
                crosshair,
                armManipMode,
                setArmManipMode,
            }}
        >
            {children}
        </ArmControlContext.Provider>
    );
}

export default ArmControlProvider;
