import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import Tabs from "./Tabs";
import GeneralTab from "./GeneralTab";
import MissionTab from "./MissionTab";
import LogsTab from "./LogsTab";
import Icon from "./Icon";

import { faCaretRight, faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import { toKebabCase } from "../lib/utils";

import styles from "./css/SidePanel.module.css";

function SidePanel() {
    const history = useHistory();
    const location = useLocation();
    const [isShow, setIsShow] = useState(true);

    const tab = location.pathname.split("/")[2] ?? "new-record";

    return (
        <aside className={styles.container}>
            <div className={`${styles.body} ${!isShow && styles.hidden}`}>
                <Tabs
                    defaultTab={tab}
                    tab={tab}
                    isShow={isShow}
                    onSwitchTab={({ label }) => history.push(`/main/${toKebabCase(label)}`)}
                >
                    <Tabs.Item index={0} label="General">
                        <GeneralTab></GeneralTab>
                    </Tabs.Item>

                    <Tabs.Item index={1} label="Mission">
                        <MissionTab></MissionTab>
                    </Tabs.Item>

                    <Tabs.Item index={2} label="Logs">
                        <LogsTab></LogsTab>
                    </Tabs.Item>
                </Tabs>

                <p className={styles.copyright}>
                    &copy;{new Date().getFullYear()} <b>R2C2</b> Ltd.
                </p>
            </div>
            <button
                className={`${styles.toggler} ${!isShow && styles.fade}`}
                style={{ background: "url(/panel-toggler-bg.png) no-repeat" }}
                onClick={() => setIsShow(!isShow)}
            >
                <Icon
                    icon={isShow ? faCaretLeft : faCaretRight}
                    type="fa"
                    size="lg"
                    style={{ color: "hsla(250, 49%, 50%, 1)" }}
                />
            </button>
        </aside>
    );
}

export default SidePanel;
