import React, { useEffect, useState, useContext, useCallback } from "react";
import { DEFAULT_IP } from "../consts";
import {
    STATE_JETPACK_CONNECTED,
    STATE_JETPACK_CONNECTING,
    STATE_JETPACK_DISCONNECTED,
} from "../lib/state";

import { connect as socketIOConnect, send } from "../lib/socket";

export const ControlSocketContext = React.createContext({
    controlSocket: null,
    socketIP: DEFAULT_IP,
    socketState: STATE_JETPACK_DISCONNECTED,
});
ControlSocketContext.displayName = "ControlSocket";

export const ControlSocketConsumer = ControlSocketContext.Consumer;
export const useSocket = () => useContext(ControlSocketContext);

function ControlSocketProvider({ children }) {
    const [controlSocket, setControlSocket] = useState(null); // one instance of socket
    const [socketIP, setSocketIP] = useState(DEFAULT_IP);
    const [socketState, setSocketState] = useState(STATE_JETPACK_DISCONNECTED);

    useEffect(() => {
        console.log("connecting control socket...");
        const socket = socketIOConnect(socketIP);
        setSocketState(STATE_JETPACK_CONNECTING);
        const timeoutId = setTimeout(() => setSocketState(STATE_JETPACK_DISCONNECTED), 5000);

        socket.on("connect", () => {
            console.log("control socket connected.");
            setControlSocket(socket);
            clearTimeout(timeoutId);
            setSocketState(STATE_JETPACK_CONNECTED);
        });

        socket.on("disconnect", () => {
            console.log("control socket disconnected.");
            setSocketState(STATE_JETPACK_DISCONNECTED);
        });

        return () => {
            socket.close();
            setSocketState(STATE_JETPACK_DISCONNECTED);
            setControlSocket(null);
        };
    }, [socketIP]);

    const sendControl = useCallback((args) => send(controlSocket, ...args), [controlSocket]);

    return (
        <ControlSocketContext.Provider
            value={{ controlSocket, socketIP, socketState, setSocketIP, sendControl }}
        >
            {children}
        </ControlSocketContext.Provider>
    );
}

export default ControlSocketProvider;
