import { io } from "socket.io-client";

const subscriptionStore = [];

export const connect = (addr) => {
    const s = io(addr);
    s.on("connect", () => {
        for (const sub of subscriptionStore) {
            subscribe(s, sub.event, sub.handler);
        }
    });
    return s;
};

export const send = (s, event, data = {}) => {
    // console.log("s:", s);
    if (!s) return;
    // console.log("emit:", event, ";", data);
    s.emit(event, data);
};

export const subscribe = (s, event, handler) => {
    // if a combination of this event and this handler not found in subscriptionstore, add them
    if (subscriptionStore.findIndex((sub) => sub.event === event && sub.handler === handler) < 0) {
        subscriptionStore.push({ event, handler });
    }
    if (!s) return;
    s.emit("subscribe", event);
    s.on(event, (data) => {
        handler(data);
    });
};

export const unsubscribe = (s, event, handler) => {
    if (handler === undefined) handler = () => {};
    s.emit("unsubscribe", event);
    s.off(event, handler);
    const i = subscriptionStore.findIndex((sub) => sub.event === event && sub.handler === handler);
    if (i > -1) {
        subscriptionStore.splice(i, 1);
    }
};
